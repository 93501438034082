/*------- fonts -------*/


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
  --font-family-base: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.color-box {
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0px 5px 18px rgba(0, 30, 60, 0.06);
}

.color-box-holder {
  border-radius: 0.5rem;
}

.color-box-inner:first-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.color-box-inner:last-child {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.color-box p {
  margin-bottom: 0;
}

/*------- typography -------*/
html, body {
  color: #797d85;
  background-color: #f1f4f7;
  font-size: 16px;
  font-weight: 400;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
  color: #797d85;
  background-color: #fff;
  font-weight: 300;
  letter-spacing: 0.02rem;
}

*:focus {
  outline: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.container.boxed {
  width: 100% !important;
  max-width: 1300px;
}

.container.full-width {
  width: 100% !important;
  padding-left: 30px;
  padding-right: 30px;
}

.content {
  background: #fafbfc;
  padding: 30px 65px;
}

h1, h2, h3, h4, h5, h6 {
  color: #111724;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.02rem;
  margin-top: 0;
  font-weight: 700;
}

h1, .h1 {
  font-size: 3rem;
}

h2, .h2 {
  font-size: 2.5rem;
}

h3, .h3 {
  font-size: 2.2rem;
}

h4, .h4 {
  font-size: 1.85rem;
}

h5, .h5 {
  font-size: 1.6rem;
}

h6, .h6 {
  font-size: 1.325rem;
}

html, body, a, p, ul, ol, li, div {
  font-family: "Poppins", sans-serif;
}

a {
  color: #0d0d0e;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  letter-spacing: 0.02rem;
  outline: 0 !important;
  text-decoration: none;
}

a:hover, a:active, a:focus {
  color: #005bc1;
  text-decoration: none;
}

.text-link, .text-link:focus {
  color: #797d85;
}

.text-link:hover {
  color: #0978f5;
}

.title-link, .title-link:focus {
  color: #111724;
}

.title-link:hover {
  color: #797d85;
}

p {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  font-size: 1rem;
  color: #797d85;
  letter-spacing: 0.02rem;
  margin-bottom: 15px;
}

ul {
  list-style: none;
  padding: 0;
}

ul, ol {
  padding-left: 20px;
  margin-top: 0;
  margin-bottom: 15px;
}

ol li {
  margin-bottom: 7px;
}

li {
  color: #797d85;
  font-size: 1rem;
  letter-spacing: 0.02rem;
}

ul li:last-child, ol li:last-child {
  margin-bottom: 0;
}

img {
  -webkit-backface-visibility: hidden !important;
  background: transparent;
}

pre {
  color: #797d85;
  font-size: 1rem;
  background-color: #fff;
  border-left: 3px solid #0978f5;
  padding: 15px;
  white-space: pre-line;
}

label {
  font-size: 1rem;
}

.list-featured {
  list-style: none;
  padding: 0;
}

.list-featured li {
  position: relative;
  padding-left: 13px;
  margin-bottom: 10px;
}

.list-featured li:before {
  position: absolute;
  display: block;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  left: 0;
  top: 8px;
  background-color: #0978f5;
}

.list-icon li, .list-icon a {
  position: relative;
  padding-left: 23px;
  margin-bottom: 15px;
}

.list-icon li:before, .list-icon a:before {
  color: #797d85;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.875rem;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  line-height: 1.325rem;
}

.list-has-link li {
  padding-left: 0;
}

.media-list {
  padding-left: 0;
}

.list-has-link li:before {
  display: none;
}

.list-icon.list-arrow li:before, .list-icon.list-arrow a:before {
  content: "\f054";
}

.list-icon.list-check li:before, .list-icon.list-check a:before {
  content: "\f00c";
  top: 3px;
}

.list-icon.list-close li:before, .list-icon.list-close a:before {
  content: "\f00d";
}

.list-icon.list-bullet li:before, .list-icon.list-bullet a:before {
  content: "\f192";
  top: 2px;
}

.list-icon.list-star li:before, .list-icon.list-star a:before {
  content: "\f005";
}

.list-icon.list-dollar li:before, .list-icon.list-dollar a:before {
  content: "\f155";
}

.list-secondary li:before, .list-secondary a:before {
  color: #c2c7cd;
}

.list-primary li:before, .list-primary a:before {
  color: #0978f5;
}

.list-success li:before, .list-success a:before {
  color: #2cb34c;
}

.list-info li:before, .list-info a:before {
  color: #4f58db;
}

.list-warning li:before, .list-warning a:before {
  color: #e5a12b;
}

.list-danger li:before, .list-danger a:before {
  color: #d72f40;
}

.list-media > .list-item {
  display: block;
}

.list-media .media-img {
  position: relative;
  float: left;
}

.list-media .media-img img {
  max-width: 55px;
  height: auto;
  text-align: center;
  border-radius: 0;
}

.list-media .info {
  padding-left: 65px;
  min-height: 45px;
  height: auto;
  position: relative;
}

.list-media .info .sub-title {
  display: block;
  font-size: 0.75rem;
}

.list-media-content {
  position: relative;
  float: left;
  font-size: 0.875rem;
  line-height: 47px;
  height: 45px;
  width: 45px;
  text-align: center;
  border-radius: 50%;
  margin-right: 6px;
}

.list-media .info .list-checkbox {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-28px);
  -webkit-transform: translateY(-28px);
  -moz-transform: translateY(-28px);
  -o-transform: translateY(-28px);
  -ms-transform: translateY(-28px);
}

.flex-list .list-media > .list-item {
  display: flex;
  align-items: center;
}

.flex-list .list-media .media-img {
  position: relative;
  float: none;
}

.flex-list .list-media .media-img img {
  max-width: 70px;
}

.flex-list .list-media .info .title {
  font-size: 1.25rem;
  font-weight: 500;
}

.flex-list .list-media .info .sub-title {
  font-size: 0.75rem;
}

.flex-list .list-media .info {
  padding-left: 15px;
}

.flex-list .list-group-item {
  border: none;
}

.flex-list.order-list .list-media .media-img img {
  max-width: 125px;
}

.list-order {
  margin-top: 10px;
}

.list-order .qty {
  max-height: 40px;
  max-width: 50px;
  margin-right: 0;
}

.list-media-secondary {
  color: #797d85;
  background-color: #c2c7cd;
}

.list-media-primary {
  color: #fff;
  background-color: #0978f5;
}

.list-media-success {
  color: #fff;
  background-color: #2cb34c;
}

.list-media-info {
  color: #fff;
  background-color: #4f58db;
}

.list-media-warning {
  color: #fff;
  background-color: #e5a12b;
}

.list-media-danger {
  color: #fff;
  background-color: #d72f40;
}

.list-group-item:first-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.list-group-item:last-child {
  margin-bottom: 0 !important;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

a.list-group-item:last-child {
  margin-bottom: -1px !important;
}

.list-group-item {
  background-color: #fff;
  margin-bottom: -1px !important;
  padding: 1rem 1.25rem;
  border: 1px solid #f1f4f7;
}

.list-group-item-primary {
  color: #fff !important;
  background-color: #0978f5 !important;
}

.list-group-item-primary:hover {
  color: #fff !important;
  background-color: #005bc1 !important;
}

.list-group-item-secondary {
  color: #797d85 !important;
  background-color: #c2c7cd !important;
}

.list-group-item-secondary:hover {
  color: #797d85 !important;
  background-color: #848d96 !important;
}

.list-group-item-success {
  color: #fff !important;
  background-color: #2cb34c !important;
}

.list-group-item-success:hover {
  color: #fff !important;
  background-color: #12812c !important;
}

.list-group-item-info {
  color: #fff !important;
  background-color: #4f58db !important;
}

.list-group-item-info:hover {
  color: #fff !important;
  background-color: #2e36a5 !important;
}

.list-group-item-warning {
  color: #fff !important;
  background-color: #e5a12b !important;
}

.list-group-item-warning:hover {
  color: #fff !important;
  background-color: #c27d04 !important;
}

.list-group-item-danger {
  color: #fff !important;
  background-color: #d72f40 !important;
}

.list-group-item-danger:hover {
  color: #fff !important;
  background-color: #9b1421 !important;
}

.list-group-item-light {
  color: #797d85 !important;
  background-color: #f1f4f7 !important;
}

.list-group-item-light:hover {
  color: #797d85 !important;
  background-color: #e6e9ec !important;
}

.list-group-item-dark {
  color: #fff !important;
  background-color: #515254 !important;
}

.list-group-item-dark:hover {
  color: #fff !important;
  background-color: #303132 !important;
}

.list-icon .list-group-item {
  padding-left: 3rem !important;
}

.list-icon li.list-group-item:before, .list-icon a.list-group-item:before {
  top: 1.325rem;
  left: 1.5rem;
}

.list-group-item-action {
  color: #797d85;
}

.list-group-item-action:focus, .list-group-item-action:hover {
  background-color: #c2c7cd;
}

/*--- topics list group ---*/
.topics-list {
  border-radius: 0.25rem;
  box-shadow: 0px 3px 12px rgba(0, 30, 60, 0.03);
}

.topics-list .list-header a {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.topics-list a.list-group-item {
  margin-bottom: 0 !important;
}

.topics-list .topic-list-item:last-child a.list-group-item {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.topics-list .list-group-item {
  padding: 1.75rem;
  border: none;
  border-bottom: 1px solid #f1f4f7;
}

.topics-list .list-header .list-group-item {
  padding: 1.5rem 1.75rem;
}

.topics-list .list-group-item-action:focus, .topics-list .list-group-item-action:hover {
  background-color: rgba(250, 250, 250, 0.25);
}

.topics-list .list-header a {
  background-color: rgba(250, 250, 250, 0.75);
  pointer-events: none;
}

.topics-list .title {
  color: #111724;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
}

.topics-list a:hover .title {
  color: #0978f5;
}

/*--- end of topics list group ---*/
.blockquote {
  background: #0978f5;
  color: #fff;
  border: none;
  border-left: none;
  padding: 1.75rem 1.75rem;
  border-radius: 0.125rem;
  position: relative;
  overflow: hidden;
}

.blockquote-reverse {
  background: #f1f4f7;
  border: none;
  border-left: none;
  border-right: none;
  padding: 1.75rem 1.5rem;
  border-radius: 0.25rem;
}

blockquote p {
  font-size: 0.875rem;
  font-weight: 400;
  color: #fff;
}

.blockquote-footer {
  color: #797d85;
  background: transparent;
  font-size: 70%;
  border: 0;
  margin-top: 0;
  padding-top: 10px;
  padding-bottom: 0;
}

.blockquote-bg-icon {
  font-size: 36px;
  color: #dfeeff;
  width: 100px;
  height: 100px;
  line-height: 96px !important;
  text-align: center;
  position: absolute;
  top: -17px;
  left: -5px;
  z-index: 1;
  border: 6px solid #dfeeff;
  border-radius: 0.25rem;
  opacity: 0.2;
  transform: rotate(-17deg);
}

.blockquote-content.has-icon p {
  color: #fff;
  position: relative;
  z-index: 2;
}

cite {
  color: #848d96;
  font-size: 0.8rem;
}

.mark, mark {
  background-color: #848d96;
}

b, strong {
  font-weight: 700 !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.lead {
  font-size: 1.125rem;
  font-weight: 400;
}

form label {
  color: #111724;
  font-size: 0.875rem;
  font-weight: 700;
  display: inline-block;
  margin-bottom: .25rem;
}

.caption {
  font-size: .9rem;
}

.caption-sm {
  font-size: .625rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: .5rem;
}

.ratings i {
  color: #fdcc08;
}

/*--- custom typography ---*/
.section-title {
  margin-bottom: 15px;
}

.section-sub-title {
  color: #797d85;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  padding-top: 10px;
  border-top: 1px solid #0978f5;
  width: max-content;
  opacity: 0.75;
}

.section-title-area {
  position: relative;
  z-index: 1;
}

.text-center .section-sub-title {
  margin: 0 auto;
}

.text-right .section-sub-title {
  margin-left: auto;
  margin-right: 0;
}

.fa {
  vertical-align: middle;
}

/*--- end of custom typography *---/

/*------- end of typography -------*/
/*------- tables -------*/
table {
  background: #fff;
  border: 1px solid #f1f4f7 !important;
  border-radius: 0px !important;
}

.table > :not(caption) > * > * {
  box-shadow: none;
}

.table thead th {
  font-weight: 500;
  vertical-align: middle;
  border-bottom: 1px solid #f1f4f7 !important;
}

tr.last-table-row td {
  border-bottom: 0;
}

.table .thead-light {
  color: #797d85;
  background-color: #fff;
  border: none;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.table thead {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.table td, .table th {
  padding: 1.25rem;
  vertical-align: top;
  border-top: none;
  border-right: 1px solid #f1f4f7 !important;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.table tr {
  border-color: rgba(0, 0, 0, 0.05);
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.table-inverse {
  color: #fff;
  background-color: #303132;
}

.table-inverse td, .table-inverse th, .table-inverse thead th {
  border-color: #fff;
}

.thead-default th {
  color: #797d85;
  background-color: #f1f4f7;
}

.thead-inverse th {
  color: #fff;
  background-color: #191b1d;
}

.thead-dark th {
  color: #fff;
  background-color: #282c31;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f1f4f7;
}

.table-striped tbody tr:nth-of-type(odd) th, .table-striped tbody tr:nth-of-type(odd) td {
  border-color: #e6e9ec !important;
}

.table-striped.table-inverse tbody tr:nth-of-type(odd) {
  background-color: #111724;
}

.table-bordered td, .table-bordered th {
  border: 1px solid #f1f4f7;
}

.table-dark tbody tr:hover {
  background-color: #f1f4f7;
}

.table-active, .table-active > td, .table-active > th {
  background-color: #f1f4f7;
}

.table-dark .table-active:hover {
  background-color: #f1f4f7;
}

.table-dark .table-active:hover > td, .table-dark .table-active:hover > th {
  background-color: #f1f4f7;
}

.table-dark.table-inverse tbody tr:hover {
  background-color: #111724;
}

.table-inverse .table-active, .table-inverse .table-active > td, .table-inverse .table-active > th {
  background-color: #111724;
}

.table-dark.table-inverse .table-active:hover {
  background-color: #111724;
}

.table-dark.table-inverse .table-active:hover > td, .table-dark.table-inverse .table-active:hover > th {
  background-color: #111724;
}

.table-primary, .table-primary > td, .table-primary > th {
  background-color: #0978f5;
}

.table-primary:hover {
  background-color: #005bc1;
}

.table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th {
  background-color: #005bc1;
}

.table-dark .table-primary:hover {
  background-color: #005bc1;
}

.table-dark .table-primary:hover > td, .table-dark .table-primary:hover > th {
  background-color: #005bc1;
}

.table-secondary, .table-secondary > td, .table-secondary > th {
  background-color: #c2c7cd;
}

.table-secondary:hover {
  background-color: #848d96;
}

.table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th {
  background-color: #848d96;
}

.table-dark .table-secondary:hover {
  background-color: #848d96;
}

.table-dark .table-secondary:hover > td, .table-dark .table-secondary:hover > th {
  background-color: #848d96;
}

.table-success, .table-success > td, .table-success > th {
  background-color: #2cb34c;
}

.table-success:hover {
  background-color: #12812c;
}

.table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
  background-color: #12812c;
}

.table-dark .table-success:hover {
  background-color: #12812c;
}

.table-dark .table-success:hover > td, .table-dark .table-success:hover > th {
  background-color: #12812c;
}

.table-info, .table-info > td, .table-info > th {
  background-color: #4f58db;
}

.table-info:hover {
  background-color: #2e36a5;
}

.table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
  background-color: #2e36a5;
}

.table-dark .table-info:hover {
  background-color: #2e36a5;
}

.table-dark .table-info:hover > td, .table-dark .table-info:hover > th {
  background-color: #2e36a5;
}

.table-warning, .table-warning > td, .table-warning > th {
  background-color: #e5a12b;
}

.table-warning:hover {
  background-color: #c27d04;
}

.table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
  background-color: #c27d04;
}

.table-dark .table-warning:hover {
  background-color: #c27d04;
}

.table-dark .table-warning:hover > td, .table-dark .table-warning:hover > th {
  background-color: #c27d04;
}

.table-danger, .table-danger > td, .table-danger > th {
  background-color: #d72f40;
}

.table-danger:hover {
  background-color: #9b1421;
}

.table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
  background-color: #9b1421;
}

.table-dark .table-danger:hover {
  background-color: #9b1421;
}

.table-dark .table-danger:hover > td, .table-dark .table-danger:hover > th {
  background-color: #9b1421;
}

/*--- custom tables ---*/
.table-xl > tbody > tr > td, .table-xl > tbody > tr > th, .table-xl > thead > tr > td, .table-xl > thead > tr > th {
  padding: 1.25rem;
  line-height: 1.15rem;
  vertical-align: middle;
}

.table-xl .checkbox label::before {
  margin-top: -17px;
  margin-left: 0;
}

.table-xl .checkbox label::after {
  margin-top: -13px;
  margin-left: 9px;
}

.table-remove {
  color: #d72f40;
  font-size: 1.25rem;
  transition: 0.2s ease-in-out;
}

.table-remove:hover {
  color: #9b1421;
}

/*------- end of tables -------*/
/*------- alerts-badges-toasts -------*/
/*--- alerts ---*/
.alert {
  font-size: 0.875rem;
  padding: 25px 30px;
  margin-bottom: 15px;
  border: none;
  border-radius: 0.125rem;
  transition: 0.2s ease-in-out;
}

.alert-dismissible {
  padding-right: 60px;
}

.alert-dismissible .btn-close {
  color: #000;
  opacity: 0.5;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0 30px;
  height: 100%;
  box-shadow: none;
  border: none;
  transition: 0.2s ease-in-out;
}

.alert-dismissible .btn-close:hover {
  opacity: 1;
}

.alert-icon {
  line-height: 1;
  vertical-align: middle;
}

.alert-primary {
  background-color: #0978f5;
  border-color: #005bc1;
  color: #fff;
}

.alert-primary-gradient {
  background-color: linear-gradient(180deg, #0978f5 0%, #005bc1 100%);
  border: none !important;
  color: #fff;
}

.alert-secondary {
  background-color: #c2c7cd;
  border-color: #848d96;
  color: #797d85;
}

.alert-secondary-gradient {
  background-color: linear-gradient(180deg, #c2c7cd 0%, #848d96 100%);
  border: none !important;
  color: #797d85;
}

.alert-success {
  background-color: #2cb34c;
  border-color: #12812c;
  color: #fff;
}

.alert-success-gradient {
  color: #fff;
  background: linear-gradient(180deg, #2cb34c 0%, #12812c 100%) !important;
  border: none !important;
}

.alert-success .close, .alert-success-gradient .close {
  color: #12812c;
}

.alert-info {
  background-color: #4f58db;
  border-color: #2e36a5;
  color: #fff;
}

.alert-info-gradient {
  color: #fff;
  background: linear-gradient(180deg, #4f58db 0%, #2e36a5 100%) !important;
  border: none !important;
}

.alert-info .close, .alert-info-gradient .close {
  color: #2e36a5;
}

.alert-warning {
  background-color: #e5a12b;
  border-color: #c27d04;
  color: #fff;
}

.alert-warning .close, .alert-warning-gradient .close {
  color: #c27d04;
}

.alert-warning-gradient {
  color: #fff;
  background: linear-gradient(180deg, #e5a12b 0%, #c27d04 100%) !important;
  border: none !important;
}

.alert-danger {
  background-color: #d72f40;
  border-color: #9b1421;
  color: #fff;
}

.alert-danger .close, .alert-danger-gradient .close {
  color: #9b1421;
}

.alert-danger-gradient {
  color: #fff;
  background: linear-gradient(180deg, #d72f40 0%, #9b1421 100%) !important;
  border: none !important;
}

.alert .close {
  background-color: transparent;
  border: none;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.alert .close:hover {
  color: #000 !important;
  opacity: 0.8;
}

/*--- end of alerts ---*/
/*--- badges ---*/
.badge {
  color: #fff;
  font-size: 65%;
  font-weight: 700;
  line-height: 1.5rem;
  border-radius: 0.1875rem;
  padding: 4px 20px;
  transition: all 0.2s ease;
}

.badge-pill {
  border-radius: 10rem;
}

.badge-primary {
  background-color: #0978f5;
}

.badge-primary-gradient {
  background: linear-gradient(180deg, #0978f5 0%, #005bc1 100%);
}

.badge-outline-primary {
  color: #0978f5;
  background-color: transparent;
  border: 1px solid #0978f5;
}

a.badge-outline-primary:hover, a .badge-outline-primary:hover {
  color: #fff;
  background-color: #0978f5;
  border: 1px solid #0978f5;
}

.badge-secondary {
  color: #fff;
  background-color: #c2c7cd;
}

.badge-secondary-gradient {
  color: #fff;
  background: linear-gradient(180deg, #c2c7cd 0%, #848d96 100%);
}

.badge-outline-secondary {
  color: #c2c7cd;
  background-color: transparent;
  border: 1px solid #c2c7cd;
}

a.badge-outline-secondary:hover, a .badge-outline-secondary:hover {
  color: #fff;
  background-color: #c2c7cd;
  border: 1px solid #c2c7cd;
}

.badge-success {
  background: #2cb34c;
}

.badge-success-gradient {
  background: linear-gradient(180deg, #2cb34c 0%, #12812c 100%);
}

.badge-outline-success {
  color: #2cb34c;
  background-color: transparent;
  border: 1px solid #2cb34c;
}

a.badge-outline-success:hover, a .badge-outline-success:hover {
  color: #fff;
  background-color: #2cb34c;
  border: 1px solid #2cb34c;
}

.badge-info {
  background-color: #4f58db;
}

.badge-info-gradient {
  background: linear-gradient(180deg, #4f58db 0%, #2e36a5 100%);
}

.badge-outline-info {
  color: #4f58db;
  background-color: transparent;
  border: 1px solid #4f58db;
}

a.badge-outline-info:hover, a .badge-outline-info:hover {
  color: #fff;
  background-color: #4f58db;
  border: 1px solid #4f58db;
}

.badge-warning {
  background-color: #e5a12b;
}

.badge-warning-gradient {
  background: linear-gradient(180deg, #e5a12b 0%, #c27d04 100%);
}

.badge-outline-warning {
  color: #e5a12b;
  background-color: transparent;
  border: 1px solid #e5a12b;
}

a.badge-outline-warning:hover, a .badge-outline-warning:hover {
  color: #fff;
  background-color: #e5a12b;
  border: 1px solid #e5a12b;
}

.badge-danger {
  background-color: #d72f40;
}

.badge-danger-gradient {
  background: linear-gradient(180deg, #d72f40 0%, #9b1421 100%);
}

.badge-outline-danger {
  color: #d72f40;
  background-color: transparent;
  border: 1px solid #d72f40;
}

a.badge-outline-danger:hover, a .badge-outline-danger:hover {
  color: #fff;
  background-color: #d72f40;
  border: 1px solid #d72f40;
}

/*-- end of badges ---*/
/*--- toasts ---*/
.toast {
  width: 350px;
  max-width: 100%;
  font-size: .875rem;
  pointer-events: auto;
  background: #fff;
  background-clip: padding-box;
  border: none;
  box-shadow: 0px 2px 8px rgba(0, 30, 60, 0.03);
  border-radius: 0.125rem;
}

.toast-header {
  color: #111724;
  font-size: 1rem;
  background: transparent;
  padding: 1rem 1.5rem 0 1.5rem;
  border-bottom: #f1f4f7;
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
}

.toast-header small {
  font-size: 0.75rem;
  font-weight: 500;
  color: #c2c7cd;
}

.toast .btn-close {
  box-sizing: content-box;
  width: 5px;
  height: 5px;
  box-shadow: none !important;
  border: none !important;
  opacity: .75;
}

.toast-body {
  padding: 1rem 1.5rem;
  background: transparent;
}

.toast.notification {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.toast.notification .btn-close {
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
}

.notification-content {
  padding-right: 2.5rem;
}

/*--- end of toasts --*/
/*------- end of alerts-badges-toasts -------*/
/*------- buttons -------*/
.btn {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.0375rem;
  border: 2px solid transparent;
  border-radius: 0.25rem;
  padding: 0.75rem 2rem;
  white-space: nowrap;
  box-shadow: none;
  transition: all 0.2s ease-in-out;
}

.btn:focus, button.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

button:focus, button:active {
  outline: none !important;
  box-shadow: none !important;
}

.btn:hover, .btn:active {
  cursor: pointer;
  box-shadow: none;
  transform: translateY(-2px);
}

[class*="btn-gradient"] {
  padding: 0.875rem 2.625rem;
}

a.btn, a.btn-social {
  display: inline-block;
}

.btn-group-lg > .btn, .btn-lg {
  padding: 0.75rem 3rem;
  font-size: 1.125rem;
  line-height: 1.875rem;
  border-radius: 0.25rem;
}

[class*="btn-group-lg btn-gradient"], [class*="btn-lg btn-gradient"] {
  padding: 1rem 3.25rem;
}

.btn-group-sm > .btn, .btn-sm {
  padding: 0.75rem 1.875rem;
  font-size: 0.875rem;
  line-height: 1.475;
  border-radius: 0.125rem;
  box-shadow: none;
}

.btn-group-sm > .btn:hover, .btn-sm:hover {
  box-shadow: none;
}

[class*="btn-group-sm btn-gradient"], [class*="btn-sm btn-gradient"] {
  padding: 0.625rem 2.375rem;
}

.btn-group-xs > .btn, .btn-xs {
  padding: 0.375rem 1.25rem;
  font-size: 0.675rem;
  line-height: 1.5rem;
  border-radius: 0.1875rem;
  box-shadow: none;
}

.btn-group-xs > .btn:hover, .btn-xs:hover {
  box-shadow: none;
}

[class*="btn-group-xs btn-gradient"], [class*="btn-xs btn-gradient"] {
  padding: 0.5rem 1.575rem;
}

.btn .bi {
  vertical-align: -1px;
}

.btn i.fas, .btn i.far, .btn i.fab {
  font-size: 0.875rem;
}

.btn-lg i.fas, .btn-lg i.far, .btn-lg i.fab {
  font-size: 1rem;
}

.btn-sm i.fas, .btn-sm i.far, .btn-sm i.fab {
  font-size: 0.75rem;
}

.btn-xs i.fas, .btn-xs i.far, .btn-xs i.fab {
  font-size: 0.5rem;
}

.btn-wide {
  min-width: 10rem;
}

.btn-icon {
  display: inline-block;
  width: 52px;
  height: 52px;
  max-width: 52px !important;
  max-height: 52px !important;
  min-width: auto;
  vertical-align: middle;
  line-height: 52px;
  padding: 0 !important;
}

.btn-icon span, .btn-icon i {
  display: block;
  line-height: 52px;
  margin-top: -1px;
  margin-right: -1px;
}

.btn-circle {
  border-radius: 50% !important;
}

.btn-icon.btn-no-border i, .btn-icon.btn-no-border span {
  margin-top: 0;
}

.btn-lg.btn-icon, .btn-lg.btn-circle {
  width: 62px;
  height: 62px;
  max-width: 62px !important;
  max-height: 62px !important;
  font-size: 18px;
  line-height: 62px;
}

.btn-lg.btn-icon span, .btn-lg.btn-icon i {
  line-height: 62px;
}

.btn-sm.btn-icon, .btn-sm.btn-circle {
  width: 48px;
  height: 48px;
  max-width: 48px !important;
  max-height: 48px !important;
  font-size: 14px;
  line-height: 48px;
}

.btn-sm.btn-icon span, .btn-sm.btn-icon i {
  line-height: 48px;
}

.btn-xs.btn-icon {
  width: 40px;
  height: 40px;
  max-width: 40px !important;
  max-height: 40px !important;
  font-size: 16px;
  line-height: 36px;
}

.btn-xs.btn-icon span, .btn-xs.btn-icon i {
  line-height: 40px;
}

.btn-xs.btn-icon i.fas, .btn-xs.btn-icon i.far, .btn-xs.btn-icon i.fab {
  font-size: 0.75rem;
}

.btn-xs.btn-icon i.bi-x {
  font-size: 20px;
  line-height: 32px;
}

.btn-xs.btn-icon i.bi-pencil {
  line-height: 34px;
}

.btn.btn-w-icon .md-icon.dp16 {
  line-height: 1.5rem;
}

.btn.dropdown-toggle::after {
  font-size: 10px !important;
  line-height: 0;
  position: relative;
  display: inline-block;
  margin-left: 0.5rem;
  top: 0;
  vertical-align: middle;
  transform: none;
}

.dropup .btn.dropdown-toggle::after {
  font-size: 10px !important;
  line-height: 0;
  position: relative;
  display: inline-block;
  margin-left: 0.5rem;
  top: 0;
  vertical-align: middle;
  transform: none;
}

.input-group-btn .btn::after {
  top: 0;
}

.input-group .overlapping-btn {
  right: 2px !important;
}

.nav-item .input-group .overlapping-btn {
  right: 0 !important;
}

.overlapping-btn {
  position: absolute !important;
  right: 0 !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  z-index: 1 !important;
}

.btn.dropdown-toggle.dropdown-toggle-split {
  padding-right: 20px;
  padding-left: 22px;
  min-width: auto;
}

.btn.dropdown-toggle-split:after {
  margin-left: 0;
}

.dropup .btn.dropdown-toggle-split:after {
  margin-left: 0;
}

.dropdown-toggle.dropdown-toggle-split {
  border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.dropdown-toggle.dropdown-toggle-split.bl-0 {
  border-left: 0 !important;
}

.btn.dropdown-toggle {
  position: relative;
}

.input-group-btn .input-icon {
  position: absolute;
  top: 12px;
  font-size: 20px;
}

.input-group-btn .input-icon.left {
  left: 20px;
}

.input-group-btn .input-icon.right {
  right: 20px;
}

.dropdown-menu li:hover .sub-menu {
  visibility: visible;
}

.btn-group .dropdown-menu {
  width: max-content;
  display: block;
  position: absolute;
  -webkit-transition: opacity 0.3s, ease-in-out 0.3s;
  -moz-transition: opacity 0.3s, ease-in-out 0.3s;
  -o-transition: opacity 0.3s, ease-in-out 0.3s;
  transition: opacity 0.3s, ease-in-out 0.3s;
  animation-delay: 0.5s;
  top: 80px;
  left: 50% !important;
  right: 50% !important;
  visibility: hidden;
  opacity: 0;
  transform: translateX(-50%) !important;
}

.btn-group.dropup .dropdown-menu:before {
  top: auto;
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid;
}

.btn-group:hover .dropdown-menu {
  top: 70px !important;
  visibility: visible;
  opacity: 1;
}

.btn-group.dropup .dropdown-menu {
  width: max-content;
  display: block;
  position: absolute;
  -webkit-transition: opacity 0.3s, ease-in-out 0.3s;
  -moz-transition: opacity 0.3s, ease-in-out 0.3s;
  -o-transition: opacity 0.3s, ease-in-out 0.3s;
  transition: opacity 0.3s, ease-in-out 0.3s;
  animation-delay: 0.5s;
  top: auto;
  bottom: 80px;
  visibility: hidden;
  opacity: 0;
}

.btn-group.dropup:hover .dropdown-menu {
  top: auto !important;
  bottom: 70px !important;
  visibility: visible;
  opacity: 1;
}

.btn-group.is-split-drop .dropdown-menu {
  top: 65px !important;
  left: 50% !important;
  right: 50% !important;
  transform: translateX(-6px) !important;
}

.btn-group.is-split-drop.dropup .dropdown-menu {
  top: auto !important;
  bottom: 65px !important;
  left: 50% !important;
  right: 50% !important;
  transform: translateX(-20px) !important;
}

.btn-group.is-split-drop .btn {
  transform: none;
  box-shadow: none;
}

/*--- button colors ---*/
.btn-primary, .btn-primary:focus, .btn-primary.active {
  color: #fff !important;
  background-color: #0978f5 !important;
  border-color: #0978f5 !important;
}

.btn-primary:hover, .btn-primary:active, .show > .btn-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #0978f5 !important;
  border-color: #0978f5 !important;
}

.btn-primary-light, .btn-primary-light:focus, .btn-primary-light.active {
  color: #0978f5 !important;
  background-color: #dfeeff !important;
  border-color: #dfeeff !important;
}

.btn-primary-light:hover, .btn-primary-light:active, .show > .btn-primary-light.dropdown-toggle {
  color: #0978f5 !important;
  background-color: #dfeeff !important;
  border-color: #dfeeff !important;
}

.btn-outline-primary, .btn-outline-primary:focus, .btn-outline-primary.active, .btn-outline-primary:active {
  color: #0978f5 !important;
  background-image: none;
  background-color: transparent !important;
  border-color: #0978f5 !important;
}

.btn-outline-primary:hover, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #0978f5 !important;
  border-color: #0978f5 !important;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff !important;
  background-color: #0978f5 !important;
  border-color: #0978f5 !important;
  opacity: 0.8 !important;
}

.btn-gradient-primary, .btn-gradient-primary:focus, .btn-primary.active {
  color: #fff !important;
  background: linear-gradient(180deg, #0978f5 0%, #005bc1 100%) !important;
  border: none !important;
}

.btn-secondary, .btn-secondary:focus, .btn-secondary.active {
  color: #fff !important;
  background-color: #c2c7cd !important;
  border-color: #c2c7cd !important;
}

.btn-secondary:hover, .btn-secondary:active, .show > .btn-secondary.dropdown-toggle {
  color: #fff !important;
  background-color: #c2c7cd !important;
  border-color: #c2c7cd !important;
}

.btn-secondary-light, .btn-secondary-light:focus, .btn-secondary-light.active {
  color: #848d96 !important;
  background-color: #e4e8ed !important;
  border-color: #e4e8ed !important;
}

.btn-secondary-light:hover, .btn-secondary-light:active, .show > .btn-secondary-light.dropdown-toggle {
  color: #848d96 !important;
  background-color: #e4e8ed !important;
  border-color: #e4e8ed !important;
}

.btn-outline-secondary, .btn-outline-secondary:focus, .btn-outline-secondary.active, .btn-outline-secondary:active {
  color: #c2c7cd !important;
  background-image: none;
  background-color: transparent;
  border-color: #c2c7cd !important;
}

.btn-outline-secondary:hover, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff !important;
  background-color: #c2c7cd !important;
  border-color: #c2c7cd !important;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff !important;
  background-color: #c2c7cd !important;
  border-color: #c2c7cd !important;
  opacity: 0.8 !important;
}

.btn-gradient-secondary, .btn-gradient-secondary:focus, .btn-secondary.active {
  color: #fff !important;
  background: linear-gradient(180deg, #c2c7cd 0%, #848d96 100%) !important;
  border: none !important;
}

.btn-success, .btn-success:focus, .btn-success.active, .btn-success:active {
  color: #fff !important;
  background-color: #2cb34c !important;
  border-color: #2cb34c !important;
}

.btn-success:hover, .show > .btn-success.dropdown-toggle {
  color: #fff !important;
  background-color: #2cb34c !important;
  border-color: #2cb34c !important;
}

.btn-success-light, .btn-success-light:focus, .btn-success-light.active, .btn-success-light:active {
  color: #2cb34c !important;
  background-color: #c7fbd3 !important;
  border-color: #c7fbd3 !important;
}

.btn-success-light:hover, .show > .btn-success-light.dropdown-toggle {
  color: #2cb34c !important;
  background-color: #c7fbd3 !important;
  border-color: #c7fbd3 !important;
}

.btn-outline-success, .btn-outline-success:focus, .btn-outline-success.active, .btn-outline-success:active {
  color: #2cb34c !important;
  background-image: none;
  background-color: transparent !important;
  border-color: #2cb34c !important;
}

.btn-outline-success:hover, .show > .btn-outline-success.dropdown-toggle {
  color: #fff !important;
  background-color: #2cb34c !important;
  border-color: #2cb34c !important;
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff !important;
  background-color: #2cb34c !important;
  border-color: #2cb34c !important;
  opacity: 0.8 !important;
}

.btn-gradient-success, .btn-gradient-success:focus, .btn-success.active {
  color: #fff !important;
  background: linear-gradient(180deg, #2cb34c 0%, #12812c 100%) !important;
  border: none !important;
}

.btn-info, .btn-info:focus, .btn-info.active, .btn-info:active {
  color: #fff !important;
  background-color: #4f58db !important;
  border-color: #4f58db !important;
}

.btn-info:hover, .show > .btn-info.dropdown-toggle {
  color: #fff !important;
  background-color: #4f58db !important;
  border-color: #4f58db !important;
}

.btn-info-light, .btn-info-light:focus, .btn-info-light.active, .btn-info-light:active {
  color: #4f58db !important;
  background-color: #c7cafa !important;
  border-color: #c7cafa !important;
}

.btn-info-light:hover, .show > .btn-info-light.dropdown-toggle {
  color: #4f58db !important;
  background-color: #c7cafa !important;
  border-color: #c7cafa !important;
}

.btn-outline-info, .btn-outline-info:focus, .btn-outline-info.active, .btn-outline-info:active {
  color: #4f58db !important;
  background-image: none;
  background-color: transparent !important;
  border-color: #4f58db !important;
}

.btn-outline-info:hover, .show > .btn-outline-info.dropdown-toggle {
  color: #fff !important;
  background-color: #4f58db !important;
  border-color: #4f58db !important;
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff !important;
  background-color: #4f58db !important;
  border-color: #4f58db !important;
  opacity: 0.8 !important;
}

.btn-gradient-info, .btn-gradient-info:focus, .btn-info.active {
  color: #fff !important;
  background: linear-gradient(180deg, #4f58db 0%, #2e36a5 100%) !important;
  border: none !important;
}

.btn-warning, .btn-warning:focus, .btn-warning.active, .btn-warning:active {
  color: #fff !important;
  background-color: #e5a12b !important;
  border-color: #e5a12b !important;
}

.btn-warning:hover, .show > .btn-warning.dropdown-toggle {
  color: #fff !important;
  background-color: #e5a12b !important;
  border-color: #e5a12b !important;
}

.btn-warning-light, .btn-warning-light:focus, .btn-warning-light.active, .btn-warning-light:active {
  color: #e5a12b !important;
  background-color: #f9e0b4 !important;
  border-color: #f9e0b4 !important;
}

.btn-warning-light:hover, .show > .btn-warning-light.dropdown-toggle {
  color: #e5a12b !important;
  background-color: #f9e0b4 !important;
  border-color: #f9e0b4 !important;
}

.btn-outline-warning, .btn-outline-warning:focus, .btn-outline-warning.active, .btn-outline-warning:active {
  color: #e5a12b !important;
  background-image: none;
  background-color: transparent !important;
  border-color: #e5a12b !important;
}

.btn-outline-warning:hover, .show > .btn-outline-warning.dropdown-toggle {
  color: #fff !important;
  background-color: #e5a12b !important;
  border-color: #e5a12b !important;
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #fff !important;
  background-color: #2cb34c !important;
  border-color: #2cb34c !important;
  opacity: 0.8 !important;
}

.btn-gradient-warning, .btn-gradient-warning:focus, .btn-warning.active {
  color: #fff !important;
  background: linear-gradient(180deg, #e5a12b 0%, #c27d04 100%) !important;
  border: none !important;
}

.btn-danger, .btn-danger:focus, .btn-danger.active, .btn-danger:active {
  color: #fff !important;
  background-color: #d72f40 !important;
  border-color: #d72f40 !important;
}

.btn-danger:hover, .show > .btn-danger.dropdown-toggle {
  color: #fff !important;
  background-color: #d72f40 !important;
  border-color: #d72f40 !important;
}

.btn-danger-light, .btn-danger-light:focus, .btn-danger-light.active, .btn-danger-light:active {
  color: #d72f40 !important;
  background-color: #f9bcc2 !important;
  border-color: #f9bcc2 !important;
}

.btn-danger-light:hover, .show > .btn-danger-light.dropdown-toggle {
  color: #d72f40 !important;
  background-color: #f9bcc2 !important;
  border-color: #f9bcc2 !important;
}

.btn-outline-danger, .btn-outline-danger:focus, .btn-outline-danger.active, .btn-outline-danger:active {
  color: #d72f40 !important;
  background-image: none;
  background-color: transparent !important;
  border-color: #d72f40 !important;
}

.btn-outline-danger:hover, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff !important;
  background-color: #d72f40 !important;
  border-color: #d72f40 !important;
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff !important;
  background-color: #d72f40 !important;
  border-color: #d72f40 !important;
  opacity: 0.8 !important;
}

.btn-gradient-danger, .btn-gradient-danger:focus, .btn-danger.active {
  color: #fff !important;
  background: linear-gradient(180deg, #d72f40 0%, #9b1421 100%) !important;
  border: none !important;
}

.btn-white, .btn-white:focus, .btn-white.active, .btn-white:active {
  color: #111724 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}

.btn-white:hover, .show > .btn-white.dropdown-toggle {
  color: #111724 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}

.btn-outline-white, .btn-outline-white:focus, .btn-outline-white.active, .btn-outline-white:active {
  color: #fff !important;
  background-image: none;
  background-color: transparent !important;
  border-color: white !important;
}

.btn-outline-white:hover, .show > .btn-outline-white.dropdown-toggle {
  color: #111724 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}

.btn-white.disabled, .btn-white:disabled {
  opacity: 0.8 !important;
}

.btn-gradient-white, .btn-gradient-white:focus, .btn-white.active {
  color: #111724 !important;
  background: linear-gradient(180deg, #fff 0%, #f1f4f7 100%) !important;
  border: none !important;
}

.btn-dark, .btn-dark:focus, .btn-dark.active, .btn-dark:active {
  color: #fff !important;
  background-color: #282c31 !important;
  border-color: #282c31 !important;
}

.btn-dark:hover, .show > .btn-dark.dropdown-toggle {
  color: #fff !important;
  background-color: #282c31 !important;
  border-color: #282c31 !important;
}

.btn-outline-dark, .btn-outline-dark:focus, .btn-outline-dark.active, .btn-outline-dark:active {
  color: #000 !important;
  background-image: none;
  background-color: transparent !important;
  border-color: #282c31 !important;
}

.btn-outline-dark:hover, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff !important;
  background-color: #282c31 !important;
  border-color: #282c31 !important;
}

.btn-dark.disabled, .btn-dark:disabled {
  opacity: 0.8;
}

.btn-gradient-dark, .btn-gradient-dark:focus, .btn-dark.active {
  color: #fff !important;
  background: linear-gradient(180deg, #303132 0%, #282c31 100%) !important;
  border: none !important;
}

.btn-black, .btn-black:focus, .btn-black.active, .btn-black:active {
  color: #fff !important;
  background-color: #000 !important;
  border-color: #000 !important;
}

.btn-black:hover, .show > .btn-black.dropdown-toggle {
  color: #fff !important;
  background-color: #000 !important;
  border-color: #000 !important;
}

.btn-outline-black, .btn-outline-black:focus, .btn-outline-black.active, .btn-outline-black:active {
  color: #000 !important;
  background-image: none;
  background-color: transparent !important;
  border-color: #000 !important;
}

.btn-outline-black:hover, .show > .btn-outline-black.dropdown-toggle {
  color: #fff !important;
  background-color: #000 !important;
  border-color: #000 !important;
}

.btn-black.disabled, .btn-black:disabled {
  opacity: 0.8;
}

.btn-gradient-black, .btn-gradient-black:focus, .btn-black.active {
  color: #fff !important;
  background: linear-gradient(180deg, #515254 0%, #000 100%) !important;
  border: none !important;
}

.btn-link, .btn-link:focus, .btn-link.active, .btn-link:active {
  color: #0978f5;
  text-decoration: none;
  box-shadow: none;
}

.btn-link:hover, .show > .btn-link.dropdown-toggle {
  color: #005bc1 !important;
  text-decoration: none;
  box-shadow: none;
}

.btn-link.disabled, .btn-link:disabled {
  color: #0978f5;
  opacity: 0.8 !important;
}

.btn-link span {
  margin-left: 1px !important;
}

/*--- end of button colors ---*/
/*--- social buttons ---*/
/* facebook button */
.btn-facebook, .btn-facebook:active, .btn-facebook:focus {
  color: #fff !important;
  background: #4e68a1;
  border-color: #4e68a1;
}

.btn-facebook:hover {
  color: #fff !important;
  background: #3b4f7a;
  border-color: #3b4f7a;
}

.btn-facebook-link, .btn-facebook-link:active, .btn-facebook-link:focus {
  color: #4e68a1 !important;
  background: transparent;
  border: none;
}

.btn-facebook-link:hover {
  color: #3b4f7a !important;
}

.btn-outline-facebook, .btn-outline-facebook:active, .btn-outline-facebook:focus {
  color: #4e68a1 !important;
  background: transparent;
  border-color: #4e68a1;
}

.btn-outline-facebook:hover {
  color: #fff !important;
  background: #4e68a1;
  border-color: #4e68a1;
}

/* twitter button */
.btn-twitter, .btn-twitter:active, .btn-twitter:focus {
  color: #fff !important;
  background: #65b5f2;
  border-color: #65b5f2;
}

.btn-twitter:hover {
  color: #fff !important;
  background: #5294c6;
  border-color: #5294c6;
}

.btn-twitter:hover {
  color: #fff !important;
  background: #5294c6;
  border-color: #5294c6;
}

.btn-twitter-link, .btn-twitter-link:active, .btn-twitter-link:focus {
  color: #65b5f2 !important;
  background: transparent;
  border: none;
}

.btn-twitter-link:hover {
  color: #5294c6 !important;
}

.btn-outline-twitter, .btn-outline-twitter:active, .btn-outline-twitter:focus {
  color: #65b5f2 !important;
  background: transparent;
  border-color: #65b5f2;
}

.btn-outline-twitter:hover {
  color: #fff !important;
  background: #65b5f2;
  border-color: #65b5f2;
}

/* linkedin button */
.btn-linkedin, .btn-linkedin:active, .btn-linkedin:focus {
  color: #fff !important;
  background: #2083bc;
  border-color: #2083bc;
}

.btn-linkedin:hover {
  color: #fff !important;
  background: #186592;
  border-color: #186592;
}

.btn-linkedin-link, .btn-linkedin-link:active, .btn-linkedin-link:focus {
  color: #2083bc !important;
  background: transparent;
  border: none;
}

.btn-linkedin-link:hover {
  color: #186592 !important;
}

.btn-outline-linkedin, .btn-outline-linkedin:active, .btn-outline-linkedin:focus {
  color: #2083bc !important;
  background: transparent;
  border-color: #2083bc;
}

.btn-outline-linkedin:hover {
  color: white !important;
  background: #2083bc;
  border-color: #2083bc;
}

/* pinterest button */
.btn-pinterest, .btn-pinterest:active, .btn-pinterest:focus {
  color: #fff !important;
  background: #d2373b;
  border-color: #d2373b;
}

.btn-pinterest:hover {
  color: #fff !important;
  background: #ad2c2f;
  border-color: #ad2c2f;
}

.btn-pinterest-link, .btn-pinterest-link:active, .btn-pinterest-link:focus {
  color: #d2373b !important;
  background: transparent;
  border: none;
}

.btn-pinterest-link:hover {
  color: #ad2c2f !important;
}

.btn-outline-pinterest, .btn-outline-pinterest:active, .btn-outline-pinterest:focus {
  color: #d2373b !important;
  background: transparent;
  border-color: #d2373b;
}

.btn-outline-pinterest:hover {
  color: #fff !important;
  background: #d2373b;
  border-color: #d2373b;
}

/* dribble button */
.btn-dribbble, .btn-dribbble:active, .btn-dribbble:focus {
  color: #fff !important;
  background: #ec5f94;
  border-color: #ec5f94;
}

.btn-dribbble:hover {
  color: #fff !important;
  background: #b4446e;
  border-color: #b4446e;
}

.btn-dribbble-link, .btn-dribbble-link:active, .btn-dribbble-link:focus {
  color: #ec5f94 !important;
  background: transparent;
  border: none;
}

.btn-dribbble-link:hover {
  color: #b4446e !important;
}

.btn-outline-dribbble, .btn-outline-dribbble:active, .btn-outline-dribbble:focus {
  color: #ec5f94 !important;
  background: transparent;
  border-color: #ec5f94;
}

.btn-outline-dribbble:hover {
  color: #fff !important;
  background: #ec5f94;
  border-color: #ec5f94;
}

/* instagram button */
.btn-instagram, .btn-instagram:active, .btn-instagram:focus {
  color: #fff !important;
  background: #4c5fd7;
  border-color: #4c5fd7;
}

.btn-instagram:hover {
  color: #fff !important;
  background: #4252ba;
  border-color: #4252ba;
}

.btn-instagram-link, .btn-instagram-link:active, .btn-instagram-link:focus {
  color: #4c5fd7 !important;
  background: transparent;
  border: none;
}

.btn-instagram-link:hover {
  color: #4252ba !important;
}

.btn-outline-instagram, .btn-outline-instagram:active, .btn-outline-instagram:focus {
  color: #4c5fd7 !important;
  background: transparent;
  border-color: #4c5fd7;
}

.btn-outline-instagram:hover {
  color: #fff !important;
  background: #4c5fd7;
  border-color: #4c5fd7;
}

/* youtube button */
.btn-youtube, .btn-youtube:active, .btn-youtube:focus {
  color: #fff !important;
  background: #e52d27;
  border-color: #e52d27;
}

.btn-youtube:hover {
  color: #fff !important;
  background: #b31217;
  border-color: #b31217;
}

.btn-youtube-link, .btn-youtube-link:active, .btn-youtube-link:focus {
  color: #e52d27 !important;
  background: transparent;
  border: none;
}

.btn-youtube-link:hover {
  color: #b31217 !important;
}

.btn-outline-youtube, .btn-outline-youtube:active, .btn-outline-youtube:focus {
  color: #e52d27 !important;
  background: transparent;
  border-color: #e52d27;
}

.btn-outline-youtube:hover {
  color: #fff !important;
  background: #e52d27;
  border-color: #e52d27;
}

/* behance button */
.btn-behance, .btn-behance:active, .btn-behance:focus {
  color: #fff !important;
  background: #053eff;
  border-color: #053eff;
}

.btn-behance:hover {
  color: #fff !important;
  background: #062fbb;
  border-color: #062fbb;
}

.btn-behance-link, .btn-behance-link:active, .btn-behance-link:focus {
  color: #053eff !important;
  background: transparent;
  border: none;
}

.btn-behance-link:hover {
  color: #062fbb !important;
}

.btn-outline-behance, .btn-outline-behance:active, .btn-outline-behance:focus {
  color: #053eff !important;
  background: transparent;
  border-color: #053eff;
}

.btn-outline-behance:hover {
  color: #fff !important;
  background: #053eff;
  border-color: #053eff;
}

/* reddit button */
.btn-reddit, .btn-reddit:active, .btn-reddit:focus {
  color: #fff !important;
  background: #ff4500;
  border-color: #ff4500;
}

.btn-reddit:hover {
  color: #fff !important;
  background: #c13400;
  border-color: #c13400;
}

.btn-reddit-link, .btn-reddit-link:active, .btn-reddit-link:focus {
  color: #ff4500 !important;
  background: transparent;
  border: none;
}

.btn-reddit-link:hover {
  color: #c13400 !important;
}

.btn-outline-reddit, .btn-outline-reddit:active, .btn-outline-reddit:focus {
  color: #ff4500 !important;
  background: transparent;
  border-color: #ff4500;
}

.btn-outline-reddit:hover {
  color: #fff !important;
  background: #ff4500;
  border-color: #ff4500;
}

/* dropbox button */
.btn-dropbox, .btn-dropbox:active, .btn-dropbox:focus {
  color: #fff !important;
  background: #3d9ae8;
  border-color: #3d9ae8;
}

.btn-dropbox:hover {
  color: #fff !important;
  background: #2777bb;
  border-color: #2777bb;
}

.btn-dropbox-link, .btn-dropbox-link:active, .btn-dropbox-link:focus {
  color: #3d9ae8 !important;
  background: transparent;
  border: none;
}

.btn-dropbox-link:hover {
  color: #2777bb !important;
}

.btn-outline-dropbox, .btn-outline-dropbox:active, .btn-outline-dropbox:focus {
  color: #3d9ae8 !important;
  background: transparent;
  border-color: #3d9ae8;
}

.btn-outline-dropbox:hover {
  color: #fff !important;
  background: #3d9ae8;
  border-color: #3d9ae8;
}

/* skype button */
.btn-skype, .btn-skype:active, .btn-skype:focus {
  color: #fff !important;
  background: #00aff0;
  border-color: #00aff0;
}

.btn-skype:hover {
  color: #fff !important;
  background: #068bbd;
  border-color: #068bbd;
}

.btn-skype-link, .btn-skype-link:active, .btn-skype-link:focus {
  color: #00aff0 !important;
  background: transparent;
  border: none;
}

.btn-skype-link:hover {
  color: #068bbd !important;
}

.btn-outline-skype, .btn-outline-skype:active, .btn-outline-skype:focus {
  color: #00aff0 !important;
  background: transparent;
  border-color: #00aff0;
}

.btn-outline-skype:hover {
  color: #fff !important;
  background: #00aff0;
  border-color: #00aff0;
}

/* vimeo button */
.btn-vimeo, .btn-vimeo:active, .btn-vimeo:focus {
  color: #fff !important;
  background: #1ab7ea;
  border-color: #1ab7ea;
}

.btn-vimeo:hover {
  color: #fff !important;
  background: #0d91bc;
  border-color: #0d91bc;
}

.btn-vimeo-link, .btn-vimeo-link:active, .btn-vimeo-link:focus {
  color: #1ab7ea !important;
  background: transparent;
  border: none;
}

.btn-vimeo-link:hover {
  color: #0d91bc !important;
}

.btn-outline-vimeo, .btn-outline-vimeo:active, .btn-outline-vimeo:focus {
  color: #1ab7ea !important;
  background: transparent;
  border-color: #1ab7ea;
}

.btn-outline-vimeo:hover {
  color: #fff !important;
  background: #1ab7ea;
  border-color: #1ab7ea;
}

/* tumblr button */
.btn-tumblr, .btn-tumblr:active, .btn-tumblr:focus {
  color: #fff !important;
  background: #34526f;
  border-color: #34526f;
}

.btn-tumblr:hover {
  color: #fff !important;
  background: #172635;
  border-color: #172635;
}

.btn-tumblr-link, .btn-tumblr-link:active, .btn-tumblr-link:focus {
  color: #34526f !important;
  background: transparent;
  border: none;
}

.btn-tumblr-link:hover {
  color: #172635 !important;
}

.btn-outline-tumblr, .btn-outline-tumblr:active, .btn-outline-tumblr:focus {
  color: #34526f !important;
  background: transparent;
  border-color: #34526f;
}

.btn-outline-tumblr:hover {
  color: #fff !important;
  background: #34526f;
  border-color: #34526f;
}

/* github button */
.btn-github, .btn-github:active, .btn-github:focus {
  color: #fff !important;
  background: #333;
  border-color: #333;
}

.btn-github:hover {
  color: #fff !important;
  background: #111;
  border-color: #111;
}

.btn-github-link, .btn-github-link:active, .btn-github-link:focus {
  color: #333 !important;
  background: transparent;
  border: none;
}

.btn-github-link:hover {
  color: #111 !important;
}

.btn-outline-github, .btn-outline-github:active, .btn-outline-github:focus {
  color: #333 !important;
  background: transparent;
  border-color: #333;
}

.btn-outline-github:hover {
  color: #fff !important;
  background: #333;
  border-color: #333;
}

/*--- end of social buttons ---*/
.btn-back-top {
  border: 1px solid rgba(0, 0, 0, 0.05) !important;
  width: 60px;
  height: 60px;
  position: absolute;
  top: -31px;
  left: auto;
  right: auto;
  opacity: 1 !important;
  transform: translateX(-50%);
  box-shadow: none;
  transition: all 0.2s ease-in-out;
}

.border-white .btn-back-top {
  border: 1px solid #fff !important;
}

.btn-back-top:hover, .btn-back-top:focus, .btn-back-top:active {
  background-color: #0978f5;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.border-white .btn-back-top:hover, .border-white .btn-back-top:focus, .border-white .btn-back-top:active {
  border: 1px solid #fff !important;
}

.btn-back-top i {
  color: #0978f5 !important;
  font-size: 26px;
  margin-top: -1px;
  margin-right: -1px;
  transition: all 0.2s ease-in-out;
}

.bg-primary .btn-back-top {
  background-color: #0978f5 !important;
}

.bg-primary .btn-back-top i {
  color: #fff !important;
}

.bg-light .btn-back-top, .bg-light .btn-back-top:focus, .bg-light .btn-back-top:active {
  background-color: #f1f4f7 !important;
}

.bg-light .btn-back-top i {
  color: #282c31 !important;
}

.bg-white .btn-back-top, .bg-white .btn-back-top:focus, .bg-white .btn-back-top:active {
  background-color: #fff !important;
}

.bg-white .btn-back-top i {
  color: #d6d7d8 !important;
}

.bg-light .btn-back-top:hover, .bg-white .btn-back-top:hover {
  background-color: #0978f5 !important;
}

.bg-light .btn-back-top:hover i, .bg-white .btn-back-top:hover i {
  color: #fff !important;
}

.bg-dark .btn-back-top, .bg-dark .btn-back-top:focus, .bg-dark .btn-back-top:active {
  background-color: #282c31 !important;
}

.bg-dark .btn-back-top i {
  color: #fff !important;
}

/*------- end of buttons -------*/
/*------- popovers & tooltips -------*/
.popover-example {
  position: relative;
}

.popover-example .popover {
  position: relative;
  display: block;
  float: left;
  width: 23%;
  margin: 1%;
  z-index: 9;
}

.popover {
  max-width: 260px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: .875rem;
  background-color: #fff;
  border: none;
  border-radius: 0.125rem;
  padding: 0;
  box-shadow: 0px 2px 8px rgba(0, 30, 60, 0.03);
}

.popover-header {
  color: #111724 !important;
  font-size: 1rem;
  background-color: transparent;
  border-bottom: none;
  padding: .75rem 1rem 0;
}

.popover-header::before {
  display: none !important;
}

.popover-body {
  padding: .75rem 1rem;
}

.popover-body p {
  font-size: .875rem;
}

.popover-title {
  font-size: 1rem;
  font-weight: 400;
  background-color: transparent;
  border-bottom: 0;
  padding: .75rem 1rem;
}

.popover-content {
  padding: 0 1rem .75rem 1rem;
}

.popover-content p {
  font-size: .875rem;
}

.bs-popover-top .popover-arrow:after, .bs-popover-top .popover-arrow:before {
  border-top-color: #fff;
}

.bs-popover-auto[data-popper-placement^=top] > .popover-arrow, .bs-popover-top > .popover-arrow {
  bottom: calc(-.4rem - 1px);
}

.bs-popover-bottom .popover-arrow:after, .bs-popover-bottom .popover-arrow:before {
  border-bottom-color: #fff;
}

.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow, .bs-popover-bottom > .popover-arrow {
  top: calc(-.4rem - 1px);
}

.bs-popover-start .popover-arrow:after, .bs-popover-start .popover-arrow:before {
  border-left-color: #fff;
}

.bs-popover-auto[data-popper-placement^=left] > .popover-arrow, .bs-popover-start > .popover-arrow {
  right: calc(-.4rem - 1px);
}

.bs-popover-end .popover-arrow:after, .bs-popover-end .popover-arrow:before {
  border-right-color: #fff;
}

.bs-popover-auto[data-popper-placement^=right] > .popover-arrow, .bs-popover-end > .popover-arrow {
  left: calc(-.4rem - 1px);
}

/*--- tooltips ---*/
.tooltips-example {
  position: relative;
}

.tooltips-example .tooltip {
  position: relative;
  display: inline-block;
  margin: 10px 20px;
  opacity: 1;
}

.tooltip {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: .75rem;
}

.tooltip-inner {
  max-width: 200px;
  padding: 7px 18px;
  color: #f1f4f7;
  text-align: center;
  background-color: #191b1d;
  border: none;
  border-radius: 0.125rem;
  opacity: 1 !important;
  box-shadow: 0px 1px 4px rgba(0, 30, 60, 0.03);
}

.tooltip.bs-tooltip-auto[x-placement^=top] .tooltip-arrow::before, .tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #191b1d;
}

.tooltip.bs-tooltip-auto[x-placement^=bottom] .tooltip-arrow::before, .tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #191b1d;
}

.tooltip.bs-tooltip-auto[x-placement^=start] .tooltip-arrow::before, .tooltip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #191b1d;
}

.tooltip.bs-tooltip-auto[x-placement^=end] .tooltip-arrow::before, .tooltip.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #191b1d;
}

/*--- end of tooltips ---*/
/*------- end of popovers & tooltips -------*/
/*------- inputs -------*/
.form-control {
  color: #797d85;
  background: transparent;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  display: inline-block;
  width: 100%;
  height: 52px;
  letter-spacing: 0.075rem;
  padding: 15px 20px;
  background-image: none;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #c2c7cd;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  appearance: none;
  transition: all ease-in-out .2s;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.fc-inverse {
  background-color: #191b1d;
  color: #fff !important;
  border-color: #fff;
}

input:focus, textarea:focus, .custom-select:focus, .multiple-selector:focus {
  color: #797d85 !important;
  background-color: transparent !important;
  outline: none !important;
  box-shadow: 0px 1px 4px rgba(0, 30, 60, 0.03) !important;
  -webkit-box-shadow: 0px 1px 4px rgba(0, 30, 60, 0.03) !important;
  border-color: #282c31 !important;
  z-index: 0 !important;
}

input.fc-inverse:focus, textarea.fc-inverse:focus {
  color: #fff !important;
  border-color: primary !important;
  background-color: #191b1d;
}

input.fc-inverse.bg-transparent:focus, textarea.fc-inverse.bg-transparent::focus {
  background-color: transparent !important;
}

.qty {
  max-width: 60px;
  height: 52px;
  max-height: 52px;
  margin-left: 0 !important;
  padding: 13px 5px 13px 13px;
}

table .qty {
  height: 46px;
  max-height: 46px;
}

select.form-control.shop-option:not([size]):not([multiple]) {
  height: 52px;
}

.custom-select {
  color: #797d85;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.15rem;
  display: inline-block;
  width: 100%;
  height: 52px;
  letter-spacing: 0.06rem;
  border: 1px solid #c2c7cd;
  border-radius: 0.25rem;
  padding: 1rem 1.85rem 1rem 1.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
}

input, textarea {
  font-family: "Poppins", sans-serif !important;
  letter-spacing: 0.02rem;
}

select.form-control:not([size]):not([multiple]) {
  height: 52px;
}

select.selector {
  -webkit-appearance: menulist !important;
}

select.multiple-selector {
  -webkit-appearance: listbox !important;
  max-height: 52px;
}

select:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: #0978f5 !important;
}

option {
  padding: 5px 0;
}

.multiple-selector option {
  padding: 5px 15px;
  text-align: center;
}

.btn-file {
  position: relative;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.0375rem !important;
}

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

[type=submit], .btn-submit {
  height: 52px;
  min-height: 52px;
  max-height: 52px;
  line-height: 1;
}

.form-control.w-icon-left {
  border: 1px solid #f1f4f7;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important;
}

.log-line {
  margin-top: 20px;
  margin-bottom: 20px;
}

.input-group-text {
  color: #111724;
  background: #fff;
  font-size: 16px;
  height: 100%;
  min-height: 52px;
  line-height: 52px;
  padding: 0.5rem 1rem;
  border: 1px solid #f1f4f7;
  border-radius: 0.125rem;
}

.input-group-prepend .input-group-text {
  border-radius: 0;
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.input-group-btn .btn {
  height: 100%;
  max-height: 52px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-group-btn .btn.has-span, .input-group-btn .btn.has-icon {
  line-height: 52px;
}

.input-group-btn .btn span {
  display: inline-block;
  line-height: 1.5rem;
}

.input-group-btn .btn-round {
  height: 52px;
  border-radius: 50% !important;
  width: 52px;
  padding: 16px;
}

.input-group-btn .btn-round i {
  font-size: 16px !important;
  line-height: 1.5rem;
}

.form-inline.dark .form-control {
  color: #fff;
  background: #d6d7d8;
  border: 1px solid #d6d7d8;
}

.bg-primary .form-inline.dark .form-control {
  color: #111724;
  background: #fff;
  border: 1px solid #fff;
}

.input-group-addon {
  color: #797d85;
  background-color: #c2c7cd;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 3.5;
  text-align: center;
  border: 1px solid #c2c7cd;
  border-radius: 0.1875rem;
  padding: 0 1rem;
  margin-bottom: 0;
}

.form-control-sm, .input-group-sm > .form-control, .input-group-sm > .input-group-addon, .input-group-sm > .input-group-btn > .btn {
  padding: .6075rem .7rem;
  font-size: .8rem;
}

.form-control-xs, .input-group-xs > .form-control, .input-group-xs > .input-group-addon, .input-group-xs > .input-group-btn > .btn {
  padding: .45rem .5rem;
  font-size: .6rem;
}

.input-group .form-control:not(:last-child), .input-group-addon:not(:last-child), .input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn, .input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle), .input-group-btn:not(:last-child) > .btn, .input-group-btn:not(:last-child) > .btn-group > .btn, .input-group-btn:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group .form-control:not(:first-child), .input-group-addon:not(:first-child), .input-group-btn:not(:first-child) > .btn, .input-group-btn:not(:first-child) > .btn-group > .btn, .input-group-btn:not(:first-child) > .dropdown-toggle, .input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn, .input-group-btn:not(:last-child) > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-addon:not(:last-child) {
  border: none;
}

.input-group-addon {
  border: none;
}

.input-group-btn:not(:last-child) > .btn, .input-group-btn:not(:last-child) > .btn-group {
  margin-right: 0;
}

.input-group-btn:not(:first-child) > .btn, .input-group-btn:not(:first-child) > .btn-group {
  margin-left: 0;
}

.form-group, .input-group {
  margin-bottom: 25px;
}

.navbar .input-group {
  margin-bottom: 0;
}

.form-inline.dark input:focus, .form-inline.dark textarea:focus {
  color: #fff !important;
  border-color: #0978f5;
}

.input-w-overlap-btn .btn {
  height: 46px !important;
  max-height: 46px !important;
}

.input-w-overlap-btn .btn-icon {
  width: 46px !important;
  height: 46px !important;
  max-width: 46px !important;
  max-height: 46px !important;
}

.input-w-overlap-btn .btn-icon i {
  line-height: 46px;
}

.bg-primary .form-inline.dark input:focus, .bg-primary .form-inline.dark textarea:focus {
  color: #111724 !important;
  border-color: #0978f5;
}

input[type=datetime-local] {
  padding: 11px 15px;
}

textarea.form-control {
  margin-bottom: -7px;
  min-height: 120px;
}

.prepend-w-textarea textarea.form-control {
  margin-bottom: 0;
}

.btn-submit {
  width: 100%;
  opacity: 1 !important;
  border: 2px solid transparent;
}

.btn-submit-auto {
  opacity: 1 !important;
  border: 2px solid transparent;
}

/*--- form validator ---*/
.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: #d72f40 !important;
  background-image: none !important;
}

.form-control.is-valid, .was-validated .form-control:valid {
  border-color: #2cb34c !important;
  background-image: none !important;
}

.needs-validation .form-control:focus, .needs-validation .form-control:active {
  border-color: #282c31 !important;
}

/*--- end of form validator ---*/
/*--- input placeholder color ---*/
::-webkit-input-placeholder {
  color: #848d96 !important;
}

::-moz-placeholder {
  color: #848d96 !important;
}

:-moz-placeholder {
  color: #848d96 !important;
}

:-ms-input-placeholder {
  color: #848d96 !important;
}

.fc-inverse::-webkit-input-placeholder {
  color: #c2c7cd !important;
}

.fc-inverse::-moz-placeholder {
  color: #c2c7cd !important;
}

.fc-inverse:-moz-placeholder {
  color: #c2c7cd !important;
}

.fc-inverse:-ms-input-placeholder {
  color: #c2c7cd !important;
}

.error::-webkit-input-placeholder {
  color: #d72f40 !important;
}

.error::-moz-placeholder {
  color: #d72f40 !important;
}

.error:-moz-placeholder {
  color: #d72f40 !important;
}

.error:-ms-input-placeholder {
  color: #d72f40 !important;
}

.valid::-webkit-input-placeholder {
  color: #d72f40 !important;
}

.valid::-moz-placeholder {
  color: #d72f40 !important;
}

.valid:-moz-placeholder {
  color: #d72f40 !important;
}

.valid:-ms-input-placeholder {
  color: #d72f40 !important;
}

.form-inline.dark ::-webkit-input-placeholder {
  color: #fff !important;
}

.form-inline.dark ::-moz-placeholder {
  color: #fff !important;
}

.form-inline.dark :-ms-input-placeholder {
  color: #fff !important;
}

.form-inline.dark :-moz-placeholder {
  color: #fff !important;
}

.bg-primary .form-inline.dark ::-webkit-input-placeholder {
  color: #111724 !important;
}

.bg-primary .form-inline.dark ::-moz-placeholder {
  color: #111724 !important;
}

.bg-primary .form-inline.dark :-ms-input-placeholder {
  color: #111724 !important;
}

.bg-primary .form-inline.dark :-moz-placeholder {
  color: #111724 !important;
}

/*--- end of input placeholder color ---*/
/*--- checkbox ---*/
.checkbox-group {
  margin: 5px 20px 5px 10px;
}

.checkbox label {
  display: inline-block;
  position: relative;
  font-weight: 400;
  padding-left: 6px;
  margin-bottom: 0;
}

.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 25px;
  height: 25px;
  top: -2px;
  left: 0;
  margin-top: 0;
  margin-left: -27px;
  border: 1px solid #c2c7cd;
  border-radius: 0.1875rem;
  background-color: #fff;
  box-shadow: none;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 18px;
  height: 18px;
  left: 0;
  top: 0px;
  margin-top: 4px;
  margin-left: -19px;
  padding-left: 0;
  padding-top: 0;
  font-size: 9px;
  color: #848d96;
}

.checkbox input[type="checkbox"] {
  opacity: 0;
}

.checkbox input[type="checkbox"]:checked + label::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f00c";
}

.checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.65;
}

.checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #c2c7cd;
  cursor: not-allowed;
}

.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}

.checkbox.checkbox-inline {
  margin-top: 0;
}

.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #0978f5;
  border-color: #0978f5;
}

.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-secondary input[type="checkbox"]:checked + label::before {
  background-color: #848d96;
  border-color: #848d96;
}

.checkbox-secondary input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #2cb34c;
  border-color: #2cb34c;
}

.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #4f58db;
  border-color: #4f58db;
}

.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #e5a12b;
  border-color: #e5a12b;
}

.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #fff;
}

.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #d72f40;
  border-color: #d72f40;
}

.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #fff;
}

/*--- end of checkbox ---*/
/*--- radio ---*/
.radio label {
  display: inline-block;
  position: relative;
  font-weight: 400;
  padding-left: 6px;
  margin-bottom: 0;
}

.radio label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 24px;
  height: 24px;
  top: -3px;
  left: 0;
  margin-left: -27px;
  border: 2px solid #c2c7cd;
  border-radius: 50%;
  background-color: #fff;
  margin-top: 0;
  box-shadow: none;
  -webkit-transition: border 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out;
}

.radio label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 10px;
  height: 10px;
  left: 4px;
  top: 4px;
  margin-left: -24px;
  border-radius: 50%;
  background-color: #848d96;
  margin-top: 0;
  -webkit-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.radio input[type="radio"] {
  opacity: 0;
}

.radio input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
}

.radio input[type="radio"]:disabled + label {
  opacity: 0.65;
}

.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
}

.radio.radio-inline {
  margin-top: 0;
}

.radio-primary input[type="radio"] + label::after {
  background-color: #0978f5;
}

.radio-primary input[type="radio"]:checked + label::before {
  border-color: #0978f5;
}

.radio-primary input[type="radio"]:checked + label::after {
  background-color: #0978f5;
}

.radio-success input[type="radio"] + label::after {
  background-color: #2cb34c;
}

.radio-success input[type="radio"]:checked + label::before {
  border-color: #2cb34c;
}

.radio-success input[type="radio"]:checked + label::after {
  background-color: #2cb34c;
}

.radio-info input[type="radio"] + label::after {
  background-color: #4f58db;
}

.radio-info input[type="radio"]:checked + label::before {
  border-color: #4f58db;
}

.radio-info input[type="radio"]:checked + label::after {
  background-color: #4f58db;
}

.radio-warning input[type="radio"] + label::after {
  background-color: #e5a12b;
}

.radio-warning input[type="radio"]:checked + label::before {
  border-color: #e5a12b;
}

.radio-warning input[type="radio"]:checked + label::after {
  background-color: #e5a12b;
}

.radio-danger input[type="radio"] + label::after {
  background-color: #d72f40;
}

.radio-danger input[type="radio"]:checked + label::before {
  border-color: #d72f40;
}

.radio-danger input[type="radio"]:checked + label::after {
  background-color: #d72f40;
}

/*--- end of radio ---*/
label.hidden {
  display: none !important;
}

label.btn-file {
  margin-bottom: 0;
}

.form-wrapper {
  background: #fff;
  border: 1px solid #c2c7cd;
  padding: 30px;
}

.form-title {
  font-size: 1.375rem;
  margin-bottom: 30px;
}

.custom-form .left-area {
  display: inline-block;
  width: 50%;
  text-align: left;
  vertical-align: middle;
}

.custom-form .right-area {
  display: inline-block;
  width: 50%;
  text-align: right;
  vertical-align: middle;
}

.custom-form .left-area.long {
  width: 75%;
}

.custom-form .right-area.long {
  width: 75%;
}

.custom-form .left-area.short {
  width: 25%;
}

.custom-form .right-area.short {
  width: 25%;
}

/*------- end of inputs -------*/
/*------- video embeds -------*/
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.video-player-wrapper {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.video-player-wrapper .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 2;
  -webkit-transition: .3s;
  transition: .3s;
}

.video-player-wrapper video {
  max-width: 100%;
  margin-bottom: -8px;
}

.video-wrapper #bgvideo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #f1f4f7;
}

.video-wrapper {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  background-color: #fff;
}

.video-wrapper .overlay-dark {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.video-wrapper .overlay-light {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
}

.video-wrapper video {
  width: 100vw;
  height: 56.25vw;
  /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh;
  /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-wrapper .section-content {
  position: relative;
}

/*------- end of video embeds -------*/
/*------- gallery & lightbox -------*/
.goverlay {
  background: rgba(0, 0, 0, 0.87);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.glightbox-clean .gclose, .glightbox-clean .gnext, .glightbox-clean .gprev {
  background-color: transparent;
  opacity: 0.6;
  transition: all 0.2s ease-in-out;
}

.glightbox-clean .gclose:hover, .glightbox-clean .gnext:hover, .glightbox-clean .gprev:hover {
  background: transparent !important;
  opacity: 1;
}

.glightbox-clean .gslide-title {
  font-size: 1.75rem;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #111724;
  margin-bottom: 15px;
  line-height: 1.5rem;
}

.glightbox-clean .gdesc-inner {
  padding: 30px 20px;
}

.glightbox-clean .gslide-desc {
  font-size: 1rem;
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
  line-height: 1.5rem;
}

.glightbox-clean .gslide-description {
  background: #fff;
}

.lightbox-video-wrap, .lightbox-video-wrap .img-holder {
  position: relative;
}

.lightbox-video-wrap .img-holder {
  background-color: #0978f5;
}

.lightbox-video-wrap .img-holder img {
  opacity: 0.33;
}

.lightbox-video-wrap .middle-icon {
  transition: all .2s ease-in-out;
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
}

.lightbox-video-wrap .middle-icon i, .lightbox-video-wrap .middle-icon span {
  color: #fff;
  font-size: 4rem;
  transition: all 0.2s ease-in-out;
  opacity: 1;
}

.lightbox-video-wrap .img-holder:hover i, .lightbox-video-wrap img-holder:hover span {
  color: #0978f5;
  opacity: 1;
}

.plyr__control--overlaid {
  background: #0978f5;
  color: #fff;
  padding: 25px;
}

.plyr__control svg {
  height: 20px;
  width: 20px;
}

.plyr--full-ui input[type=range] {
  color: #0978f5;
}

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true], .plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #0978f5 !important;
}

.gclose.disabled, .gnext.disabled, .gprev.disabled {
  opacity: 0 !important;
  pointer-events: none;
}

.glightbox-mobile .glightbox-container .gslide-description {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.69) 0, rgba(0, 0, 0, 0.89) 100%);
}

/*------- end of gallery & lightbox -------*/
/*------- preloader -------*/
#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
}

#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  background: #fafbfc;
  width: 100%;
  height: 100%;
}

.loaded #loader {
  opacity: 0;
}

.loaded #loader-wrapper {
  visibility: hidden;
  transition: all 0.2s ease-out;
}

/*------- end of preloader -------*/
/*---------- end of ui ----------*/
/*---------- components ----------*/
/*------- progress and sliders -------*/
.progress {
  margin-bottom: 20px;
}

.progress {
  font-size: .75rem;
  line-height: 1rem;
  background-color: #f1f4f7;
  border-radius: 0px;
  height: 6px;
}

.progress-pill {
  border-radius: 0.25rem;
}

.progress-bar {
  height: 6px;
  color: #fff;
  background-color: #0978f5;
}

.progress-bar.animated {
  margin-bottom: -0.1px;
}

.progress.text-xs {
  font-size: 0.5rem;
  line-height: 0.5rem;
}

.progress-title {
  font-size: 14px;
  margin-bottom: 8px;
}

/*------- end of progress and sliders -------*/
/*------- cards -------*/
.card {
  background: #fff;
  border: none;
  border-radius: 0.25rem;
  margin-bottom: 2rem;
  box-shadow: none;
}

.card-columns .card {
  margin-bottom: 2rem;
  box-shadow: none !important;
}

.full-height-cards .card {
  min-height: 300px;
  height: 100%;
  margin: 0;
  border-radius: 0;
  box-shadow: none !important;
}

.card.bg-img {
  border: none !important;
}

.full-height-cards.mh-400 .card {
  min-height: 400px;
}

.full-height-cards.mh-500 .card {
  min-height: 500px;
}

.full-height-cards.mh-600 .card {
  min-height: 600px;
}

.full-height-cards.mh-700 .card {
  min-height: 700px;
}

.full-height-cards .card-body.text-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.full-height-cards .card-body.text-middle {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.full-height-cards .card-body.text-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.full-height-cards .card {
  border: none !important;
}

.stacked-card {
  margin-left: -45px;
  margin-right: -45px;
  z-index: 3;
}

.card-img-top {
  width: 100%;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card-img-bottom {
  width: 100%;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.card-w-raised-img-top {
  margin-top: 70px;
}

.card-w-raised-img-bottom {
  margin-bottom: 100px;
}

.card-body {
  padding: 4rem 2rem;
}

.card-w-raised-img-top .card-body {
  padding-top: 0;
}

.card-w-raised-img-bottom .card-body {
  padding-bottom: 0;
}

.card-raised-img-top {
  margin-top: -70px;
}

.card-raised-img-bottom {
  margin-bottom: -70px;
}

.card-img-overlay {
  padding: 40px 30px;
}

.card.rectangle .card-img {
  border-radius: 0;
}

.card-body .md-icon {
  display: inline;
}

.card-icon-tag {
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
}

.card-icon-tag.icon-left {
  left: 20px;
  right: auto;
}

.card-icon-tag.icon-center {
  left: 0;
  right: 0;
}

.card-text {
  font-size: 1rem;
  margin: 1.5rem 0;
}

.card-rounded {
  border-radius: 1rem;
}

.card-header {
  padding: 2rem;
  margin-bottom: 0;
  border-bottom: 1px solid #f1f4f7;
}

.card-header:first-child {
  border-radius: 0;
}

.card-footer {
  padding: 20px 0 0 0;
  background-color: transparent;
  border-top: 1px solid #f1f4f7;
}

.card-header, .card-footer {
  background: #fff;
  border: none;
}

.card-title {
  font-size: 2rem;
  margin-bottom: 0;
}

.card-sub-title {
  color: #848d96;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  margin-top: 5px;
}

.card.w-raised-icon {
  position: relative;
  margin-top: 35px;
}

.card.w-raised-icon .raised-icon {
  position: absolute;
  top: -35px;
  font-size: 20px;
  text-align: center;
  width: 70px;
  height: 70px;
  line-height: 70px;
  border-radius: 0.125rem;
}

.card.w-raised-icon.sm-icon {
  margin-top: 25px;
}

.card.w-raised-icon .raised-icon.icon-sm {
  font-size: 14px;
  top: -25px;
  width: 50px;
  height: 50px;
  line-height: 50px;
}

.card.w-raised-icon.lg-icon {
  margin-top: 50px;
}

.card.w-raised-icon .raised-icon.icon-lg {
  font-size: 1.5rem;
  top: -50px;
  width: 100px;
  height: 100px;
  line-height: 100px;
}

.card.w-raised-icon.xl-icon {
  margin-top: 60px;
}

.card.w-raised-icon .raised-icon.icon-xl {
  font-size: 2rem;
  top: -75px;
  width: 150px;
  height: 150px;
  line-height: 150px;
}

.featured-pt.card.w-raised-icon .raised-icon.icon-xl {
  font-size: 2.25rem;
  top: -90px;
  width: 180px;
  height: 180px;
  line-height: 180px;
}

.card-inverse {
  background-color: #191b1d;
  border-color: transparent;
}

.card-secondary {
  background-color: #c2c7cd !important;
  border-color: #848d96 !important;
}

.card-primary {
  background-color: #0978f5 !important;
  border-color: #005bc1 !important;
}

.card-success {
  background-color: #2cb34c;
  border-color: #12812c;
}

.card-info {
  background-color: #4f58db;
  border-color: #2e36a5;
}

.card-warning {
  background-color: #e5a12b;
  border-color: #c27d04;
}

.card-danger {
  background-color: #d72f40;
  border-color: #9b1421;
}

.card-outline-inverse {
  background-color: #fff;
  border-color: #515254;
}

.card-outline-secondary {
  background-color: #fff;
  border-color: #848d96 !important;
}

.card-outline-primary {
  background-color: #fff;
  border-color: #005bc1;
}

.card-outline-success {
  background-color: #fff;
  border-color: #12812c;
}

.card-outline-info {
  background-color: #fff;
  border-color: #2e36a5;
}

.card-outline-warning {
  background-color: #fff;
  border-color: #c27d04;
}

.card-outline-danger {
  background-color: #fff;
  border-color: #9b1421;
}

.card-inverse .card-blockquote, .card-inverse .card-footer, .card-inverse .card-header, .card-inverse .card-title {
  color: #fff;
}

.card-inverse .card-blockquote .blockquote-footer, .card-inverse .card-link, .card-inverse .card-subtitle, .card-inverse .card-text {
  color: rgba(255, 255, 255, 0.75);
}

/*------- end of cards -------*/
/*------- sliders -------*/
.swiper-container {
  overflow: hidden;
  position: relative;
}

.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 10px;
}

.swiper-button-next, .swiper-button-prev {
  color: #fff;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.025);
}

.dark-nav .swiper-button-next, .dark-nav .swiper-button-prev {
  color: #848d96;
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: 2rem;
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-variant: initial;
  line-height: 1;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 20px 5px;
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50%;
  background: #282c31;
  opacity: .2;
}

.swiper-pagination-bullet-active {
  background: #fff;
  opacity: 0.75;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.025);
}

.swiper-horizontal.pagination-down > .swiper-pagination-bullets .swiper-pagination-bullet-active {
  background: #0978f5;
  opacity: 1;
  box-shadow: none;
}

.flow-swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.flow-swiper .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.vertical-swiper.swiper-container {
  width: 100%;
  height: 700px !important;
  margin: auto;
}

.vertical-swiper.swiper-container.header-slider {
  height: 840px !important;
}

.vertical-swiper.swiper-container.header-slider-full {
  height: 100vh !important;
}

.vertical-swiper .swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-horizontal.pagination-down > .swiper-pagination-bullets {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(0px);
}

.swiper-3d .swiper-slide-shadow-left, .swiper-3d .swiper-slide-shadow-right {
  border-radius: 0;
}

/*------- end of sliders -------*/
/*-------- call to action --------*/
.cta {
  padding: 30px 0;
}

.cta-sm {
  padding: 15px 0;
}

.cta-lg {
  padding: 45px 0;
}

.cta-xl {
  padding: 60px 0;
}

.cta .left-area.left-aligned {
  text-align: left;
}

.cta .right-area.right-aligned {
  text-align: right;
}

.cta .promo-box {
  margin-bottom: 0;
}

/*-------- end of call to action --------*/
/*------- modals -------*/
.example-modal .modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;
}

.modal-backdrop {
  background-color: rgba(255, 255, 255, 0.99);
}

.modal-backdrop.show {
  opacity: 1;
}

.modal-content {
  background-color: #fff;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0px 3px 12px rgba(0, 30, 60, 0.03);
  z-index: 999999;
}

.modal-header {
  display: block;
  padding: 2.5rem;
  padding-bottom: 0;
  border-bottom: 0;
  border-radius: 0;
}

.modal-header .close {
  display: none !important;
  color: #000;
  opacity: 1;
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-weight: 400;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.modal-header .close:hover {
  opacity: 0.5;
}

.modal-title {
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0;
}

.modal-body {
  padding: 1.5rem;
}

.modal-body p {
  font-size: 1rem;
  margin-bottom: 0;
}

.modal-body.multi-paragraph p {
  margin-bottom: 10px;
}

.small-modal p {
  font-size: 0.875rem;
}

.modal-footer {
  display: block;
  padding: 2.5rem;
  padding-top: 0;
  border-top: 0;
  border-radius: 0;
}

[role=button], a, area, button, input, label, select, summary, textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.inverse.modal-content .modal-title {
  color: #fff;
}

.inverse.modal-content .modal-body * {
  color: #f1f4f7;
}

.inverse button.close {
  color: #fff;
}

.modal-inverse.modal-content {
  background-color: #000;
  border: 2px solid #515254;
}

.modal-inverse .modal-header, .modal-inverse .modal-footer {
  border-color: #515254;
}

.modal-primary.modal-content {
  background-color: #0978f5;
  border: 2px solid #005bc1;
}

.modal-primary .modal-header, .modal-primary .modal-footer {
  border-color: #005bc1;
}

.modal-success.modal-content {
  background-color: #2cb34c;
  border: 2px solid #12812c;
}

.modal-success .modal-header, .modal-success .modal-footer {
  border-color: #12812c;
}

.modal-info.modal-content {
  background-color: #4f58db;
  border: 2px solid #2e36a5;
}

.modal-info .modal-header, .modal-info .modal-footer {
  border-color: #2e36a5;
}

.modal-warning.modal-content {
  background-color: #e5a12b;
  border: 2px solid #c27d04;
}

.modal-warning .modal-header, .modal-warning .modal-footer {
  border-color: #c27d04;
}

.modal-danger.modal-content {
  background-color: #d72f40;
  border: 2px solid #9b1421;
}

.modal-danger .modal-header, .modal-danger .modal-footer {
  border-color: #9b1421;
}

.modal.terms {
  z-index: 1060;
}

.modal .modal-dialog {
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
}

.modal:not(.modal-right) .modal-dialog, .modal:not(.modal-left) .modal-dialog, .modal:not(.modal-fs) .modal-dialog {
  transform: translateY(20px);
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  -o-transform: translateY(20px);
  -ms-transform: translateY(20px);
}

.modal:not(.modal-right).show .modal-dialog, .modal:not(.modal-left).show .modal-dialog, .modal:not(.modal-fs).show .modal-dialog {
  transform: translateY(0%);
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -o-transform: translateY(0%);
  -ms-transform: translateY(0%);
}

.modal.modal-right {
  padding-right: 0 !important;
}

.modal.modal-right .modal-dialog {
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -o-transform: translateX(100%);
  -ms-transform: translateX(100%);
}

.modal.modal-right.show .modal-dialog {
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -o-transform: translateX(0%);
  -ms-transform: translateX(0%);
}

.modal.modal-left {
  padding-right: 0 !important;
}

.modal.modal-left .modal-dialog {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
}

.modal.modal-left.show .modal-dialog {
  transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -o-transform: translateX(0%);
  -ms-transform: translateX(0%);
}

.modal.modal-fs {
  padding-right: 0 !important;
}

.modal.modal-fs .modal-dialog {
  transform: scale(0.7);
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -o-transform: scale(0.7);
  -ms-transform: scale(0.7);
}

.modal.modal-fs.show .modal-dialog {
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
}

.modal-right .modal-dialog, .modal-left .modal-dialog {
  width: 400px;
  height: 100%;
  margin: 0 auto 0 0;
}

.modal-right .modal-dialog .modal-content, .modal-left .modal-dialog .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-right .modal-dialog .modal-content .side-modal-wrapper, .modal-left .modal-dialog .modal-content .side-modal-wrapper {
  height: 100%;
  position: relative;
}

.modal-right .modal-dialog .modal-content .modal-footer, .modal-left .modal-dialog .modal-content .modal-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.modal-right .modal-dialog .modal-content, .modal-left .modal-dialog .modal-content {
  height: 100%;
  border-radius: 0px;
}

.modal-right .modal-dialog .modal-content .side-modal-wrapper, .modal-left .modal-dialog .modal-content .side-modal-wrapper {
  height: 100%;
  position: relative;
}

.modal-right .modal-dialog .modal-content .modal-footer, .modal-left .modal-dialog .modal-content .modal-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.modal-right .modal-dialog {
  margin: 0px 0px 0px auto;
}

.modal-right .modal-footer, .modal-left .modal-footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 1px solid #c2c7cd;
}

.modal-fs .modal-dialog {
  width: 100%;
  margin: 0px auto;
  height: 100%;
  max-width: none;
}

.modal-fs .modal-dialog .modal-content {
  height: 100%;
  border-radius: 0px;
}

.modal-fs .modal-body {
  padding-top: 0;
  padding-bottom: 0;
}

.modal-fs .modal-close {
  color: #282c31;
  height: 40px;
  width: 40px;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  position: absolute;
  top: 20px;
  right: 20px;
  border: 2px solid #282c31;
  border-radius: 1rem;
}

.modal-fs .modal-close:hover {
  color: #fff;
  background-color: #282c31;
}

.modal.fade .modal-dialog {
  transition: all .3s ease-in-out;
  transform: none !important;
}

/*------- end of modals -------*/
/*------- navigations -------*/
/*--- breadcrumb ---*/
.breadcrumb {
  padding: 25px 35px;
  background-color: transparent;
  border-radius: 0.125rem;
}

.breadcrumb-item {
  font-size: 0.875rem;
  font-weight: 400;
}

.breadcrumb.text-center .breadcrumb-item, .breadcrumb.text-right .breadcrumb-item {
  float: none;
}

.breadcrumb-transparent {
  background-color: transparent;
  padding: 1rem 0;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 8px;
  padding-left: 0px;
  color: #848d96;
  content: '\f054';
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 700;
  font-size: 8px;
  vertical-align: middle;
  float: none;
}

.breadcrumb-item.active {
  color: #005bc1;
}

/*--- end of breadcrumb ---*/
/*--- navbars ---*/
.navbar {
  padding: 0;
  border-radius: 0;
  z-index: 98;
}

.nav-link {
  /* font-size: 1.2rem; */
  /* font-size: 1.2rem; */
  font-weight: 500;
  letter-spacing: 0.075rem;
  transition: all 0.2s ease-in-out;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.fixed-top.nav-scroll {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.01), 0 3px 6px rgba(0, 0, 0, 0.02);
}

.navbar-light.bg-transparent.nav-fixed {
  background-color: rgba(255, 255, 255, 0.99) !important;
}

.navbar-inverse.bg-transparent.nav-fixed {
  background-color: rgba(0, 0, 0, 0.99) !important;
}

.navbar.nav-fixed {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.005), 0 3px 6px rgba(0, 0, 0, 0.01);
}

.navbar-brand {
  font-size: 2rem;
  line-height: 0;
  font-weight: 500;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: .75rem;
  margin-left: 0;
  opacity: 1 !important;
}

  /* width: auto; */
.navbar-brand img {
  /* width: auto; */
  max-width: 3.5rem;
  max-height: 3.5rem;
}

.mobile-brand {
  display: none !important;
}

.navbar-light .navbar-brand {
  color: #111724;
}

.navbar-inverse .navbar-brand {
  color: #fff;
}

.navbar-light .navbar-nav .nav-link, .navbar-light .navbar-nav .nav-link:focus {
  color: #515254;
}

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.open, .navbar-light .navbar-nav .open > .nav-link {
  color: #0978f5;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .active > .nav-link:hover, .navbar-light .navbar-nav .nav-link.active:hover, .navbar-light .navbar-nav .nav-link.open:hover, .navbar-light .navbar-nav .open > .nav-link:hover {
  color: #0978f5;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #0978f5;
}

.navbar-icons {
  position: relative;
  display: inline-block;
}

.navbar-icons .nav-icon {
  font-size: 1rem;
  vertical-align: middle;
}

.navbar-icons .middle-icon {
  margin-left: 20px;
  margin-right: 20px;
}

.navbar-light .navbar-icons .nav-icon, .navbar-light .navbar-icons .nav-icon:focus, .navbar-light .navbar-icons .nav-icon:active {
  color: #111724;
}

.navbar-light .navbar-icons .nav-icon:hover {
  color: #0978f5;
}

.navbar-inverse .navbar-icons .nav-icon, .navbar-inverse .navbar-icons .nav-icon:focus, .navbar-inverse .navbar-icons .nav-icon:active {
  color: #fff;
  opacity: 0.75;
}

.navbar-inverse .navbar-icons .nav-icon:hover {
  opacity: 1;
}

.navbar-icons .cart-count {
  width: 18px;
  height: 18px;
  position: absolute;
  top: -4px;
  right: -9px;
  color: #fff;
  font-size: 8px;
  font-weight: 700;
  line-height: 19px;
  text-align: center;
  background: #0978f5;
  border-radius: 50px;
}

.navbar-toggler {
  border: none !important;
  box-shadow: none !important;
  padding: .25rem 0;
}

.navbar-toggler-right {
  right: 15px;
}

.navbar-toggler-left {
  left: 15px;
}

/* extra menu styles */
.stacked-menu .navbar-brand {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
  text-align: center;
  margin-right: 0;
  padding-top: 1.75rem;
  padding-bottom: 0;
}

.stacked-menu .navbar-toggler-right {
  padding-right: 10px;
  margin: 0 auto;
}

.stacked-menu .navbar-toggler {
  margin: 1rem auto 0.5rem auto;
}

.split-menu .navbar-brand {
  display: inline-block;
  float: none;
  width: 100%;
  max-height: 100px;
  text-align: center;
}

.split-menu .navbar-brand img {
  max-height: 42px;
}

.custom-menu-thin {
  padding-top: 15px;
  padding-bottom: 15px;
}

.menu-pills .navbar-nav .nav-link {
  border-radius: 1rem;
  border: 2px solid transparent;
}

.menu-pills .navbar-nav .active > .nav-link, .menu-pills .navbar-nav .nav-link.active, .menu-pills .navbar-nav .nav-link.open, .menu-pills .navbar-nav .open > .nav-link {
  color: #fff;
  background: #0978f5;
  border-color: #0978f5;
  border-radius: 1rem;
}

.menu-pills .navbar-nav .nav-link:hover, .menu-pills .navbar-nav .active > .nav-link:hover, .menu-pills .navbar-nav .nav-link.active:hover, .menu-pills .navbar-nav .nav-link.open:hover, .menu-pills .navbar-nav .open > .nav-link:hover {
  color: #fff;
  background: #005bc1;
  border: 2px solid #005bc1;
}

.menu-pills .navbar-nav .active > .nav-link:hover, .menu-pills .navbar-nav .nav-link.active:hover {
  color: #fff;
  background: #0978f5;
  border-color: #0978f5;
}

.menu-line .navbar-nav .nav-link {
  padding: 0 !important;
}

.menu-line li a.nav-link span {
  position: relative;
  padding: 0px 20px 35px 20px;
  padding-bottom: 15px;
}

.menu-line.nav-fixed li a.nav-link span {
  padding-bottom: 25px;
}

.split-menu.menu-line .navbar-nav .nav-link {
  padding: 0 1.25rem !important;
}

.split-menu.menu-line li a.nav-link span {
  padding-left: 0;
  padding-right: 0;
}

.menu-line.nav-scroll li a.nav-link.active span:before {
  visibility: visible !important;
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
  transform: scaleX(1);
}

.menu-line li a.nav-link.active span:before {
  visibility: visible !important;
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
  transform: scaleX(1);
}

.menu-line li a.nav-link span:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -1px;
  left: 0;
  background-color: #0978f5;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}

.menu-line li a.nav-link:hover span:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
  transform: scaleX(1);
}

.menu-icon i {
  margin-top: -3px;
}

.transparent-menu {
  background: transparent;
}

.transparent-menu.nav-scroll {
  background: #fff;
}

.navbar-inverse.transparent-menu.nav-scroll {
  background: #232527;
}

.menu-icon.nav-scroll i {
  margin-top: -1.5px;
}

/* end of extra menu styles */
/* navbar-inverse */
.navbar-inverse .navbar-brand, .navbar-inverse .navbar-toggler {
  color: #fff;
}

.navbar-inverse .navbar-nav .nav-link, .navbar-inverse .navbar-nav .nav-link:focus {
  color: #fff;
  opacity: 0.65;
}

.navbar-inverse .navbar-nav .active > .nav-link, .navbar-inverse .navbar-nav .nav-link.active, .navbar-inverse .navbar-nav .nav-link.open, .navbar-inverse .navbar-nav .open > .nav-link {
  color: #fff;
  opacity: 1;
}

.navbar-inverse .navbar-nav .nav-link:hover, .navbar-inverse .navbar-nav .active > .nav-link:hover, .navbar-inverse .navbar-nav .nav-link.active:hover, .navbar-inverse .navbar-nav .nav-link.open:hover, .navbar-inverse .navbar-nav .open > .nav-link:hover {
  color: #fff;
  opacity: 1;
}

.navbar-inverse .navbar-nav .nav-link:hover {
  color: #fff;
  opacity: 1;
}

.navbar-inverse.bg-primary .navbar-nav .active > .nav-link, .navbar-inverse.bg-primary .navbar-nav .nav-link.active, .navbar-inverse.bg-primary .navbar-nav .nav-link.open, .navbar-inverse.bg-primary .navbar-nav .open > .nav-link, .navbar-inverse.bg-primary .navbar-nav .nav-link:focus, .navbar-inverse.bg-info .navbar-nav .active > .nav-link, .navbar-inverse.bg-info .navbar-nav .nav-link.active, .navbar-inverse.bg-info .navbar-nav .nav-link.open, .navbar-inverse.bg-info .navbar-nav .open > .nav-link, .navbar-inverse.bg-info .navbar-nav .nav-link:focus, .navbar-inverse.nav-primary .navbar-nav .active > .nav-link, .navbar-inverse.nav-primary .navbar-nav .nav-link.active, .navbar-inverse.nav-primary .navbar-nav .nav-link.open, .navbar-inverse.nav-primary .navbar-nav .open > .nav-link, .navbar-inverse.nav-primary .navbar-nav .nav-link:focus {
  color: #fff;
  opacity: 0.65;
}

.navbar-inverse.bg-primary .navbar-nav .nav-link:hover, .navbar-inverse.bg-primary .navbar-nav .active > .nav-link:hover, .navbar-inverse.bg-primary .navbar-nav .nav-link.active:hover, .navbar-inverse.bg-primary .navbar-nav .nav-link.open:hover, .navbar-inverse.bg-primary .navbar-nav .open > .nav-link:hover, .navbar-inverse.bg-info .navbar-nav .nav-link:hover, .navbar-inverse.bg-info .navbar-nav .active > .nav-link:hover, .navbar-inverse.bg-info .navbar-nav .nav-link.active:hover, .navbar-inverse.bg-info .navbar-nav .nav-link.open:hover, .navbar-inverse.bg-info .navbar-nav .open > .nav-link:hover, .navbar-inverse.nav-primary .navbar-nav .nav-link:hover, .navbar-inverse.nav-primary .navbar-nav .active > .nav-link:hover, .navbar-inverse.nav-primary .navbar-nav .nav-link.active:hover, .navbar-inverse.nav-primary .navbar-nav .nav-link.open:hover, .navbar-inverse.nav-primary .navbar-nav .open > .nav-link:hover {
  color: #fff;
  opacity: 1;
}

.navbar-inverse.nav-primary .navbar-nav .nav-link {
  color: #fff;
  opacity: 0.65;
}

.navbar-inverse.nav-primary .navbar-nav .nav-link:hover {
  color: #fff;
  opacity: 1 !important;
}

.navbar-inverse.nav-primary .navbar-nav .nav-link.active:hover {
  color: #fff;
  opacity: 1 !important;
}

.navbar-inverse.bg-primary .navbar-nav .nav-link:hover {
  color: #fff;
  opacity: 1;
}

/* end of navbar-inverse */
/* animated hamburger icon */
.navbar-toggler.menu-text:before {
  color: #111724;
  float: left;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-left: -62px;
  margin-top: 1px;
}

.navbar-toggler.collapsed.menu-text:before {
  content: 'MENU';
}

.navbar-inverse .navbar-toggler.menu-text:before {
  color: #fff;
}

/* overlay-nav */
.overlay-nav {
  height: 90px;
  line-height: 90px;
}

.overlay-nav .navbar-collapse {
  height: 100% !important;
  width: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 99999;
  transition: ease-in 0.3s;
  background: rgba(255, 255, 255, 0.87);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.overlay-nav .navbar-collapse .nav-link {
  font-size: 1.25rem;
  font-weight: 700;
  height: auto;
  line-height: 3rem;
  text-transform: uppercase;
  max-width: 160px;
  margin: 3px auto;
  border-bottom: 3px solid transparent;
  opacity: 1 !important;
}

.overlay-nav .navbar-collapse .nav-link.active {
  color: #0978f5;
  border-color: #0978f5;
}

.overlay-nav .navbar-collapse .navbar-nav {
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  overflow: scroll;
}

.overlay-nav.navbar-inverse .navbar-collapse {
  background: rgba(0, 0, 0, 0.87);
}

.overlay-nav.navbar-inverse.nav-primary .navbar-collapse {
  background: rgba(214, 29, 9, 0.87);
}

.overlay-nav.navbar-inverse .navbar-collapse .nav-link {
  color: #fff;
  border-bottom: 3px solid transparent;
}

.overlay-nav.navbar-inverse .navbar-collapse .nav-link:hover {
  color: #0978f5;
}

.overlay-nav.navbar-inverse .navbar-collapse .nav-link.active, .overlay-nav.navbar-inverse .navbar-collapse .nav-link.active:hover {
  color: #0978f5;
  border-color: #0978f5;
}

.overlay-nav.navbar-inverse.nav-primary .navbar-collapse .nav-link {
  color: #fff;
  border-bottom: 3px solid transparent;
  opacity: 0.7 !important;
}

.overlay-nav.navbar-inverse.nav-primary .navbar-collapse .nav-link:hover {
  color: #fff;
  opacity: 1 !important;
}

.overlay-nav.navbar-inverse.nav-primary .navbar-collapse .nav-link.active, .overlay-nav.navbar-inverse.nav-primary .navbar-collapse .nav-link.active:hover {
  color: #fff;
  border-color: #fff;
  opacity: 1 !important;
}

/* end of overlay-nav */
/* slide-nav */
.slide-nav {
  height: 90px;
  line-height: 90px;
}

.slide-nav .navbar-collapse {
  height: 100% !important;
  width: 360px;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  right: 0;
  overflow: hidden;
  z-index: 99999;
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  background: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0px 5px 18px rgba(0, 30, 60, 0.06);
}

.slide-nav .navbar-collapse .nav-link {
  font-size: 1.25rem;
  font-weight: 700;
  height: auto;
  line-height: 3rem;
  text-transform: uppercase;
  margin: 3px auto;
  border: none;
  opacity: 1;
}

.slide-nav .navbar-collapse .nav-link.active, .slide-nav .navbar-collapse .nav-link.active:hover {
  color: #fff;
  background-color: #0978f5;
}

.slide-nav .navbar-collapse .nav-link:hover {
  color: #0978f5;
}

.slide-nav .navbar-collapse .navbar-nav {
  position: relative;
  top: 90px;
  overflow: scroll;
}

.slide-nav.navbar-inverse .navbar-collapse {
  background: rgba(0, 0, 0, 0.98);
}

.slide-nav.navbar-inverse.nav-primary .navbar-collapse {
  background: rgba(214, 29, 9, 0.98);
}

.slide-nav.navbar-inverse .navbar-collapse .nav-link {
  color: #fff;
  border: none;
  opacity: 0.7;
}

.slide-nav.navbar-inverse .navbar-collapse .nav-link:hover {
  color: #fff;
  opacity: 1;
}

.slide-nav.navbar-inverse .navbar-collapse .nav-link.active, .slide-nav.navbar-inverse .navbar-collapse .nav-link.active:hover {
  color: #fff;
  background-color: #0978f5;
  opacity: 1;
}

.slide-nav.navbar-inverse.nav-primary .navbar-collapse .nav-link {
  color: #fff;
  border: none;
  opacity: 0.7 !important;
}

.slide-nav.navbar-inverse.nav-primary .navbar-collapse .nav-link:hover {
  color: #fff;
  opacity: 1 !important;
}

.slide-nav.navbar-inverse.nav-primary .navbar-collapse .nav-link.active, .slide-nav.navbar-inverse.nav-primary .navbar-collapse .nav-link.active:hover {
  color: #fff;
  background-color: #005bc1;
  opacity: 1 !important;
}

.slide-nav button:not(.collapsed).navbar-toggler {
  position: fixed;
  right: 30px;
}

/* end of slide-nav */
/* side-navbar */
.side-navbar {
  width: 340px;
  z-index: 999;
  top: 0;
  bottom: 0;
  position: fixed;
  overflow: hidden;
  padding: 0;
  box-shadow: 0px 5px 18px rgba(0, 30, 60, 0.06);
}

.side-navbar.right-nav {
  right: 0;
  border-right: none;
  border-left: 1px solid #f1f4f7;
}

.sidenavbar-logo {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 120px;
  line-height: 120px;
  padding: 0 30px;
  border-bottom: 1px solid #f1f4f7;
}

.nav-inverse .sidenavbar-logo {
  border-color: #282c31;
}

.sidenavbar-logo img {
  width: auto;
  height: 52px;
}

.side-navbar .nav-link {
  color: #797d85;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  padding: 25px 30px;
}

.side-navbar .nav-link:hover {
  color: #0978f5;
  background-color: #dfeeff;
}

.side-navbar .nav-link.active, .side-navbar .nav-link.active:hover {
  color: #fff;
  background-color: #0978f5;
}

.side-navbar.nav-inverse .nav-link {
  color: #fff;
}

.side-navbar.nav-inverse .nav-link:hover {
  color: #0978f5;
  background-color: #dfeeff;
}

.side-navbar.nav-inverse .nav-link.active, .side-navbar.nav-inverse .nav-link.active:hover {
  color: #fff;
  background-color: #0978f5;
}

.side-navbar .nav-link i {
  font-size: 16px;
  vertical-align: middle;
  margin-right: 6px;
}

.side-navbar .nav-link span {
  vertical-align: middle;
}

.side-navbar ul, .side-navbar ul li, .side-navbar ul .dropdown-menu {
  width: 100% !important;
}

.side-navbar .dropdown-menu {
  top: 0 !important;
  padding: 5px 0 !important;
  margin: 0 !important;
  box-shadow: none !important;
  border: none !important;
  position: relative !important;
}

.side-navbar .dropdown-menu.show {
  height: auto;
}

.side-navbar .dropdown-menu:before, .side-navbar .dropdown-menu:after {
  display: none !important;
}

.side-navbar .dropdown-item {
  padding: 10px 28px;
}

.side-navbar .dropdown-menu .dropdown-item {
  font-size: 13px;
}

.side-navbar .dropdown-toggle {
  white-space: normal;
}

.side-navbar .dropdown-toggle::after {
  display: inline-block !important;
  vertical-align: middle;
  color: #797d85;
  font-size: 13px;
  line-height: 75%;
  float: right;
  position: relative;
  top: 5px;
  opacity: 0.75 !important;
  transition: all 0.2s ease-in-out;
  transform: rotate(0deg);
}

.side-navbar .dropdown-toggle:hover::after {
  color: #111724;
}

.side-navbar .dropdown .dropdown-toggle.show::after {
  transform: rotate(180deg);
}

.sidenavbar-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  height: 90px;
  line-height: 90px;
  padding: 0px 30px 0px 30px;
  border-top: 1px solid #f1f4f7;
}

.nav-inverse .sidenavbar-footer {
  border-color: #282c31;
}

.page-container {
  padding: 0 0 0 340px;
}

.page-container.with-right-nav {
  padding: 0 340px 0 0;
}

.side-navbar .nav-link.has-collapse:after {
  content: "\f078";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  display: inline-block !important;
  vertical-align: middle;
  color: #797d85;
  font-size: 13px;
  line-height: 125%;
  float: right;
  position: relative;
  top: 0;
  opacity: 0.75 !important;
  transition: all 0.2s ease-in-out;
  transform: rotate(0deg);
}

.side-navbar .nav-link.has-collapse:hover::after {
  color: #111724;
}

.side-navbar .nav-link.has-collapse:not(.collapsed)::after {
  transform: rotate(180deg);
}

.side-navbar .nav-link:hover::after {
  color: #fff !important;
}

.side-navbar .sidenavbar-sub-item {
  color: #797d85;
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  padding: 12px 30px;
  transition: height 0.35s ease;
}

.side-navbar .sidenavbar-sub-item:hover {
  color: #fff;
  background-color: #0978f5;
}

/* end of side-navbar */
.navbar-toggler {
  border: none;
  background: transparent !important;
  z-index: 999999;
}

.navbar-toggler.collapsed {
  z-index: 999999;
}

.navbar-toggler:focus {
  outline: none;
  background: transparent !important;
}

.navbar-toggler .icon-bar {
  background-color: #111724;
  transform: rotate(0deg) translate(0px, 0px);
  transition: ease all .3s;
}

.navbar-inverse .navbar-toggler .icon-bar {
  background-color: #fff;
  transform: rotate(0deg) translate(0px, 0px);
  transition: ease all .3s;
}

.navbar-toggler .icon-bar {
  display: block;
  width: 34px;
  height: 3px;
  border-radius: 0;
}

.navbar-toggler .icon-bar + .icon-bar {
  margin-top: 6px;
}

.navbar-toggler:not(.collapsed) .icon-bar:nth-child(1) {
  transform: rotate(45deg) translate(7px, 7px);
  transition: ease all .3s;
}

.navbar-toggler:not(.collapsed) .icon-bar:nth-child(2) {
  opacity: 0;
  transition: ease all .3s;
}

.navbar-toggler:not(.collapsed) .icon-bar:nth-child(3) {
  transform: rotate(-45deg) translate(6px, -6px);
  transition: ease all .3s;
}

/* end of animated hamburger icon */
.navbar-button {
  padding: 0;
  margin: 0;
}

.nav-item .form-control {
  height: 40px;
  max-height: 40px;
}

/* main-nav */
.main-nav {
  background: #fff;
  position: absolute;
  top: 1.5rem;
  left: 10px;
  right: 10px;
  padding: 0 0.5rem;
  z-index: 9999;
  border-radius: 0.25rem;
}

.navbar-inverse.main-nav {
  background: rgba(27, 26, 26, 0.15);
}

.navbar-inverse.nav-primary.main-nav {
  background: rgba(214, 29, 9, 0.15);
}

.main-nav.fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.main-nav.fixed-bottom {
  position: fixed;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
}

.main-nav.sticky-top {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
}

.main-nav.sticky-bottom {
  position: sticky;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
}

.main-nav .sticky-logo {
  display: none;
}

.main-nav.nav-scroll .navbar-brand {
  display: none;
}

.main-nav.nav-scroll .sticky-logo {
  display: inline-block;
}

.navbar-inverse.main-nav.nav-scroll .navbar-toggler .icon-bar {
  background-color: #111724;
}

.main-nav .dropdown-toggle::after {
  transform: translate(0, -47%);
}

/* end of main-nav */
/*--- end of navbars ---*/
/*--- top-nav ---*/
.top-nav {
  padding: 15px 0;
}

.top-nav .left {
  vertical-align: middle;
}

.top-nav .right {
  text-align: right;
  vertical-align: middle;
}

.top-nav-light a {
  color: #797d85;
}

.top-nav-light a:hover {
  color: #0978f5;
}

.top-nav-inverse a {
  color: #f1f4f7;
}

.top-nav-inverse a:hover {
  color: #f1f4f7;
  opacity: 0.75;
}

.top-nav-inverse a.btn:hover {
  opacity: 1;
}

.top-nav-btn {
  display: inline-block;
  font-size: 10px;
  padding: 8px 25px;
}

.btn-sm.top-nav-btn {
  font-size: 12px;
}

/*--- end of top-navs ---*/
/*--- paginations ---*/
.pager {
  padding-left: 0;
  margin-bottom: 0;
}

.pager a {
  font-size: 0.875rem;
  font-weight: 500;
}

.page-link {
  color: #111724;
  font-size: 0.875rem;
  font-weight: 700;
  background-color: transparent !important;
  border: none;
  border-radius: 0.1875rem;
  padding: 7px 15px;
}

.page-link:hover {
  color: #0978f5 !important;
  background-color: transparent;
  border: none;
}

.page-link:active, .page-link:focus, .page-link:visited {
  color: #111724;
}

.page-link:active, .page-link:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #0978f5 !important;
  border-color: #0978f5 !important;
}

.page-item.active .page-link:hover {
  color: #fff !important;
  background-color: #005bc1 !important;
  border-color: #005bc1 !important;
}

.page-nav-left a {
  padding-left: 0 !important;
  margin-left: 0 !important;
}

.page-nav-right a {
  padding-right: 0 !important;
  margin-right: 0 !important;
}

.pill-pagination .page-item.active .page-link {
  color: #fff;
  background-color: #0978f5 !important;
  text-align: center;
  line-height: 45px;
  height: 45px;
  width: 45px;
  margin: 0 6px;
  padding: 0;
  border: none;
  border-radius: 50%;
}

.pill-pagination .page-item.active .page-link:hover {
  color: #fff !important;
  background-color: #005bc1 !important;
  border-color: #005bc1;
}

.pagination .page-link {
  margin: 2px;
}

.page-item.disabled {
  cursor: not-allowed;
}

.page-item.disabled .page-link {
  color: #797d85;
  pointer-events: none;
  cursor: not-allowed;
  background-color: transparent;
  border-color: transparent;
  opacity: 0.5;
}

.pagination-white .page-link {
  color: #111724;
  background-color: #fff;
  border: 2px solid #848d96;
}

.pagination-white .page-link:hover {
  color: #000;
  background-color: #c2c7cd;
  border: 2px solid #848d96;
}

.pagination-primary .page-link {
  color: #fff;
  background-color: #0978f5;
  border: 2px solid #005bc1;
}

.pagination-primary .page-link:hover {
  color: #fff;
  background-color: #005bc1;
  border: 2px solid #005bc1;
}

.pagination-primary .page-link:active, .pagination-primary .page-link:focus, .pagination-primary .page-link:visited {
  color: #fff;
}

.pagination-primary .page-item.disabled .page-link {
  color: #f1f4f7;
  pointer-events: none;
  cursor: not-allowed;
  background-color: #0978f5;
  border-color: #005bc1;
}

.pagination-primary .page-item.active .page-link {
  color: #fff;
  background-color: #005bc1;
  border-color: #005bc1;
}

.pager .btn:hover, .pager .btn:focus {
  color: #fff;
}

.pager-left, .pager-right {
  display: inline-block;
}

.pager-left i {
  margin-right: 1px;
}

.pager-right i {
  margin-left: 1px;
}

.pager-right {
  float: right;
}

/*--- end of paginations ---*/
/*------- end of navigations -------*/
/*------ tabs-collapse -------*/
/*--- tabs ---*/
.nav-tabs {
  border-bottom: 0;
}

.nav-tabs .nav-item {
  margin: 5px;
}

.nav-tabs .nav-item:first-child {
  margin-left: 0;
}

.nav-tabs .nav-item:last-child {
  margin-right: 0;
}

.nav-tabs .nav-link {
  color: #797d85;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.075rem;
  padding: 0.875rem 2rem;
  border-radius: 0.25rem !important;
  border: none !important;
  border-bottom: none !important;
}

.nav-tabs .nav-link:first-child {
  margin-left: 0;
}

.nav-tabs .nav-link:last-child {
  margin-right: 0;
}

.nav-tabs .nav-link:hover {
  color: #111724;
  background-color: transparent;
  border-color: #111724;
}

.nav-tabs .nav-link.active {
  color: #0978f5;
  background-color: transparent;
  border-bottom: 1px solid #0978f5;
  box-shadow: none;
}

.nav-tabs .nav-link.disabled {
  color: #848d96;
  cursor: not-allowed;
}

.nav-tabs.product-tabs .nav-link {
  border-bottom: 1px solid transparent !important;
}

.nav-tabs.product-tabs .nav-link:hover {
  border-color: #111724 !important;
}

.nav-tabs.product-tabs .nav-link.active, .nav-tabs.product-tabs .nav-link.active:hover {
  border-color: #0978f5 !important;
}

.nav-pills .nav-link:hover {
  color: #0978f5;
  background-color: #dfeeff;
  border: none;
  box-shadow: 0px 3px 10px rgba(0, 30, 60, 0.03);
}

.nav-pills .nav-link.active {
  color: #fff;
  background: #0978f5;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0px 6px 25px rgba(0, 30, 60, 0.14);
  transition: 0.2s ease-in-out;
}

.nav-pills .nav-link.active:hover {
  color: #fff;
  background-color: #0978f5;
  border-color: #005bc1;
  box-shadow: 0px 6px 25px rgba(0, 30, 60, 0.14);
}

.tab-w-bar {
  background: #0978f5;
  border-radius: 0.25rem;
  padding: 1rem;
}

.tab-w-bar .nav-link {
  color: #fff;
  border-radius: 0;
  border: none;
}

.tab-w-bar .nav-link.active {
  color: #fff;
  background: rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 0;
  box-shadow: 0px 6px 25px rgba(0, 30, 60, 0.14);
}

.tab-w-bar .nav-link:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  box-shadow: 0px 3px 10px rgba(0, 30, 60, 0.03);
}

.tab-w-bar .nav-link.active:hover {
  color: #fff;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0px 6px 25px rgba(0, 30, 60, 0.14);
}

.card .tab-w-bar {
  margin-top: -40px;
}

.card-w-bar {
  margin-top: 55px;
}

.card-w-bar .card-body {
  padding-top: 0;
}

.nav-tabs.light-bar {
  background: #0978f5;
  border-radius: 0;
  padding: 1rem;
}

.nav-tabs.light-bar .nav-link {
  color: #797d85;
  border-radius: 0;
  border: none !important;
}

.nav-tabs.light-bar .nav-link.active {
  color: #111724;
  background: rgba(0, 0, 0, 0.15);
  border: none !important;
  border-radius: 0;
  box-shadow: 0px 6px 25px rgba(0, 30, 60, 0.14);
}

.nav-tabs.light-bar .nav-link:hover {
  color: #111724;
  background-color: rgba(0, 0, 0, 0.1);
  border: none;
  box-shadow: 0px 3px 10px rgba(0, 30, 60, 0.03);
}

.nav-tabs.light-bar .nav-link.active:hover {
  color: #111724;
  background: rgba(0, 0, 0, 0.15);
  box-shadow: 0px 6px 25px rgba(0, 30, 60, 0.14);
}

.nav-tabs.form-tabs .nav-link {
  height: 52px;
}

.account-bar.horizontal-bar {
  width: 100%;
  padding: 30px;
}

.account-bar.horizontal-bar .nav-link {
  text-align: left;
  padding: 30px;
  box-shadow: none !important;
  margin-top: 1px;
}

/*--- end of tabs ---*/
/*--- accordions ---*/
.accordion {
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: none;
}

.accordion.w-cards {
  background: transparent;
  box-shadow: none;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.75rem 2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  color: #111724;
  text-align: left;
  background-color: transparent;
  border: 1px solid #f1f4f7;
  opacity: 1 !important;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  color: #0978f5;
  background-color: transparent;
}

.accordion-button:not(.collapsed)::after, .accordion-button::after {
  background-image: none;
  transform: none;
}

.accordion-item {
  background-color: transparent;
  border: none;
}

.accordion-item .accordion-button {
  border-color: #f1f4f7;
}

.accordion-item .accordion-button:focus {
  border-color: #f1f4f7;
}

.accordion-button.collapsed i {
  color: #111724;
  transform: rotate(-360deg) !important;
  transition: ease-in-out 0.3s;
}

.accordion-button i {
  color: #0978f5;
  position: absolute;
  right: 1.75rem;
  transform: rotate(-180deg) !important;
  transition: ease-in-out 0.3s;
}

.accordion-collapse {
  border: solid #f1f4f7;
  border-width: 0 1px;
}

.accordion-body {
  padding: 2rem 1.75rem;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 1px;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-width: 1px;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

/* minimal accordion */
.minimal-accordion {
  padding: 1.75rem 2.25rem 2rem 5.5rem;
  box-shadow: 0px 3px 12px rgba(0, 30, 60, 0.03);
}

.minimal-accordion .accordion-collapse {
  border: none;
}

.minimal-accordion .accordion-button {
  background-color: transparent;
  border: none;
  padding: 2rem 1.75rem;
}

.minimal-accordion .accordion-button i {
  position: static;
  line-height: 56px;
}

.minimal-accordion .accordion-button span {
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  left: -3.5rem;
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.5rem;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0px 3px 12px rgba(0, 30, 60, 0.03);
}

.minimal-accordion .accordion-body {
  padding-top: 0px;
}

/* end of minimal accordion */
/* accordions on cards */
.accordion .card {
  background: #fff;
  border: none;
  border-radius: 0.125rem !important;
  margin-bottom: 0;
}

.accordion .card .accordion-title {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1rem;
}

.accordion .card-header {
  padding: 1.5rem 1.75rem;
  background: transparent;
}

.accordion .card-body {
  color: #797d85;
  background-color: transparent;
  padding: 1.75rem;
  border: none;
  border-top: 1px solid #f1f4f7;
  border-bottom-left-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}

.accordion .card-header {
  border-radius: 0;
  border: none;
}

.accordion.w-icon .card .collapsed i {
  float: right;
  -webkit-transform: rotate(-360deg) !important;
  -moz-transform: rotate(-360deg) !important;
  transform: rotate(-360deg) !important;
  -webkit-transition: ease-in-out 0.3s;
  -moz-transition: ease-in-out 0.3s;
  -o-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s;
}

.accordion.w-icon .card i {
  color: #848d96;
  float: right;
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  transform: rotate(-180deg);
  -webkit-transition: ease-in-out 0.3s;
  -moz-transition: ease-in-out 0.3s;
  -o-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s;
}

.accordion.w-icon .card a:hover i {
  color: #515254;
}

.accordion.dark.w-icon .card i {
  color: #fff;
  opacity: 0.5;
}

.accordion.dark.w-icon .card a:hover i {
  color: #fff;
  opacity: 1;
}

.primary-accordion.w-icon .card-header {
  border-radius: 0;
  border: none;
}

.primary-accordion .card {
  border-radius: 0.125rem;
  background-color: rgba(214, 29, 9, 0.15);
  border-color: #0978f5;
}

.accordion.primary-accordion .card-body {
  color: #f1f4f7;
  background: transparent;
  border-top: 1px solid #0978f5;
}

.dark-accordion.w-icon .card-header {
  border-radius: 0;
  border: none;
}

.dark-accordion .card {
  border-color: #191b1d;
}

.dark-accordion .card-header {
  background: #282c31;
}

.accordion.dark-accordion .card-body {
  color: #f1f4f7;
  background: #191b1d;
  border-top: 1px solid #191b1d;
}

.nested-accordion .card .card-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0;
}

.nested-accordion .card:last-child .card-header {
  border-bottom: none !important;
}

.nested-accordion.primary-accordion .card .card-header {
  border-bottom: 1px solid rgba(214, 29, 9, 0.15);
}

.nested-accordion.dark-accordion .card .card-header {
  border-bottom: 1px solid #191b1d;
}

.nested-accordion .card {
  border-radius: 0 !important;
  transform: none;
}

.nested-accordion .card-body {
  border-top: 0;
  border-radius: 0;
}

.nested-accordion.dark .card-body {
  border-top: 0;
}

.nested-accordion .card:first-child {
  border-top-left-radius: 0.125rem !important;
  border-top-right-radius: 0.125rem !important;
}

.nested-accordion .card:last-child, .nested-accordion .card:last-child .card-body {
  border-bottom-left-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important;
}

/* end of accordions on cards */
/*--- end of accordions ---*/
/*------ end of tabs-collapse -------*/
/*------- dropdowns -------*/
.dropdown-toggle::after {
  content: "\f282" !important;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  font-size: 12px !important;
  border: none;
  position: absolute;
  top: 50%;
  margin-left: 5px !important;
  opacity: 1 !important;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.dropup .dropdown-toggle::after {
  content: "\f077";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 0.5rem;
  border: none;
  position: absolute;
}

.dropdown-menu {
  min-width: 180px;
  width: max-content !important;
  color: #111724;
  background-color: #fff;
  border: none;
  z-index: 99;
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
  margin: 0;
  padding: 10px;
  border-radius: 0.25rem;
  box-shadow: 0px 3px 12px rgba(0, 30, 60, 0.03);
}

.dropdown-menu[data-bs-popper] {
  left: 50% !important;
  margin-top: 0 !important;
}

.dropdown-menu:before {
  display: none;
}

.main-nav .dropdown-menu {
  background-color: #fff;
}

.main-nav .dropdown-menu:before {
  color: #fff;
}

.dropdown-menu.dropdown-inverse {
  background-color: #191b1d;
}

.main-nav .dropdown-menu.dropdown-inverse {
  background-color: #191b1d;
}

.dropdown-menu.dropdown-inverse:before {
  color: #191b1d;
}

.main-nav .dropdown-menu.dropdown-inverse:before {
  color: #191b1d;
}

.dropdown-menu.dropdown-inverse.bg-primary {
  background-color: #0978f5 !important;
}

.main-nav .dropdown-menu.dropdown-inverse.bg-primary {
  background-color: #0978f5 !important;
}

.dropdown-menu.dropdown-inverse.bg-primary:before {
  color: #0978f5 !important;
}

.main-nav .dropdown-menu.dropdown-inverse.bg-primary:before {
  color: #0978f5 !important;
}

.dropdown-menu.dropdown-inverse.bg-info:before {
  color: #4f58db;
}

.dropdown-menu.dropdown-inverse.bg-inverse:before {
  color: #191b1d;
}

.dropdown-menu.dropdown-inverse.bg-inverse {
  background-color: #191b1d;
}

.dropdown-menu[data-bs-popper] {
  left: initial;
}

.dropdown-cart {
  padding: 1.25rem;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #797d85;
  cursor: not-allowed;
  background-color: transparent;
}

.dropdown-item {
  color: #797d85;
  background-color: transparent;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.75rem 1.25rem;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #0978f5;
  text-decoration: none;
  background-color: transparent;
}

.dropdown-item.active, .dropdown-item:active {
  color: #0978f5;
  background-color: transparent;
}

.dropdown-item i {
  width: 20px;
  text-align: center;
  color: #111724;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.dropdown-item:hover i, .dropdown-item.active i, .dropdown-item:active i {
  color: #0978f5;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: .75rem;
  color: #111724;
  white-space: nowrap;
}

.dropdown-divider {
  height: 1px;
  margin: .5rem 0;
  overflow: hidden;
  border-top: 1px solid #f1f4f7;
}

/*--- mega menu ---*/
.dropdown-menu.mega-menu {
  padding: 1.25rem 1rem;
}

.mega-menu .sub-menu {
  border-left: 1px solid #f1f4f7;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.dropdown-inverse.mega-menu .sub-menu {
  border-left: 1px solid rgba(27, 26, 26, 0.5);
}

.dropdown-inverse.bg-primary.mega-menu .sub-menu {
  border-left: 1px solid #005bc1;
}

.mega-menu .sub-menu:first-child {
  border-left: none;
}

/*--- end of mega menu ---*/
/*--- inverse dropdown ---*/
.dropdown-inverse {
  background: #191b1d;
  border: none;
}

.dropdown-inverse .dropdown-item, .dropdown-inverse .dropdown-item:focus {
  color: #f1f4f7;
}

.dropdown-inverse .dropdown-item:hover {
  color: #0978f5;
  background-color: transparent;
}

.dropdown-inverse.bg-primary .dropdown-item:hover {
  color: #f1f4f7;
  opacity: 0.75;
}

.dropdown-inverse .dropdown-item.active, .dropdown-inverse .dropdown-item.active:focus {
  color: #0978f5;
}

.dropdown-inverse.bg-primary .dropdown-item.active, .dropdown-inverse.bg-primary .dropdown-item.active:focus {
  color: #d6d7d8;
  opacity: 0.75;
}

.dropdown-inverse .dropdown-header {
  color: #fff;
}

.dropdown-inverse .dropdown-divider {
  border-top: 1px solid #282c31;
}

.dropdown-inverse.bg-primary .dropdown-divider {
  border-top: 1px solid #005bc1;
}

/*--- end of inverse dropdown ---*/
/*------- end of collapse-dropdowns -------*/
/*------- border box -------*/
.border-box {
  margin-bottom: 2rem;
  padding: 2.5rem 2rem;
  border: 2px solid #c2c7cd;
  border-radius: 0.125rem;
}

/*------- end of border box -------*/
/*------- promo box -------*/
.promo-box {
  background-color: #fff;
  margin-bottom: 2rem;
  padding: 2.5rem 2rem;
  border-radius: 0.25rem;
  box-shadow: 0px 2px 8px rgba(0, 30, 60, 0.03);
}

.main-container .promo-box:last-child {
  margin-bottom: 0;
}

.promo-box.dark {
  background: #303132;
}

.promo-container {
  position: relative;
}

.promo-icon {
  font-size: 50px;
  border-radius: 50px;
}

.promo-icon-bg {
  text-align: center;
  width: 100px;
  height: 100px;
  border: 1px solid transparent;
  border-radius: 0;
  position: relative;
}

.promo-icon-bg i, .promo-icon-bg .text-icon {
  font-size: 1.75rem;
  line-height: 100px;
  left: 0;
  right: 0;
}

.promo-icon-bg.icon-md {
  width: 65px;
  height: 65px;
  border: 1px solid transparent;
}

.promo-icon-bg.icon-md i, .promo-icon-bg.icon-md .text-icon {
  font-size: 1.25rem;
  line-height: 65px;
  left: 0;
  right: 0;
}

.promo-icon-bg.icon-sm {
  width: 50px;
  height: 50px;
  border: 1px solid transparent;
}

.promo-icon-bg.icon-sm i, .promo-icon-bg.icon-sm .text-icon {
  font-size: 1.1rem;
  line-height: 50px;
  left: 0;
  right: 0;
}

.promo-block {
  margin: 30px 0;
}

.promo-box-image {
  max-width: 90px;
  border-radius: 0.125rem;
}

.promo-box-image.img-md {
  max-width: 65px;
}

.promo-box-image.img-sm {
  max-width: 50px;
}

.promo-left .promo-container {
  padding-left: 60px;
}

.promo-left .promo-icon {
  font-size: 36px;
  position: absolute;
  float: left;
  left: 0;
}

.promo-center {
  text-align: center;
}

.promo-center .promo-icon {
  margin-left: auto;
  margin-right: auto;
}

.promo-right .promo-icon {
  font-size: 36px;
  position: absolute;
  float: right;
  right: 0;
}

.promo-right .promo-container {
  padding-right: 60px;
}

.promo-box.has-process-icon:after {
  content: "\f054";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  color: rgba(225, 225, 225, 0.75);
  font-size: 24px;
  margin-top: -36px;
  top: 50%;
  right: -3.5%;
  z-index: 1;
  -webkit-transition: ease-in-out 0.2s;
  -moz-transition: ease-in-out 0.2s;
  -o-transition: ease-in-out 0.2s;
  transition: ease-in-out 0.2s;
}

.promo-box.has-process-icon:hover:after {
  right: -5%;
}

.promo-img {
  border-radius: 10px;
}

.promo-box-bg-img {
  border-radius: 0.125rem;
}

.promo-box-img-left img, .promo-box-img-left .box-bg-image {
  border-radius: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.promo-box-img-left .promo-box {
  border-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.promo-box-img-right img, .promo-box-img-right .box-bg-image {
  border-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.promo-box-img-right .promo-box {
  border-radius: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.cta-box {
  max-width: 380px;
}

/*--- promo big ---*/
.promo-container-big {
  position: relative;
}

.promo-big .promo-icon {
  left: 0;
  right: 0;
}

.promo-big.b-1 .promo-icon {
  margin-right: -1px;
  margin-top: -1px;
}

.promo-big.b-2 .promo-icon {
  margin-right: -2px;
  margin-top: -2px;
}

.promo-big.b-3 .promo-icon {
  margin-right: -3px;
  margin-top: -3px;
}

.promo-big.b-4 .promo-icon {
  margin-right: -4px;
  margin-top: -4px;
}

.promo-left .promo-big {
  position: absolute;
  float: left;
  left: 0;
}

.promo-left .promo-container-big {
  padding-left: 130px;
}

.promo-left .promo-container-big-md {
  padding-left: 90px;
}

.promo-left .promo-container-big-sm {
  padding-left: 75px;
}

.promo-center .promo-big {
  margin-left: auto;
  margin-right: auto;
}

.promo-right .promo-big {
  position: absolute;
  float: right;
  right: 0;
}

.promo-right .promo-container-big {
  padding-right: 130px;
}

.promo-right .promo-container-big-md {
  padding-right: 90px;
}

.promo-right .promo-container-big-sm {
  padding-right: 75px;
}

/*--- end of promo big ---*/
.box-w-image {
  position: relative;
}

.box-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.box-title a {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

.box-title a:hover {
  color: #0978f5;
}

.box-description {
  margin-bottom: 0;
}

.promo-box .col-line {
  border-right: 1px solid rgba(225, 225, 225, 0.5);
}

.promo-box .col-line:last-child {
  border-right: none;
}

.promo-box .col-line-bottom {
  border-bottom: 1px solid rgba(225, 225, 225, 0.5);
}

/*-------- end of promo box --------*/
/*-------- blog components --------*/
.post-meta-small {
  border-top: 1px solid #e6e9ec;
  border-bottom: 1px solid #e6e9ec;
}

/*-------- end of blog components --------*/
/*-------- comments --------*/
.comments-boxed {
  padding: 30px;
  margin-bottom: 30px;
}

#comment-form textarea {
  max-width: 100%;
  height: 150px;
  min-height: 106px !important;
  max-height: 211px;
}

.comments img {
  max-width: 60px;
  border-radius: 50%;
}

.comments .media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-top: 30px;
}

.comments .media-body {
  -ms-flex: 1;
  flex: 1;
}

.comments .media-left, .comments .media > .pull-left {
  padding-right: 15px;
}

.comment-author {
  color: #111724;
  font-weight: 400;
}

.comment-date {
  font-size: 0.75rem;
}

.comment-reply {
  font-size: 0.9rem;
  margin: 0 5px;
}

.comment-reply, .comment-reply:focus {
  color: #282c31;
  opacity: 0.75;
}

.comment-reply:hover {
  opacity: 1;
}

/*-------- end of comments --------*/
/*--- timeline ---*/
.timeline {
  list-style: none;
  padding: 20px 0;
  position: relative;
}

.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: rgba(255, 255, 255, 0.5);
  left: 50%;
  margin-left: -2px;
}

.timeline.w-icons:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: rgba(255, 255, 255, 0.5);
  left: 50%;
  margin-left: -1.5px;
}

.timeline.light-line:before {
  background-color: rgba(245, 245, 245, 0.5);
}

.timeline.light-line.w-icons:before {
  background-color: rgba(245, 245, 245, 0.5);
}

.timeline.inverse-line:before {
  background-color: rgba(27, 26, 26, 0.5);
}

.timeline.inverse-line.w-icons:before {
  background-color: rgba(27, 26, 26, 0.5);
}

.timeline.primary-line:before {
  background-color: rgba(214, 29, 9, 0.5);
}

.timeline.primary-line.w-icons:before {
  background-color: rgba(214, 29, 9, 0.5);
}

.timeline > li {
  margin-bottom: 20px;
  position: relative;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li > .timeline-panel {
  background: #fff;
  width: 50%;
  float: left;
  border-radius: 0.25rem;
  padding: 1.75rem;
  position: relative;
  box-shadow: 0px 3px 12px rgba(0, 30, 60, 0.03);
}

.timeline > li:not(.timeline-inverted) {
  padding-right: 50px;
}

.timeline > li.timeline-inverted {
  padding-left: 50px;
}

.timeline.w-icons > li:not(.timeline-inverted) {
  padding-right: 85px;
}

.timeline.w-icons > li.timeline-inverted {
  padding-left: 85px;
}

.timeline > li > .timeline-badge {
  color: #fff;
  background: #0978f5;
  width: 25px;
  height: 25px;
  line-height: 25px !important;
  font-size: 1rem;
  text-align: center;
  position: absolute;
  top: 25px;
  left: 50%;
  margin-left: -14px;
  z-index: 9;
  border: 6px solid #fff;
  border-radius: 50%;
  box-shadow: 0px 1px 4px rgba(0, 30, 60, 0.03);
}

.timeline.w-icons > li > .timeline-badge {
  color: #fff;
  background: #0978f5;
  width: 60px;
  height: 60px;
  line-height: 65px !important;
  font-size: 1rem;
  text-align: center;
  position: absolute;
  top: 20px;
  left: 50%;
  margin-left: -30px;
  z-index: 100;
  border: 0;
  border-radius: 50%;
  box-shadow: 0px 5px 18px rgba(0, 30, 60, 0.06);
}

.timeline.w-icons > li > .timeline-badge .material-icons {
  line-height: 60px !important;
}

.timeline > li.timeline-inverted > .timeline-badge {
  top: 0;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}

ul.timeline {
  margin-bottom: 0;
}

.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}

.timeline-body > p + p {
  margin-top: 5px;
}

/*--- end of timeline ---*/
/*-------- pricing tables --------*/
.pricing-table {
  background: #fff;
  position: relative;
  margin: 1rem 0;
  z-index: 0;
  border-radius: 0.25rem;
  box-shadow: 0px 3px 12px rgba(0, 30, 60, 0.03);
}

.pricing-table ul, .pricing-table ol {
  margin-bottom: 0;
  padding: 0;
  padding-bottom: 2rem;
}

.pricing-title {
  color: #848d96;
  font-size: 1.5rem;
  font-weight: 700;
  padding: 3rem 0;
  margin-bottom: 0;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.pricing-table-price p {
  margin-bottom: 0;
  line-height: 1rem;
}

.pricing-currency {
  font-size: 1rem;
  font-weight: 700;
  vertical-align: 20px;
}

.pricing-price {
  font-size: 2.5rem;
  font-weight: 700;
}

.pricing-table-content {
  padding: 3rem 0;
}

.pricing-table-content li {
  margin-bottom: 1.25rem;
}

.pricing-table-content li:last-child {
  margin-bottom: 0rem;
}

.stacked .pricing-table.featured {
  background: rgba(255, 255, 255, 0.87);
  z-index: 1;
  margin-left: -0.25rem;
  margin-right: -0.25rem;
  box-shadow: 0px 5px 18px rgba(0, 30, 60, 0.06);
}

.pricing-table.featured .pricing-title {
  padding-top: 4rem;
}

.pricing-table.featured .pricing-table-content {
  padding-bottom: 4rem;
}

.featured-pt .card-body {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

/*-------- end of pricing tables --------*/
/*------- widgets -------*/
.widget {
  background: #fff;
  margin: 15px 0;
  padding: 30px;
  border: 1px solid #f1f4f7;
  border-radius: 0.25rem;
}

.widget.big {
  margin: 30px 0;
}

.widget.first, .widget.big.first {
  margin-top: 0;
}

.widget.last, .widget.big.last {
  margin-bottom: 0;
}

.widget-title {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 25px;
}

.footer-wrapper .widget-title {
  font-size: 1.5rem;
}

.menu-list a {
  font-size: 14px;
  color: #797d85;
  font-weight: 400;
}

.menu-list li {
  margin-bottom: 8px;
}

.menu-list.list-icon a {
  padding-left: 15px;
}

.menu-list li:last-child {
  margin-bottom: 0;
}

.menu-list a:hover {
  color: #111724;
}

.widget-img {
  max-width: 100px;
}

.project-widget img {
  max-width: 96.8px;
  margin: 4px 2px;
  border-radius: 50px;
  -webkit-transition: ease-in-out 0.2s;
  -moz-transition: ease-in-out 0.2s;
  transition: ease-in-out 0.2s;
}

.project-widget img:hover {
  opacity: 0.75;
}

.instagram-widget img {
  max-width: 59.5px;
  margin: 2px 0;
  border-radius: 0.1875rem;
  -webkit-transition: ease-in-out 0.2s;
  -moz-transition: ease-in-out 0.2s;
  transition: ease-in-out 0.2s;
}

.instagram-widget img:hover {
  opacity: 0.75;
}

.tag-cloud .btn {
  margin: 5px 3px !important;
  border-radius: 50px;
  font-size: 12px;
  padding: 8px 24px;
}

/*--- post-widget ---*/
.post-widget img {
  max-width: 120px;
  height: auto;
  float: left;
  margin-right: 15px;
  border-radius: 0.125rem;
}

.post-widget.small img {
  max-width: 85px;
}

.post-widget .post-small {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}

.recent-post-content p, .recent-post-content a {
  font-size: 1rem;
}

.recent-post-title, .recent-post-title:focus {
  color: #111724;
  font-weight: 400;
}

.recent-post-title:hover {
  color: #111724;
  opacity: 0.5;
}

.post-date, .post-tag {
  font-size: 12px !important;
  margin-bottom: 5px;
}

.recent-post.last img, .recent-post.last .post-date {
  margin-bottom: 0;
}

.widget .recent-post {
  margin-bottom: 25px;
}

.widget .recent-post.last {
  margin-bottom: 0px;
}

/*--- end of post-widget ---*/
/*------- end of widgets -------*/
/*---------- end of components ----------*/
/*---------- container ----------*/
.main-container {
  position: relative;
  padding: 100px 0 100px 0;
}

.bottom-element {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -25%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}

.bottom-element .promo-box {
  width: 100%;
}

.bottom-element.xs {
  bottom: -5%;
}

.bottom-element.sm {
  bottom: -10%;
}

.bottom-element.lg {
  bottom: -20%;
}

.bottom-element.xl {
  bottom: -25%;
}

.bottom-element.xxl {
  bottom: -30%;
}

.pnf-container {
  min-height: 100vh;
  height: 100%;
}

.error-title {
  font-size: 14rem;
}

.demo-item-link .card {
  box-shadow: 0px 3px 12px rgba(0, 30, 60, 0.03);
  transition: all 0.2s ease-in-out;
}

.demo-item-link:hover .card {
  transform: translateY(-5px);
  box-shadow: 0px 5px 18px rgba(0, 30, 60, 0.06);
}

.demo-item-link .card-title {
  color: #111724;
  font-size: 1.125rem;
  transition: ease-in-out 0.3s;
}

.demo-item-link:hover .card-title {
  color: #0978f5;
}

.demo-item-link .card-body {
  padding: 1.75rem;
}

/*---------- end of container ----------*/
/*---------- sections ----------*/
section {
  position: relative;
  padding: 50px 0 0 0;
}

section.big {
  padding: 80px 0;
}

section.lg {
  padding: 100px 0 0 0;
}

section.xl {
  padding: 200px 0 0 0;
}

section.xxl {
  padding: 300px 0;
}

section.fh {
  height: 100vh;
}

/*-------- headers --------*/
header {
  padding: 100px 0;
  position: relative;
  overflow: hidden;
}

header.big {
  padding: 150px 0;
}

header.lg {
  padding: 220px 0;
}

header.xl {
  padding: 300px 0;
}

header.xxl {
  padding: 390px 0;
}

header.fh {
  height: 100vh;
}

.page-header {
  text-align: center;
  padding: 200px 0;
}

.page-header.w-absolute-nav .page-title {
  padding-top: 290px;
}

.page-header.w-absolute-nav {
  padding: 0 0 200px 0;
}

.page-header .breadcrumb {
  text-align: left;
  display: block;
}

header.dark .page-title, header.dark .title, .dark .section-title {
  color: #fff;
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}

header .page-title, header .title, .section-title {
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
}

header .page-title {
  font-size: 48px;
  letter-spacing: 0.175rem;
  margin-left: -2px;
}

header .fixed-title {
  transform: translateY(-50%);
  position: fixed;
  top: 380px;
  left: 0;
  right: 0;
}

header .absolute-title {
  transform: translateY(-50%);
  position: absolute;
  top: -40%;
  left: -0.8%;
  right: 0;
}

.header-image-bottom {
  pointer-events: none;
  position: relative;
  z-index: 1;
  margin-bottom: -2px;
}

.header-content {
  position: relative;
  z-index: 2;
}

.header-content.slide-header {
  padding: 310px 0;
}

.section-title {
  font-size: 56px;
}

header .title {
  font-size: 8vw;
  font-weight: 500;
}

.header-text {
  font-size: 18px;
  font-weight: 500;
  max-width: 390px;
  margin-bottom: 30px;
}

/*--- typewriter ---*/
.typewrite > .wrap {
  position: relative;
  color: #111724;
  font-weight: 500;
}

.typewrite > .wrap:after {
  content: "|";
  color: #0978f5;
  font-weight: 700;
  position: absolute;
  animation: blinkerText 1s linear infinite;
}

.typewrite.text-primary > .wrap {
  color: #0978f5;
}

.dark .typewrite > .wrap, .dark-mode .typewrite > .wrap {
  color: #fff;
}

@keyframes blinkerText {
  50% {
    opacity: 0;
  }
}

.dark-mode .header-text {
  color: #d6d7d8;
}

.dark-mode .typewrite > .wrap {
  color: #fff;
}

/*--- end typewriter ---*/
/*-------- end of headers --------*/
/*------- portfolio -------*/
.project {
  margin: 25px 0;
  border-radius: 0.25rem;
}

.project figure {
  margin: 0;
}

.project .hovereffect {
  border-radius: 0.25rem;
  margin: 14px 0;
}

.no-gutter .project, .g-0 .project {
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 0 !important;
}

.no-gutter .project .hovereffect, .g-0 .project .hovereffect {
  border-radius: 0 !important;
  margin: 0 !important;
  box-shadow: none !important;
}

.card .project {
  margin: 0;
  border-radius: 0;
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.card .project img {
  border-radius: 0;
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.card .project .portfolio-item {
  margin: 0;
}

.card .project .hovereffect {
  border-radius: 0;
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.no-gutter-project-cards .card, .no-gutter-project-cards .card .project, .no-gutter-project-cards .card .project img {
  margin: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  transform: none !important;
}

.no-gutter-project-cards .card {
  border-left: 1px solid #f1f4f7;
  border-bottom: 1px solid #f1f4f7;
}

.no-gutter-project-cards .card .project {
  margin: 0;
  border-radius: 0 !important;
  box-shadow: none !important;
  transform: none !important;
}

.has-spacer {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
}

.has-spacer-extra-space {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
}

.has-side-spacer {
  margin-left: 30px;
  margin-right: 30px;
}

.project-title {
  font-size: 1rem;
}

.project-skill {
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 0.0375rem;
}

.project-item-link .card {
  transition: all 0.2s ease-in-out;
}

.project-item-link:hover .card {
  transform: translateY(-5px);
  box-shadow: 0px 5px 18px rgba(0, 30, 60, 0.06);
}

.project-item-link .card-title {
  color: #111724;
  font-size: 1.125rem;
  transition: ease-in-out 0.3s;
}

.project-item-link:hover .card-title {
  color: #0978f5;
}

.project-item-link .card-body {
  padding: 1.5rem;
}

/*--- default effect ---*/
.hovereffect {
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
  z-index: 2;
  box-shadow: 0px 3px 12px rgba(0, 30, 60, 0.03);
}

.hovereffect .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  opacity: 0;
  background: transparent;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.hovereffect.dark-effect.inverse-bg .overlay {
  background-color: rgba(27, 26, 26, 0.87);
}

.hovereffect.dark-effect.primary-bg .overlay {
  background-color: rgba(214, 29, 9, 0.87);
}

.hovereffect img {
  display: block;
  position: relative;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
}

.hovereffect-title .project-title-wrapper {
  position: absolute;
  top: 45%;
  left: 0;
  width: 100%;
  padding: 10px;
  transform: translateY(-50%);
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.project:hover .hovereffect-title .project-title-wrapper {
  top: 50%;
}

.hovereffect-title .project-title {
  color: #111724;
  font-size: 1.25rem;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  background: transparent;
  margin-bottom: 8px;
  transition: all .2s ease-in-out;
}

.hovereffect-title .project-title:hover {
  color: #0978f5;
}

.hovereffect-title p {
  color: #797d85;
  background: transparent;
  font-size: 12px;
  margin: 0;
}

.hovereffect-title.dark-effect .project-title {
  color: #fff;
}

.hovereffect-title.dark-effect p {
  color: #f1f4f7;
}

.hovereffect-title .hover-bottom {
  font-size: 16px;
  background: #fff;
  position: absolute;
  bottom: -10%;
  left: 0;
  right: 0;
  padding: 15px 0;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.project:hover .hovereffect-title .hover-bottom {
  bottom: 0;
}

.hovereffect-title .hover-bottom a {
  color: #111724;
}

.hovereffect-title.dark-effect .hover-bottom a {
  color: #fff;
}

.hovereffect-title.project-icons {
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}

.project:hover .hovereffect-title.project-icons {
  top: 50%;
}

.hovereffect-title.project-icons a {
  display: inline-block;
  color: #fff;
  font-size: 12px;
  line-height: 50px;
  width: 50px;
  height: 50px;
  margin: 10px;
  background: #0978f5;
  border-radius: 50px;
  box-shadow: none;
}

.hovereffect-title.project-icons a:hover {
  background: #dfeeff !important;
  color: #0978f5;
}

.dark-effect .hovereffect-title.project-icons a {
  color: #111724;
  background: rgba(255, 255, 255, 0.15);
}

.dark-effect .hovereffect-title.project-icons a:hover {
  background: #fff !important;
  color: #0978f5;
}

.hovereffect-title.project-icons .hover-bottom {
  color: #fff;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  margin-top: 0;
  opacity: 1;
}

.hovereffect-title.project-icons a:hover {
  background: #005bc1;
}

.hovereffect-title .hover-bottom a {
  opacity: 0.7;
  margin: 0 5px;
}

.hovereffect-title .hover-bottom a {
  opacity: 0.7;
}

.hovereffect-title .hover-bottom a:hover {
  opacity: 1;
}

.hovereffect:hover .overlay {
  opacity: 1;
  filter: alpha(opacity=100);
}

.hovereffect.dark-effect .hovereffect-title .project-title {
  color: #fff;
}

.hovereffect.dark-effect .project-skill {
  color: #f1f4f7;
}

.hovereffect.dark-effect .hover-bottom a {
  color: #fff;
}

/*--- end of default effect ---*/
/*--- isotope-filter buttons ---*/
.filters-button-group {
  margin-bottom: 30px;
}

.filter-wrapper {
  display: inline-block;
}

.filter-icon {
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  font-size: 16px;
  line-height: 0;
  color: #0978f5;
  background-color: #dfeeff;
  margin-right: 5px;
  padding: 12px;
  border-radius: 50%;
}

.filter-icon.solid {
  color: #fff;
  background-color: #0978f5;
}

.filter-icon.bordered {
  color: #0978f5;
  background-color: transparent;
  padding: 10px;
  border: 2px solid #0978f5;
}

.filter-title {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5rem;
  color: #111724;
  margin-right: 20px;
  margin-bottom: 0;
}

.filter-btn {
  font-size: 1rem;
  line-height: 1.75rem;
  min-width: 7.5rem;
  margin: 0.5rem 0.5rem 0.5rem 0rem;
  padding: 0.5rem 2.25rem;
  border-radius: 0.25rem;
  box-shadow: none;
  transform: none !important;
}

.filter-btn:hover {
  color: #0978f5;
  background-color: #dfeeff;
  box-shadow: none;
}

.filter-btn.is-checked {
  color: #fff;
  background-color: #0978f5;
  box-shadow: none;
}

.bordered-filter .filter-btn {
  border: 2px solid transparent;
}

.bordered-filter .filter-btn:hover {
  color: #111724;
  background-color: transparent;
  border-color: #111724;
  box-shadow: none;
}

.bordered-filter .filter-btn.is-checked {
  color: #0978f5;
  background-color: transparent;
  border-color: #0978f5;
}

.filter-btn.filter-btn-secondary:hover {
  color: #c2c7cd;
  background-color: #e4e8ed;
}

.filter-btn.filter-btn-secondary.is-checked {
  color: #fff;
  background-color: #c2c7cd;
}

.filter-btn.filter-btn-success:hover {
  color: #2cb34c;
  background-color: #c7fbd3;
}

.filter-btn.filter-btn-success.is-checked {
  color: #fff;
  background-color: #2cb34c;
}

.filter-btn.filter-btn-info:hover {
  color: #4f58db;
  background-color: #c7cafa;
}

.filter-btn.filter-btn-info.is-checked {
  color: #fff;
  background-color: #4f58db;
}

.filter-btn.filter-btn-warning:hover {
  color: #e5a12b;
  background-color: #f9e0b4;
}

.filter-btn.filter-btn-warning.is-checked {
  color: #fff;
  background-color: #e5a12b;
}

.filter-btn.filter-btn-danger:hover {
  color: #d72f40;
  background-color: #f9bcc2;
}

.filter-btn.filter-btn-danger.is-checked {
  color: #fff;
  background-color: #d72f40;
}

/*--- end of isotope-filter buttons ---*/
/*------- end of portfolio -------*/
/*------- footers -------*/
footer {
  background: #fff;
  padding: 1rem 0;
}

footer p {
  font-size: 1rem;
  color: #111724;
  margin-bottom: 0;
}

footer .text-link {
  color: #111724;
  font-weight: 500;
}

footer .text-link:hover {
  color: #0978f5;
}

.dark footer .text-link {
  color: #fff;
  font-weight: 500;
}



.dark footer .text-link:hover {
  color: #fff;
  opacity: 1;
}

footer.big {
  padding: 70px 0;
}

footer.light {
  background: #c2c7cd;
}

footer.mid {
  background: #848d96;
}

footer.dark {
  background: #515254;
}

footer.dark p {
  color: #f1f4f7;
}

.footer-left-area {
  text-align: left;
}

.footer-right-area {
  text-align: right;
}

footer.dark.bg-primary a, footer.dark.bg-primary-dark a {
  color: #fff;
}

footer.dark.bg-primary a:hover, footer.dark.bg-primary-dark a:hover {
  opacity: 1;
}

.footer-link, .footer-link:focus {
  font-weight: 400;
  color: #191b1d;
}

.footer-link:hover {
  color: #191b1d;
  opacity: 1;
}

.dark .footer-link {
  color: #c2c7cd;
  opacity: 0.65;
}

.dark .footer-link:hover {
  color: #c2c7cd;
  opacity: 1;
}

.footer-logo {
  width: auto;
  max-height: 28px;
  font-size: 28px;
  font-weight: 500;
}

.footer-widget-area {
  padding: 85px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.footer-widget-area.dark {
  border-bottom: 1px solid rgba(11, 11, 11, 0.3);
}

.footer-widget-area .widget {
  background: transparent;
  padding: 0;
  border: none;
  box-shadow: none;
}

.footer-widget-area .widget-title {
  font-size: 1.125rem;
  font-weight: 400;
  margin-bottom: 20px;
}

.footer-widget-area .widget p {
  font-size: 14px;
}

.footer-widget-area .menu-list li:before, .footer-widget-area .menu-list a:before {
  font-size: 70%;
}

.footer-wrapper .text-link i {
  font-size: 1rem;
}

.payment-methods img {
  pointer-events: none;
  display: inline-block;
  width: 100%;
  max-width: 50px;
  vertical-align: middle;
  margin-right: 2px;
}

.payment-methods img:last-child {
  margin-right: 0;
}

/*------- end of footers -------*/
/*---------- end of sections ----------*/
/*------- utility classes -------*/
.container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

.stacked {
  padding: 0 15px;
}

.stacked > .col-md-4, .stacked > .col-md-3 {
  padding-left: 0;
  padding-right: 0;
}

.v-top {
  display: flex;
  align-items: start;
}

.v-center {
  display: flex;
  align-items: center;
}

.v-bottom {
  display: flex;
  align-items: end;
}

.va-top {
  vertical-align: top !important;
}

.va-middle {
  vertical-align: middle !important;
}

.va-bottom {
  vertical-align: bottom !important;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.hidden {
  display: none !important;
}

.d-block, .btn-block {
  display: block !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-flex {
  display: flex !important;
}

.d-table {
  display: table !important;
}

.d-table-cell {
  display: table-cell !important;
}

.vertical-align {
  display: table;
  height: 100%;
  width: 100%;
}

.vertical-align .table-cell {
  display: table-cell;
  vertical-align: middle;
}

.no-transform {
  transform: none !important;
}

.list-inline > li {
  display: inline-block;
  margin-right: 1rem;
}

.full-width {
  width: 100%;
  max-width: 100%;
}

.full-height {
  height: 100vh;
}

.max-fh {
  max-height: 100vh;
}

.min-fh {
  min-height: 100vh;
}

::-moz-selection {
  color: #fff;
  background: #282c31;
}

::selection {
  color: #fff;
  background: #282c31;
}

/*---tyopgraphy ---*/
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.title-font {
  font-family: "Poppins", sans-serif !important;
}

.primary-font {
  font-family: "Poppins", sans-serif !important;
}

.secondary-font {
  font-family: "Poppins", sans-serif !important;
}

.fw-thin {
  font-weight: 100 !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-regular {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.text-sm {
  font-size: 12px;
}

.text-xs {
  font-size: 10px;
}

.lsp {
  letter-spacing: 0.02rem;
}

.lsp-2x {
  letter-spacing: 0.0375rem;
}

.lsp-big {
  letter-spacing: 0.075rem;
}

.lsp-extra {
  letter-spacing: 0.175rem;
}

.fs-4 {
  font-size: 4px !important;
}

.fs-6 {
  font-size: 6px !important;
}

.fs-8 {
  font-size: 8px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-34 {
  font-size: 34px !important;
}

.fs-36 {
  font-size: 36px !important;
}

.fs-38 {
  font-size: 38px !important;
}

.fs-40 {
  font-size: 40px !important;
}

.fs-50 {
  font-size: 50px !important;
}

.fs-60 {
  font-size: 60px !important;
}

.fs-70 {
  font-size: 70px !important;
}

.fs-80 {
  font-size: 80px !important;
}

.fs-90 {
  font-size: 90px !important;
}

.fs-100 {
  font-size: 100px !important;
}

.fs-120 {
  font-size: 120px !important;
}

.fs-150 {
  font-size: 150px !important;
}

.fs-170 {
  font-size: 170px !important;
}

.fs-200 {
  font-size: 200px !important;
}

.lh-0 {
  line-height: 0 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-1-2 {
  line-height: 1.1 !important;
}

.lh-1-2 {
  line-height: 1.2 !important;
}

.lh-1-5 {
  line-height: 1.5 !important;
}

.lh-1-7 {
  line-height: 1.7 !important;
}

.lh-2 {
  line-height: 2 !important;
}

.lh-3 {
  line-height: 3 !important;
}

.lh-4 {
  line-height: 4 !important;
}

.lh-5 {
  line-height: 5 !important;
}

/*--- end of tyopgraphy ---*/
/*--- image ---*/
img {
  width: 100%;
  max-width: 100%;
  height: auto;
  -webkit-backface-visibility: hidden;
}

.img-auto {
  width: auto !important;
}

.img-thumbnail {
  padding: 2px;
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 0.25rem;
  margin-bottom: 15px;
  box-shadow: 0px 3px 12px rgba(0, 30, 60, 0.03);
}

.img-cirlce {
  border-radius: 50%;
}

.bg-img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.bg-img.img-bottom {
  background-position: center bottom;
}

.bg-img.img-top {
  background-position: center top;
}

.bg-img.img-left {
  background-position: center left;
}

.bg-img.img-right {
  background-position: center right;
}

.bg-fixed {
  background-attachment: fixed !important;
}

.bg-img.bg-contain {
  background-size: contain;
}

.overlay-dark {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.overlay-darker {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.87);
}

.overlay-half-dark {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background: rgba(0, 0, 0, 0.87);
}

.overlay-half-darker {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background: rgba(0, 0, 0, 0.87);
}

.overlay-light {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.87);
}

.overlay-lighter {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
}

.overlay-half-light {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.87);
}

.overlay-half-lighter {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
}

.overlay-half-light-frosted {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.87);
}

.overlay-primary {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(214, 29, 9, 0.5);
}

.overlay-primary-darker {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(214, 29, 9, 0.87);
}

.overlay-half-primary {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background: rgba(214, 29, 9, 0.87);
}

.overlay-half-primary-darker {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background: rgba(214, 29, 9, 0.87);
}

/*--- end of image ---*/
/*--- spacers ---*/
.spacer-sm {
  margin: 1.5rem 0;
  line-height: 0;
}

.spacer {
  margin: 2rem 0;
  line-height: 0;
}

.spacer-2x {
  margin: 3rem 0;
  line-height: 0;
}

.spacer-3x {
  margin: 4rem 0;
  line-height: 0;
}

.spacer-line {
  width: 60px;
  line-height: 0;
  border-bottom: 2px solid #f1f4f7;
  margin-left: auto;
  margin-right: auto;
}

.spacer-line-2x {
  width: 120px;
  line-height: 0;
  border-bottom: 2px solid #f1f4f7;
  margin-left: auto;
  margin-right: auto;
}

.spacer-line-solid {
  width: 60px;
  line-height: 0;
  border-bottom: 4px solid #e6e9ec;
  margin-left: auto;
  margin-right: auto;
}

.spacer-line-solid-2x {
  width: 120px;
  line-height: 0;
  border-bottom: 4px solid #e6e9ec;
  margin-left: auto;
  margin-right: auto;
}

.spacer-line-fw {
  width: 100%;
  line-height: 0;
  border-bottom: 1px solid #f1f4f7;
}

.has-top-menu-sm {
  margin-top: 70px;
}

.has-top-menu {
  margin-top: 90px;
}

.btn-floating {
  position: fixed;
  bottom: 15px;
  right: 15px;
  display: none;
  z-index: 99;
}

/* margins */
.m-0 {
  margin: 0 !important;
}

.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-35 {
  margin: 35px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-45 {
  margin: 45px !important;
}

.m-50 {
  margin: 50px !important;
}

.m-60 {
  margin: 60px !important;
}

.m-70 {
  margin: 70px !important;
}

.m-80 {
  margin: 80px !important;
}

.m-90 {
  margin: 90px !important;
}

.m-100 {
  margin: 100px !important;
}

.m-150 {
  margin: 150px !important;
}

.m-200 {
  margin: 200px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.ml-0 {
  margin-bottom: 0 !important;
}

.m-x-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.m-y-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.m-x-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.m-y-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.m-y-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.m-y-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.m-y-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.m-y-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.m-y-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.m-y-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.m-y-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.m-y-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.m-y-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.m-y-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.m-y-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.m-y-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.m-y-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.m-y-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.m-y-150 {
  margin-top: 150px !important;
  margin-bottom: 150px !important;
}

.m-y-200 {
  margin-top: 200px !important;
  margin-bottom: 200px !important;
}

.m-y-50 {
  margin-top: 50px !important;

.m-y-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}
  margin-bottom: 50px !important;
}

.m-x-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.m-x-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.m-x-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.m-x-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.m-x-25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.m-x-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.m-x-35 {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

.m-x-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.m-x-45 {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

.m-x-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.m-x-60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.m-x-70 {
  margin-left: 70px !important;
  margin-right: 70px !important;
}

.m-x-80 {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

.m-x-90 {
  margin-left: 90px !important;
  margin-right: 90px !important;
}

.m-x-100 {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.m-x-150 {
  margin-left: 150px !important;
  margin-right: 150px !important;
}

.m-x-200 {
  margin-left: 200px !important;
  margin-right: 200px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mt-150 {
  margin-top: 150px !important;
}

.mt-200 {
  margin-top: 200px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mb-150 {
  margin-bottom: 150px !important;
}

.mb-200 {
  margin-bottom: 200px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.ml-150 {
  margin-left: 510px !important;
}

.ml-200 {
  margin-left: 200px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.mr-90 {
  margin-right: 90px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

.mr-150 {
  margin-right: 150px !important;
}

.mr-200 {
  margin-right: 200px !important;
}

/* end of margins */
/* paddings */
.p-0 {
  padding: 0 !important;
}

.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-35 {
  padding: 35px !important;
}

.p-40 {
  padding: 40px !important;
}

.p-45 {
  padding: 45px !important;
}

.p-50 {
  padding: 50px !important;
}

.p-60 {
  padding: 60px !important;
}

.p-70 {
  padding: 70px !important;
}

.p-80 {
  padding: 80px !important;
}

.p-90 {
  padding: 90px !important;
}

.p-100 {
  padding: 100px !important;
}

.p-150 {
  padding: 150px !important;
}

.p-200 {
  padding: 200px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.p-y-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-x-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.p-y-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.p-y-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.p-y-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.p-y-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.p-y-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.p-y-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.p-y-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.p-y-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.p-y-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.p-y-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.p-y-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.p-y-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.p-y-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.p-y-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.p-y-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.p-y-150 {
  padding-top: 150px !important;
  padding-bottom: 150px !important;
}

.p-y-200 {
  padding-top: 200px !important;
  padding-bottom: 200px !important;
}

.p-x-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.p-x-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.p-x-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.p-x-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.p-x-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.p-x-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.p-x-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.p-x-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.p-x-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.p-x-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.p-x-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.p-x-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.p-x-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.p-x-90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.p-x-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.p-x-150 {
  padding-left: 150px !important;
  padding-right: 150px !important;
}

.p-x-200 {
  padding-left: 200px !important;
  padding-right: 200px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pt-150 {
  padding-top: 150px !important;
}

.pt-200 {
  padding-top: 200px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pb-150 {
  padding-bottom: 150px !important;
}

.pb-200 {
  padding-bottom: 200px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.pl-150 {
  padding-left: 150px !important;
}

.pl-200 {
  padding-left: 200px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

.pr-150 {
  padding-right: 150px !important;
}

.pr-200 {
  padding-right: 200px !important;
}

/* end of paddings */
/* positions */
.x-0 {
  left: 0;
  right: 0;
}

.x-5 {
  left: 5px;
  right: 5px;
}

.x-10 {
  left: 10px;
  right: 10px;
}

.x-15 {
  left: 15px;
  right: 15px;
}

.x-20 {
  left: 20px;
  right: 20px;
}

.x-25 {
  left: 25px;
  right: 25px;
}

.x-30 {
  left: 30px;
  right: 30px;
}

.x-35 {
  left: 35px;
  right: 35px;
}

.x-40 {
  left: 40px;
  right: 40px;
}

.x-45 {
  left: 45px;
  right: 45px;
}

.x-50 {
  left: 50px;
  right: 50px;
}

.x-60 {
  left: 60px;
  right: 60px;
}

.x-70 {
  left: 70px;
  right: 70px;
}

.x-80 {
  left: 80px;
  right: 80px;
}

.x-90 {
  left: 90px;
  right: 90px;
}

.x-100 {
  left: 100px;
  right: 100px;
}

.x-150 {
  left: 150px;
  right: 150px;
}

.x-200 {
  left: 200px;
  right: 200px;
}

.y-0 {
  top: 0;
  bottom: 0;
}

.y-5 {
  top: 5px;
  bottom: 5px;
}

.y-10 {
  top: 10px;
  bottom: 1-px;
}

.y-15 {
  top: 15px;
  bottom: 15px;
}

.y-20 {
  top: 20px;
  bottom: 20px;
}

.y-25 {
  top: 25px;
  bottom: 25px;
}

.y-30 {
  top: 30px;
  bottom: 30px;
}

.y-35 {
  top: 35px;
  bottom: 35px;
}

.y-40 {
  top: 40px;
  bottom: 40px;
}

.y-45 {
  top: 45px;
  bottom: 45px;
}

.y-50 {
  top: 50px;
  bottom: 50px;
}

.y-60 {
  top: 60px;
  bottom: 60px;
}

.y-70 {
  top: 70px;
  bottom: 70px;
}

.y-80 {
  top: 80px;
  bottom: 80px;
}

.y-90 {
  top: 90px;
  bottom: 90px;
}

.y-100 {
  top: 100px;
  bottom: 100px;
}

.y-150 {
  top: 150px;
  bottom: 150px;
}

.y-200 {
  top: 200px;
  bottom: 200px;
}

.top-0 {
  top: 0 !important;
}

.top-5 {
  top: 5px !important;
}

.top-10 {
  top: 10px !important;
}

.top-15 {
  top: 15px !important;
}

.top-20 {
  top: 20px !important;
}

.top-25 {
  top: 25px !important;
}

.top-30 {
  top: 30px !important;
}

.top-35 {
  top: 35px !important;
}

.top-40 {
  top: 40px !important;
}

.top-45 {
  top: 45px !important;
}

.top-50 {
  top: 50px !important;
}

.top-60 {
  top: 60px !important;
}

.top-70 {
  top: 70px !important;
}

.top-80 {
  top: 80px !important;
}

.top-90 {
  top: 90px !important;
}

.top-100 {
  top: 100px !important;
}

.top-150 {
  top: 150px !important;
}

.top-200 {
  top: 200px !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-5 {
  bottom: 5px !important;
}

.bottom-10 {
  bottom: 10px !important;
}

.bottom-15 {
  bottom: 15px !important;
}

.bottom-20 {
  bottom: 20px !important;
}

.bottom-25 {
  bottom: 25px !important;
}

.bottom-30 {
  bottom: 30px !important;
}

.bottom-35 {
  bottom: 35px !important;
}

.bottom-40 {
  bottom: 40px !important;
}

.bottom-45 {
  bottom: 45px !important;
}

.bottom-50 {
  bottom: 50px !important;
}

.bottom-60 {
  bottom: 60px !important;
}

.bottom-70 {
  bottom: 70px !important;
}

.bottom-80 {
  bottom: 80px !important;
}

.bottom-90 {
  bottom: 90px !important;
}

.bottom-100 {
  bottom: 100px !important;
}

.bottom-150 {
  bottom: 150px !important;
}

.bottom-200 {
  bottom: 200px !important;
}

.left-0 {
  left: 0 !important;
}

.left-auto {
  left: auto !important;
}

.left-5 {
  left: 5px !important;
}

.left-10 {
  left: 10px !important;
}

.left-15 {
  left: 15px !important;
}

.left-20 {
  left: 20px !important;
}

.left-25 {
  left: 25px !important;
}

.left-30 {
  left: 30px !important;
}

.left-35 {
  left: 35px !important;
}

.left-40 {
  left: 40px !important;
}

.left-45 {
  left: 45px !important;
}

.left-50 {
  left: 50px !important;
}

.left-60 {
  left: 60px !important;
}

.left-70 {
  left: 70px !important;
}

.left-80 {
  left: 80px !important;
}

.left-90 {
  left: 90px !important;
}

.left-100 {
  left: 100px !important;
}

.left-150 {
  left: 150px !important;
}

.left-200 {
  left: 200px !important;
}

.right-0 {
  right: 0 !important;
}

.right-5 {
  right: 5px !important;
}

.right-10 {
  right: 10px !important;
}

.right-15 {
  right: 15px !important;
}

.right-20 {
  right: 20px !important;
}

.right-25 {
  right: 25px !important;
}

.right-30 {
  right: 30px !important;
}

.right-35 {
  right: 35px !important;
}

.right-40 {
  right: 40px !important;
}

.right-45 {
  right: 45px !important;
}

.right-50 {
  right: 50px !important;
}

.right-60 {
  right: 60px !important;
}

.right-70 {
  right: 70px !important;
}

.right-80 {
  right: 80px !important;
}

.right-90 {
  right: 90px !important;
}

.right-100 {
  right: 100px !important;
}

.right-150 {
  right: 150px !important;
}

.right-200 {
  right: 200px !important;
}

.right-auto {
  right: auto !important;
}

.pos-center {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.pos-v-center {
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

/* end of positions */
/*--- end of spacers ---*/
/*--- colors ---*/
.title-color {
  color: #111724 !important;
}

.text-color {
  color: #797d85 !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-white-transparent {
  background-color: rgba(255, 255, 255, 0.15) !important;
}

.bg-white-frosted {
  background-color: rgba(255, 255, 255, 0.87) !important;
}

.bg-white-frosted-dark {
  background-color: rgba(255, 255, 255, 0.98) !important;
}

.bg-black-transparent {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.bg-black-frosted {
  background-color: rgba(0, 0, 0, 0.87) !important;
}

.bg-black-frosted-dark {
  background-color: rgba(0, 0, 0, 0.98) !important;
}

.bg-inverse-transparent {
  background-color: rgba(27, 26, 26, 0.15) !important;
}

.bg-inverse-frosted {
  background-color: rgba(27, 26, 26, 0.87) !important;
}

.bg-inverse-frosted-dark {
  background-color: rgba(27, 26, 26, 0.98) !important;
}

.bg-primary-transparent {
  background: rgba(214, 29, 9, 0.15) !important;
}

.bg-primary-frosted {
  background-color: rgba(214, 29, 9, 0.87) !important;
}

.bg-primary-frosted-dark {
  background-color: rgba(214, 29, 9, 0.98) !important;
}

.bg-body {
  background-color: #fafbfc !important;
}

.bg-gradient-body {
  background: linear-gradient(180deg, #fafbfc 0%, #edf0f4 100%) !important;
}

.bg-body-dark {
  background-color: #232527 !important;
}

.bg-gradient-body-dark {
  background: linear-gradient(180deg, #232527 0%, #191a1b 100%) !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-gradient-white {
  background: linear-gradient(180deg, #fff 0%, #f1f4f7 100%) !important;
}

.bg-dark {
  background-color: #282c31 !important;
}

.bg-gradient-dark {
  background: linear-gradient(180deg, #303132 0%, #282c31 100%) !important;
}

.bg-black {
  background-color: #000 !important;
}

.bg-gradient-black {
  background: linear-gradient(180deg, #515254 0%, #000 100%) !important;
}

.bg-faded {
  background-color: #c2c7cd !important;
}

.bg-light, .bg-light-grey {
  background-color: #f1f4f7 !important;
}

.bg-light-grey-dark {
  background-color: #e6e9ec !important;
}

.bg-grey {
  background-color: #d6d7d8 !important;
}

.bg-gradient-grey {
  background: linear-gradient(180deg, #e6e9ec 0%, #d6d7d8 100%) !important;
}

.bg-grey-dark {
  background-color: #919293 !important;
}

.bg-dark-grey {
  background-color: #515254 !important;
}

.bg-dark-grey-dark {
  background-color: #303132 !important;
}

.bg-inverse {
  background-color: #191b1d !important;
}

.bg-primary-light {
  background-color: #dfeeff !important;
}

.bg-primary {
  background-color: #0978f5 !important;
}

.bg-primary-dark {
  background-color: #005bc1 !important;
}

.bg-gradient-primary {
  background: linear-gradient(180deg, #0978f5 0%, #005bc1 100%) !important;
}

.bg-secondary-light {
  background-color: #e4e8ed !important;
}

.bg-secondary {
  background-color: #c2c7cd !important;
}

.bg-secondary-dark {
  background-color: #848d96 !important;
}

.bg-gradient-secondary {
  background: linear-gradient(180deg, #c2c7cd 0%, #848d96 100%) !important;
}

.bg-success-light {
  background-color: #c7fbd3 !important;
}

.bg-success {
  background-color: #2cb34c !important;
}

.bg-success-dark {
  background-color: #12812c !important;
}

.bg-gradient-success {
  background: linear-gradient(180deg, #2cb34c 0%, #12812c 100%) !important;
}

.bg-info-light {
  background-color: #c7cafa !important;
}

.bg-info {
  background-color: #4f58db !important;
}

.bg-info-dark {
  background-color: #2e36a5 !important;
}

.bg-gradient-info {
  background: linear-gradient(180deg, #4f58db 0%, #2e36a5 100%) !important;
}

.bg-warning-light {
  background-color: #f9e0b4 !important;
}

.bg-warning {
  background-color: #e5a12b !important;
}

.bg-warning-dark {
  background-color: #c27d04 !important;
}

.bg-gradient-warning {
  background: linear-gradient(180deg, #e5a12b 0%, #c27d04 100%) !important;
}

.bg-danger-light {
  background-color: #f9bcc2 !important;
}

.bg-danger {
  background-color: #d72f40 !important;
}

.bg-danger-dark {
  background-color: #9b1421 !important;
}

.bg-gradient-danger {
  background: linear-gradient(180deg, #d72f40 0%, #9b1421 100%) !important;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

.text-light, .text-light-grey {
  color: #f1f4f7 !important;
}

.text-muted, .text-grey {
  color: #d6d7d8 !important;
}

.text-title {
  color: #111724 !important;
}

.text-primary {
  color: #0978f5 !important;
}

.text-secondary {
  color: #c2c7cd !important;
}

.text-secondary-dark {
  color: #848d96 !important;
}

.text-success {
  color: #2cb34c !important;
}

.text-info {
  color: #4f58db !important;
}

.text-warning {
  color: #e5a12b !important;
}

.text-danger {
  color: #d72f40 !important;
}

.text-facebook {
  color: #4e68a1 !important;
}

.text-twitter {
  color: #65b5f2 !important;
}

.text-linkedin {
  color: #2083bc !important;
}

.text-pinterest {
  color: #d2373b !important;
}

.text-dribbble {
  color: #ec5f94 !important;
}

.text-instagram {
  color: #4c5fd7 !important;
}

.text-vimeo {
  color: #1ab7ea !important;
}

.text-youtube {
  color: #e52d27 !important;
}

.text-dropbox {
  color: #3d9ae8 !important;
}

.text-behance {
  color: #053eff !important;
}

.text-skype {
  color: #00aff0 !important;
}

a.text-white:focus, a.text-white:hover {
  color: #f1f4f7 !important;
}

a.text-black:focus, a.text-black:hover {
  color: #dark-grey !important;
}

a.text-primary:focus, a.text-primary:hover {
  color: #005bc1 !important;
}

a.text-secondary:focus, a.text-secondary:hover {
  color: #848d96 !important;
}

a.text-success:focus, a.text-success:hover {
  color: #12812c !important;
}

a.text-info:focus, a.text-info:hover {
  color: #2e36a5 !important;
}

a.text-warning:focus, a.text-warning:hover {
  color: #c27d04 !important;
}

a.text-danger:focus, a.text-danger:hover {
  color: #9b1421 !important;
}

.text-hover {
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.primary-hover:hover {
  color: #0978f5 !important;
}

.secondary-hover:hover {
  color: #c2c7cd !important;
}

.success-hover:hover {
  color: #2cb34c !important;
}

.info-hover:hover {
  color: #4f58db !important;
}

.warning-hover:hover {
  color: #e5a12b !important;
}

.danger-hover:hover {
  color: #d72f40 !important;
}

.white-hover:hover {
  color: #fff !important;
}

.grey-hover:hover {
  color: #d6d7d8 !important;
}

.black-hover:hover {
  color: #000 !important;
}

.dark-hover:hover {
  color: #282c31 !important;
}

/*--- end of olors ---*/
/*--- positions ---*/
.relative, .position-relative {
  position: relative !important;
}

.absolute, .position-absolute {
  position: absolute !important;
}

.fixed, .position-fixed {
  position: fixed !important;
}

.static, .position-static {
  position: static !important;
}

.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-3 {
  z-index: 3 !important;
}

.z-index-4 {
  z-index: 4 !important;
}

.z-index-5 {
  z-index: 5 !important;
}

.z-index-6 {
  z-index: 6 !important;
}

.z-index-7 {
  z-index: 7 !important;
}

.z-index-8 {
  z-index: 8 !important;
}

.z-index-9 {
  z-index: 9 !important;
}

.z-index-99 {
  z-index: 99 !important;
}

.z-index-999 {
  z-index: 999 !important;
}

.z-index-9999 {
  z-index: 9999 !important;
}

.z-index-99999 {
  z-index: 99999 !important;
}

.z-index-999999 {
  z-index: 999999 !important;
}

/*--- end of positions ---*/
/*--- overflow ---*/
.overflow-hidden {
  overflow: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-auto {
  overflow: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

/*--- end of overflow---*/
/*--- width ---*/
.full-width {
  width: 100% !important;
}

.w100, .w-100 {
  max-width: 100% !important;
}

.w90, .w-90 {
  max-width: 90% !important;
}

.w80, .w-80 {
  max-width: 80% !important;
}

.w70, .w-70 {
  max-width: 70% !important;
}

.w60, .w-60 {
  max-width: 60% !important;
}

.w50, .w-50 {
  max-width: 50% !important;
}

.w40, .w-40 {
  max-width: 40% !important;
}

.w30, .w-30 {
  max-width: 30% !important;
}

.w20, .w-20 {
  max-width: 20% !important;
}

.w10, .w-10 {
  max-width: 10% !important;
}

/*--- end of width ---*/
/*--- height ---*/
.full-height {
  min-height: 100vh !important;
}

.full-height-nav {
  min-height: calc(100vh - 90px) !important;
}

.half-height {
  min-height: 50vh !important;
}

.tq-height {
  min-height: 75vh !important;
}

.height-50 {
  min-height: 50vh !important;
}

.height-75 {
  min-height: 75vh !important;
}

.height-80 {
  min-height: 80vh !important;
}

.height-90 {
  min-height: 90vh !important;
}

.h-auto {
  height: auto !important;
}

.h-50 {
  height: 50px !important;
}

.h-100 {
  height: 100px !important;
}

.h-200 {
  height: 200px !important;
}

.h-300 {
  height: 300px !important;
}

.h-400 {
  height: 400px !important;
}

.h-500 {
  height: 500px !important;
}

.h-600 {
  height: 600px !important;
}

.h-700 {
  height: 700px !important;
}

.h-800 {
  height: 800px !important;
}

.h-900 {
  height: 900px !important;
}

.h-1000 {
  height: 1000px !important;
}

/*--- end of height ---*/
/*--- opacity ---*/
.opc-100 {
  opacity: 1 !important;
}

.opc-95 {
  opacity: 0.95 !important;
}

.opc-90 {
  opacity: 0.90 !important;
}

.opc-85 {
  opacity: 0.85 !important;
}

.opc-80 {
  opacity: 0.80 !important;
}

.opc-75 {
  opacity: 0.75 !important;
}

.opc-70 {
  opacity: 0.70 !important;
}

.opc-65 {
  opacity: 0.65 !important;
}

.opc-60 {
  opacity: 0.60 !important;
}

.opc-55 {
  opacity: 0.55 !important;
}

.opc-50 {
  opacity: 0.5 !important;
}

.opc-45 {
  opacity: 0.45 !important;
}

.opc-40 {
  opacity: 0.40 !important;
}

.opc-35 {
  opacity: 0.35 !important;
}

.opc-30 {
  opacity: 0.30 !important;
}

.opc-25 {
  opacity: 0.25 !important;
}

.opc-20 {
  opacity: 0.20 !important;
}

.opc-15 {
  opacity: 0.15 !important;
}

.opc-10 {
  opacity: 0.10 !important;
}

.opc-5 {
  opacity: 0.5 !important;
}

.opc-0 {
  opacity: 0 !important;
}

/*--- end of opacity ---*/
/*--- borders ---*/
.inline-column-border {
  border-right: 1px solid #c2c7cd;
}

.inline-column-border:last-child {
  border-right: none;
}

.b-solid {
  border-style: solid !important;
}

.b-0 {
  border: none !important;
}

.b-y-0 {
  border-top: none !important;
  border-bottom: none !important;
}

.b-x-0 {
  border-left: none !important;
  border-right: none !important;
}

.bt-0 {
  border-top: none !important;
}

.bb-0 {
  border-bottom: none !important;
}

.bl-0 {
  border-left: none !important;
}

.br-0 {
  border-right: none !important;
}

.b-1 {
  border: 1px solid transparent !important;
}

.b-2 {
  border: 2px solid transparent !important;
}

.b-3 {
  border: 3px solid transparent !important;
}

.b-4 {
  border: 4px solid transparent !important;
}

.b-5 {
  border: 5px solid transparent !important;
}

.b-y-1 {
  border: 0 !important;
  border-top: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
}

.b-y-2 {
  border: 0 !important;
  border-top: 2px solid transparent !important;
  border-bottom: 2px solid transparent !important;
}

.b-y-3 {
  border: 0 !important;
  border-top: 3px solid transparent !important;
  border-bottom: 3px solid transparent !important;
}

.b-y-4 {
  border: 0 !important;
  border-top: 4px solid transparent !important;
  border-bottom: 4px solid transparent !important;
}

.b-y-5 {
  border: 0 !important;
  border-top: 5px solid transparent !important;
  border-bottom: 5px solid transparent !important;
}

.b-x-1 {
  border: 0 !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
}

.b-x-2 {
  border: 0 !important;
  border-left: 2px solid transparent !important;
  border-right: 2px solid transparent !important;
}

.b-x-3 {
  border: 0 !important;
  border-left: 3px solid transparent !important;
  border-right: 3px solid transparent !important;
}

.b-x-4 {
  border: 0 !important;
  border-left: 4px solid transparent !important;
  border-right: 4px solid transparent !important;
}

.b-x-5 {
  border: 0 !important;
  border-left: 5px solid transparent !important;
  border-right: 5px solid transparent !important;
}

.bt-1 {
  border-top: 1px solid transparent !important;
}

.bt-2 {
  border-top: 2px solid transparent !important;
}

.bt-3 {
  border-top: 3px solid transparent !important;
}

.bt-4 {
  border-top: 4px solid transparent !important;
}

.bt-5 {
  border-top: 5px solid transparent !important;
}

.bb-1 {
  border-bottom: 1px solid transparent !important;
}

.bb-2 {
  border-bottom: 2px solid transparent !important;
}

.bb-3 {
  border-bottom: 3px solid transparent !important;
}

.bb-4 {
  border-bottom: 4px solid transparent !important;
}

.bb-5 {
  border-bottom: 5px solid transparent !important;
}

.bl-1 {
  border-left: 1px solid transparent !important;
}

.bl-2 {
  border-left: 2px solid transparent !important;
}

.bl-3 {
  border-left: 3px solid transparent !important;
}

.bl-4 {
  border-left: 4px solid transparent !important;
}

.bl-5 {
  border-left: 5px solid transparent !important;
}

.br-1 {
  border-right: 1px solid transparent !important;
}

.br-2 {
  border-right: 2px solid transparent !important;
}

.br-3 {
  border-right: 3px solid transparent !important;
}

.br-4 {
  border-right: 4px solid transparent !important;
}

.br-5 {
  border-right: 5px solid transparent !important;
}

.bw-1 {
  border-width: 1px !important;
}

.bw-2 {
  border-width: 2px !important;
}

.bw-3 {
  border-width: 3px !important;
}

.bw-4 {
  border-width: 4px !important;
}

.bw-5 {
  border-width: 5px !important;
}

.bw-6 {
  border-width: 6px !important;
}

.bw-7 {
  border-width: 7px !important;
}

.bw-8 {
  border-width: 8px !important;
}

.bw-9 {
  border-width: 9px !important;
}

.bw-10 {
  border-width: 10px !important;
}

.rectangle, .sharp {
  border-radius: 0 !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-xs {
  border-radius: 0.1875rem !important;
}

.rounded-sm {
  border-radius: 0.125rem !important;
}

.rounded-lg {
  border-radius: 0.5rem !important;
}

.rounded-xl {
  border-radius: 1rem !important;
}

.rounded-5 {
  border-radius: 5px !important;
}

.rounded-10 {
  border-radius: 10px !important;
}

.rounded-15 {
  border-radius: 15px !important;
}

.rounded-20 {
  border-radius: 20px !important;
}

.rounded-25 {
  border-radius: 25px !important;
}

.rounded-30 {
  border-radius: 30px !important;
}

.pill, .btn-pill {
  border-radius: 50px !important;
}

.circle {
  border-radius: 50% !important;
}

.border-white {
  border-color: #fff !important;
}

.border-light {
  border-color: 300 !important;
}

.border-light-grey {
  border-color: #f1f4f7 !important;
}

.border-light-grey-dark {
  border-color: #e6e9ec !important;
}

.border-grey {
  border-color: #d6d7d8 !important;
}

.border-black {
  border-color: #000 !important;
}

.border-dark {
  border-color: #282c31 !important;
}

.border-faded {
  border-color: #f1f4f7 !important;
}

.border-inverse {
  border-color: #515254 !important;
}

.border-primary {
  border-color: #0978f5 !important;
}

.border-primary-dark {
  border-color: #005bc1 !important;
}

.border-secondary {
  border-color: #c2c7cd !important;
}

.border-secondary-dark {
  border-color: #848d96 !important;
}

.border-success {
  border-color: #2cb34c !important;
}

.border-success-dark {
  border-color: #12812c !important;
}

.border-info {
  border-color: #4f58db !important;
}

.border-info-dark {
  border-color: #2e36a5 !important;
}

.border-warning {
  border-color: #e5a12b !important;
}

.border-warning-dark {
  border-color: #c27d04 !important;
}

.border-danger {
  border-color: #d72f40 !important;
}

.border-danger-dark {
  border-color: #9b1421 !important;
}

.border-transparent {
  border-color: transparent !important;
}

/*--- end of borders ---*/
/*--- custom attributes ---*/
.after-dropdown {
  margin-left: 15px;
}

.hide-dropdown-arrow:after {
  display: none !important;
}

.no-move:hover {
  transform: translateY(0) !important;
}

.move, .big-move, .blur {
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.move:hover {
  transform: translateY(-3px) !important;
}

.big-move:hover {
  transform: translateY(-6px) !important;
}

.raised-xs, .shadow-xs {
  box-shadow: 0px 1px 4px rgba(0, 30, 60, 0.03) !important;
}

.raised-sm, .shadow-sm {
  box-shadow: 0px 2px 8px rgba(0, 30, 60, 0.03) !important;
}

.raised, .shadow {
  box-shadow: 0px 3px 12px rgba(0, 30, 60, 0.03) !important;
}

.raised-lg, .shadow-lg {
  box-shadow: 0px 5px 18px rgba(0, 30, 60, 0.06) !important;
}

.raised-xl, .shadow-xl {
  box-shadow: 0px 10px 30px rgba(0, 30, 60, 0.06) !important;
}

.blur {
  box-shadow: 0px 2px 8px rgba(0, 30, 60, 0.03) !important;
}

.blur:hover {
  box-shadow: 0px 10px 30px rgba(0, 30, 60, 0.06) !important;
}

.raised.move:hover {
  box-shadow: 0px 5px 18px rgba(0, 30, 60, 0.06) !important;
}

.no-blur {
  backdrop-filter: none !important;
  -webkit-backdrop-filter: none !important;
}

body .no-shadow, body .no-shadow:hover, body .no-shadow:active, body .no-shadow:focus {
  box-shadow: none !important;
}

.transform.rotate-1 {
  -webkit-transform: rotate(-40deg);
  transform: rotate(-40deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.transform.rotate-2 {
  -webkit-transform: rotate(-10deg);
  transform: rotate(-10deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.transform.rotate-3 {
  -webkit-transform: rotate(5deg);
  transform: rotate(5deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.transform.rotate-4 {
  -webkit-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

.transform.rotate-5 {
  -webkit-transform: translate3d(20%, -55%, 0);
  transform: translate3d(20%, -55%, 0);
}

/*--- end of custom attributes ---*/
/*--- end of custom attributes ---*/
/*--- animations ---*/
.animate__animated {
  -webkit-animation-duration: 1s !important;
  animation-duration: 1s !important;
}

.animate__animated.fast {
  -webkit-animation-duration: 0.5s !important;
  animation-duration: 0.4s !important;
}

.animate__animated.first {
  -webkit-animation-duration: 1.5s !important;
  animation-duration: 1.5s !important;
  animation-delay: 0.5s;
}

.animate__animated.second {
  -webkit-animation-duration: 1.6s !important;
  animation-duration: 1.6s !important;
  animation-delay: 1s;
}

.animate__animated.third {
  -webkit-animation-duration: 1.5s !important;
  animation-duration: 1.5s !important;
  animation-delay: 1.3s;
}

.animate__animated.fourth {
  -webkit-animation-duration: 1.6s !important;
  animation-duration: 1.6s !important;
  animation-delay: 1.8s;
}

.animate__animated.fifth {
  -webkit-animation-duration: 1.5s !important;
  animation-duration: 1.5s !important;
  animation-delay: 2s;
}

.animate__animated.sixth {
  -webkit-animation-duration: 1.5s !important;
  animation-duration: 1.5s !important;
  animation-delay: 2.5s;
}

.animate__animated.seventh {
  -webkit-animation-duration: 1.6s !important;
  animation-duration: 1.6s !important;
  animation-delay: 3.2s;
}

.animate__animated.eighth {
  -webkit-animation-duration: 1.5s !important;
  animation-duration: 1.5s !important;
  animation-delay: 3.5s;
}

.animate__animated.nineth {
  -webkit-animation-duration: 1.6s !important;
  animation-duration: 1.6s !important;
  animation-delay: 3.8s;
}

.animate__animated.tenth {
  -webkit-animation-duration: 2s !important;
  animation-duration: 2s !important;
  animation-delay: 4s;
}

.animate.pulse {
  animation: pulse 1s infinite;
  animation-timing-function: linear;
}

.animate.pulse-hover:hover {
  animation: pulse 1s infinite;
  animation-timing-function: linear;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
    100% {
      transform: scale(1);
    }
  }
}

.animate.zoom:hover {
  transform: scale(1.1);
  transition: all ease 500ms;
}

.animate.move-vertical {
  animation: move-vertical 900ms infinite;
  animation-timing-function: linear;
}

@keyframes move-vertical {
  0% {
    transform: translate(0px, 4px);
  }
  50% {
    transform: translate(0px, -4px);
  }
  100% {
    transform: translate(0px, 4px);
  }
}

.animate.move-horizontal {
  animation: move-horizontal 900ms infinite;
  animation-timing-function: linear;
}

@keyframes move-horizontal {
  0% {
    transform: translate(4px, 0px);
  }
  50% {
    transform: translate(-4px, 0px);
  }
  100% {
    transform: translate(4px, 0px);
  }
}

/*--- end of animations ---*/
/*------- end of utility classes -------*/
/*---------- dark-mode ----------*/
body.dark-mode {
  background-color: #232527;
  color: #d6d7d8;
}

.dark h1, .dark h2, .dark h3, .dark h4, .dark h5, .dark h6, .dark-mode h1, .dark-mode h2, .dark-mode h3, .dark-mode h4, .dark-mode h5, .dark-mode h6 {
  color: #fff;
}

.dark a, .dark a:focus, .dark-mode a, .dark-mode a:focus {
  color: #fff;
  opacity: 0.65;
}

.dark a:hover, .dark-mode a:hover {
  color: #fff;
  opacity: 1;
}

.dark a.btn, .dark .btn:hover, .dark .btn-social:hover, .dark-mode a.btn, .dark-mode .btn:hover, .dark-mode .btn-social:hover {
  opacity: 1;
}

.dark .title-color, .dark-mode .title-color {
  color: #fff !important;
}

.dark .text-link, .dark .text-link:focus, .dark-mode .text-link, .dark-mode .text-link:focus {
  color: #fff;
  opacity: 0.65;
}

.dark .text-link:hover, .dark-mode .text-link:hover {
  color: #fff;
  opacity: 1;
}

.dark .title-link, .dark .title-link:focus, .dark-mode .title-link, .dark-mode .title-link:focus {
  color: #fff;
}

.dark .title-link:hover, .dark-mode .title-link:hover {
  color: #fff;
  opacity: 0.75;
}

.dark p, .dark-mode p {
  color: #d6d7d8;
}

.dark li, .dark-mode li {
  color: #d6d7d8;
}

.dark header .page-title, .dark header .title, .dark .section-title, .dark-mode header .page-title, .dark-mode header .title, .dark-mode .section-title {
  text-shadow: 0px 0px 40px rgba(55, 55, 55, 0.3);
}

.dark .section-sub-title, .dark-mode .section-sub-title {
  color: #fff;
}

.dark .nav a, .dark-mode .nav a, .dark .pagination .active a, .dark-mode .pagination .active a, .dark .navbar-brand, .dark-mode .navbar-brand {
  opacity: 1 !important;
}

.dark .pagination a, .dark-mode .pagination a {
  color: #fff;
  opacity: 0.75;
}

.dark .pagination a:hover, .dark-mode .pagination a:hover {
  opacity: 1;
}

.dark .breadcrumb-item, .dark-mode .breadcrumb-item {
  color: #f1f4f7;
}

.dark .breadcrumb-item:hover, .dark-mode .breadcrumb-item:hover {
  opacity: 1;
  color: #d6d7d8;
}

.dark .breadcrumb-item::before, .dark-mode .breadcrumb-item::before {
  color: #d6d7d8 !important;
}

.dark .breadcrumb-item.active, .dark-mode .breadcrumb-item.active {
  color: #d6d7d8;
}

.dark .breadcrumb-item.active:hover, .dark-mode .breadcrumb-item.active:hover {
  color: #d6d7d8;
}

.dark table, .dark-mode table {
  background: #000;
  border-color: #282c31 !important;
}

.dark .table thead th, .dark-mode .table thead th {
  border-bottom: 1px solid #282c31 !important;
  background-color: #191b1d;
}

.dark .table .thead-light, .dark-mode .table .thead-light {
  color: #fff;
  background-color: #282c31;
}

.dark .table td, .dark .table th, .dark-mode .table td, .dark-mode .table th {
  border-right-color: #282c31 !important;
}

.dark .table tr, .dark-mode .table tr {
  border-color: #282c31;
  color: #d6d7d8;
}

.dark .thead-default th, .dark-mode .thead-default th {
  color: #fff;
  background-color: #282c31;
}

.dark .table-striped tbody tr:nth-of-type(odd), .dark-mode .table-striped tbody tr:nth-of-type(odd) {
  background-color: #191b1d;
}

.dark .table-striped tbody tr:nth-of-type(odd) th, .dark .table-striped tbody tr:nth-of-type(odd) td, .dark-mode .table-striped tbody tr:nth-of-type(odd) th, .dark-mode .table-striped tbody tr:nth-of-type(odd) td {
  border-color: #282c31 !important;
}

.dark .table-bordered td, .dark .table-bordered th, .dark-mode .table-bordered td, .dark-mode .table-bordered th {
  border-color: #282c31;
}

.dark .table-active, .table-active > td, .dark .table-active > th, .dark-mode .table-active, .table-active > td, .dark-mode .table-active > th {
  background-color: #282c31;
}

.dark .toast, .dark-mode .toast {
  background: #000;
}

.dark .toast-header, .dark-mode .toast-header {
  color: #fff;
  border-bottom: #282c31;
}

.dark .toast-header {
  color: #fff;
}

.dark .toast-header small, .dark-mode .toast-header small {
  color: #919293;
}

.dark .toast .btn-close, .dark-mode .toast .btn-close {
  color: #fff;
}

.dark .popover, .dark-mode .popover {
  background-color: #191b1d;
  color: #d6d7d8;
}

.dark .popover *, .dark-mode .popover * {
  color: #fff;
  opacity: 0.75;
}

.dark .popover-header, .dark-mode .popover-header {
  color: #fff !important;
  opacity: 1;
}

.dark .bs-popover-top .popover-arrow:after, .dark .bs-popover-top .popover-arrow:before, .dark-mode .bs-popover-top .popover-arrow:after, .dark-mode .bs-popover-top .popover-arrow:before {
  border-top-color: #191b1d;
}

.dark .bs-popover-bottom .popover-arrow:after, .dark .bs-popover-bottom .popover-arrow:before, .dark-mode .bs-popover-bottom .popover-arrow:after, .dark-mode .bs-popover-bottom .popover-arrow:before {
  border-bottom-color: #191b1d;
}

.dark .bs-popover-start .popover-arrow:after, .dark .bs-popover-start .popover-arrow:before, .dark-mode .bs-popover-start .popover-arrow:after, .dark-mode .bs-popover-start .popover-arrow:before {
  border-left-color: #191b1d;
}

.dark .bs-popover-end .popover-arrow:after, .dark .bs-popover-end .popover-arrow:before, .dark-mode .bs-popover-end .popover-arrow:after, .dark-mode .bs-popover-end .popover-arrow:before {
  border-right-color: #191b1d;
}

.dark .form-control, .dark-mode .form-control {
  color: #d6d7d8;
  background: transparent;
  border-color: #282c31;
}

.dark input:focus, .dark textarea:focus, .dark .custom-select:focus, .dark .multiple-selector:focus, .dark-mode input:focus, .dark-mode textarea:focus, .dark-mode .custom-select:focus, .dark-mode .multiple-selector:focus {
  color: #d6d7d8 !important;
  outline: none !important;
  background-color: transparent;
  border-color: #515254 !important;
}

.dark .custom-select, .dark-mode .custom-select {
  color: #d6d7d8;
  border-color: #282c31;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
}

.dark .needs-validation .form-control:focus, .dark .needs-validation .form-control:active, .dark-mode .needs-validation .form-control:focus, .dark-mode .needs-validation .form-control:active {
  border-color: #515254 !important;
}

.dark .btn-file input[type=file], .dark-mode .btn-file input[type=file] {
  color: #fff;
  background: #191b1d;
}

.dark .form-control.w-icon-left, .dark-mode .form-control.w-icon-left {
  border-color: #282c31;
}

.dark .input-group-text, .dark-mode .input-group-text {
  color: #d6d7d8;
  background: #191b1d;
  border-color: #282c31;
}

.dark .input-group-addon, .dark-mode .input-group-addon {
  color: #d6d7d8;
  background-color: #282c31;
  border-color: #282c31;
}

.dark ::-webkit-input-placeholder, .dark-mode ::-webkit-input-placeholder {
  color: #c2c7cd !important;
}

.dark ::-moz-placeholder, .dark-mode ::-moz-placeholder {
  color: #c2c7cd !important;
}

.dark :-moz-placeholder, .dark-mode :-moz-placeholder {
  color: #c2c7cd !important;
}

.dark :-ms-input-placeholder, .dark-mode :-ms-input-placeholder {
  color: #c2c7cd !important;
}

.dark .checkbox label::before, .dark-mode .checkbox label::before {
  border-color: #282c31;
  background-color: #191b1d;
}

.dark .checkbox label::after, .dark-mode .checkbox label::after {
  color: #c2c7cd;
}

.dark .checkbox input[type="checkbox"]:disabled + label::before, .dark-mode .checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #191b1d;
}

.dark .radio label::before, .dark-mode .radio label::before {
  border: 2px solid #282c31;
  background-color: #191b1d;
}

.dark .radio label::after, .dark-mode .radio label::after {
  background-color: #c2c7cd;
}

.dark .form-wrapper, .dark-mode .form-wrapper {
  background: #191b1d;
  border-color: #282c31;
}

.dark #loader-wrapper .loader-section, .dark-mode #loader-wrapper .loader-section {
  background: #232527;
}

.dark .progress, .dark-mode .progress {
  background-color: #191b1d;
}

.dark .noUi-handle, .dark-mode .noUi-handle {
  background-color: #191b1d;
}

.dark .noUi-target, .dark-mode .noUi-target {
  background-color: #000;
}

.dark .card, .dark-mode .card {
  background-color: #000;
  border-color: #282c31;
}

.dark .card-header, .dark-mode .card-header {
  border-bottom-color: #282c31;
}

.dark .card-footer, .dark-mode .card-footer {
  border-top-ccolor: #282c31;
}

.dark .card-header, .dark .card-footer, .dark-mode .card-header, .dark-mode .card-footer {
  background: #000;
}

.dark .card-sub-title, .dark-mode .card-sub-title {
  color: #c2c7cd;
}

.dark .modal-backdrop, .dark-mode .modal-backdrop {
  background-color: #000;
}

.dark .modal-content, .dark-mode .modal-content {
  background-color: #000;
}

.dark .modal-fs .modal-close, .dark-mode .modal-fs .modal-close {
  color: #fff;
  border-color: #fff;
}

.dark .modal-fs .modal-close:hover, .dark-mode .modal-fs .modal-close:hover {
  color: #fff;
  background-color: transparent;
}

.dark .swiper-pagination-bullet, .dark-mode .swiper-pagination-bullet {
  background-color: #c2c7cd;
}

.dark .swiper-horizontal.pagination-down > .swiper-pagination-bullets .swiper-pagination-bullet-active, .dark-mode .swiper-horizontal.pagination-down > .swiper-pagination-bullets .swiper-pagination-bullet-active {
  background-color: #c2c7cd;
}

.dark .accordion, .dark-mode .accordion {
  background: #000;
}

.dark .accordion-button, .dark-mode .accordion-button {
  color: #fff;
  border-color: #282c31;
}

.dark .accordion-item .accordion-button:focus, .dark-mode .accordion-item .accordion-button:focus {
  border-color: #282c31;
}

.dark .accordion-button.collapsed i, .dark-mode .accordion-button.collapsed i {
  color: #fff;
}

.dark .accordion-collapse, .dark-mode .accordion-collapse {
  border-color: #282c31;
}

.dark .minimal-accordion .accordion-button span, .dark-mode .minimal-accordion .accordion-button span {
  background: rgba(27, 26, 26, 0.5);
}

.dark .accordion .card, .dark-mode .accordion .card {
  background: #000;
}

.accordion .card-body {
  color: #d6d7d8;
  border-top-color: #282c31;
}

.dark .dropdown-menu, .dark-mode .dropdown-menu {
  background-color: #000;
}

.dark .dropdown-divider, .dark-mode .dropdown-divider {
  border-color: #282c31;
}

.dark .promo-box, .dark-mode .promo-box {
  background-color: #000;
  border-color: #282c31;
}

.dark .promo-box.has-process-icon:after, .dark .promo-box.dark.has-process-icon:after, .dark-mode .promo-box.has-process-icon:after, .dark-mode .promo-box.dark.has-process-icon:after {
  color: rgba(25, 25, 25, 0.75);
}

.dark .promo-box .col-line-bottom, .dark-mode .promo-box .col-line-bottom {
  border-bottom-color: #282c31;
}

.dark .promo-box .col-line, .dark-mode .promo-box .col-line {
  border-right-color: #282c31;
}

.dark .widget, .dark-mode .widget {
  background-color: #000;
  border-color: #282c31;
}

.dark .footer-widget-area, .dark-mode .footer-widget-area {
  border-color: rgba(55, 55, 55, 0.5);
}

.dark .footer-widget-area .widget, .dark-mode .footer-widget-area .widget, .footer-widget-area.dark .widget {
  background-color: transparent;
  border-color: transparent;
}

.dark .comment-reply, .dark .comment-reply:focus, .dark .comment-reply:hover, .dark-mode .comment-reply, .dark-mode .comment-reply:focus, .dark-mode .comment-reply:hover {
  color: #fff;
  opacity: 0.75;
}

.dark .comment-reply:hover, .dark-mode .comment-reply:hover {
  opacity: 1;
}

.dark .timeline:before, .dark-mode .timeline:before {
  background-color: rgba(0, 0, 0, 0.5);
}

.dark .timeline.w-icons:before, .dark-mode .timeline.w-icons:before {
  background-color: rgba(0, 0, 0, 0.5);
}

.dark .timeline > li > .timeline-badge, .dark-mode .timeline > li > .timeline-badge {
  border-color: #000;
}

.dark .timeline > li > .timeline-panel, .dark-mode .timeline > li > .timeline-panel {
  background-color: #000;
}

.dark .menu-list a, .dark-mode .menu-list a {
  color: #fff;
  opacity: 0.75;
}

.dark .menu-list a:hover, .dark-mode .menu-list a:hover {
  color: #fff;
  opacity: 1;
}

.dark .filter-title, .dark-mode .filter-title {
  color: #fff;
}

.dark .filter-btn, .dark-mode .filter-btn {
  color: #fff;
}

.dark .filter-btn:hover, .dark-mode .filter-btn:hover {
  color: #0978f5;
}

.dark .filter-btn.is-checked, .dark-mode .filter-btn.is-checked {
  color: #0978f5;
}

.dark .filter-btn.is-checked:hover, .dark-mode .filter-btn.is-checked:hover {
  color: #0978f5;
}

.dark .hovereffect-title .hover-bottom, .dark-mode .hovereffect-title .hover-bottom {
  background-color: #282c31;
}

.dark .hovereffect-title .project-title, .dark-mode .hovereffect-title .project-title {
  color: #fff;
}

.dark .hovereffect-title .project-title:hover, .dark-mode .hovereffect-title .project-title:hover {
  color: #0978f5;
}

.dark .comment-author, .dark-mode .comment-author {
  color: #fff;
}

.dark .glightbox-clean .gslide-description, .dark-mode .glightbox-clean .gslide-description {
  background-color: #000;
}

.dark .glightbox-clean .gslide-title, .dark-mode .glightbox-clean .gslide-title {
  color: #fff;
}

.dark .glightbox-clean .gslide-desc, .dark-mode .glightbox-clean .gslide-desc {
  color: #c2c7cd;
}

.dark .hovereffect-title .hover-bottom a, .dark-mode .hovereffect-title .hover-bottom a {
  color: #fff;
  opacity: 1;
}

.dark .hovereffect-title .hover-bottom a:hover, .dark-mode .hovereffect-title .hover-bottom a:hover {
  color: #0978f5;
}

.dark .product-price, .dark-mode .product-price {
  background-color: #191b1d;
}

.dark .product-price.w-del del, .dark-mode .product-price.w-del del {
  border-color: #282c31;
}

.dark .mega-menu .sub-menu, .dark-mode .mega-menu .sub-menu {
  border-color: #282c31;
}

.dark form label, .dark-mode form label {
  color: #d6d7d8;
}

.dark .wishlist-list li, .dark-mode .wishlist-list li {
  border-color: #282c31;
}

.dark .nav-tabs .nav-link, .dark-mode .nav-tabs .nav-link {
  color: #d6d7d8;
}

.dark .nav-tabs .nav-link:hover, .dark-mode .nav-tabs .nav-link:hover {
  color: #fff;
}

.dark .nav-tabs.product-tabs .nav-link:hover, .dark-mode .nav-tabs.product-tabs .nav-link:hover {
  border-color: #fff !important;
}

.dark .nav-tabs.product-tabs .nav-link.active:hover, .dark-mode .nav-tabs.product-tabs .nav-link.active:hover {
  border-color: #0978f5 !important;
}

.dark .nav-tabs .nav-link.active, .dark-mode .nav-tabs .nav-link.active {
  color: #0978f5;
  border-color: #0978f5;
}

.dark .shipping-info, .dark-mode .shipping-info, .dark .order-info, .dark-mode .order-info {
  border-color: #282c31;
}

.dark .shipping-options, .dark-mode .shipping-options {
  background-color: #232527;
  border-color: #282c31;
}

.dark .accepted-cards, .dark-mode .accepted-cards {
  background-color: #232527;
  border-color: #282c31;
}

.dark .accepted-cards-text, .dark-mode .accepted-cards-text {
  color: #fff;
}

.dark .order-list li, .dark-mode .order-list li {
  border-color: #282c31;
}

.dark .spacer, .dark-mode .spacer {
  border-color: #282c31;
}

.dark .hovereffect-title.project-icons a, .dark-mode .hovereffect-title.project-icons a {
  opacity: 1 !important;
  background-color: #fff;
  color: #000;
}

.dark .hovereffect-title.project-icons a:hover, .dark-mode .hovereffect-title.project-icons a:hover {
  background-color: #282c31 !important;
  color: #fff;
}

/*---------- end of dark-mode ----------*/
/*----------------    media screens    ----------------*/
/*-------------    max-widths    -------------*/
/*-------       1440px       -------*/
@media only screen and (max-width: 1440px) {
  .navbar .dropdown-menu.mega-menu {
    transform: translateX(-70%);
  }
  .navbar .dropdown-menu:before {
    left: auto;
    right: 0;
  }
  .navbar .dropdown-menu:before {
    right: 15%;
  }
  .navbar .dropdown-menu.mega-menu.drop-to-center {
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
  }
  .navbar .dropdown-menu.drop-to-center::before {
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
  }
  .navbar .dropdown-menu.mega-menu.drop-to-right {
    transform: none;
  }
  .navbar .dropdown-menu.drop-to-right::before {
    left: 15%;
    right: auto;
  }
  .snav-hide-span-xxl .nav-item span {
    display: none !important;
  }
  .bottom-element {
    max-width: 90%;
  }
  .header-content.slide-header {
    padding: 300px 0;
  }
}

/*-------       end of 1440px       -------*/
/*-------       1200px       -------*/
@media only screen and (max-width: 1200px) {
  .tablet-xl-text-left {
    text-align: left !important;
  }
  .tablet-xl-text-center {
    text-align: center !important;
  }
  .tablet-xl-text-right {
    text-align: right !important;
  }
  .navbar-expand-xl.navbar {
    padding: 0.885rem 0;
  }
  .navbar-expand-xl.navbar.split-menu {
    padding: 1.5rem 0;
  }
  .navbar-expand-xl .dropdown .dropdown-toggle.show::after {
    content: "\f077";
  }
  .tablet-xl-fw {
    width: 100% !important;
    max-width: 100% !important;
  }
  .tablet-xl-top {
    margin-bottom: 15px !important;
  }
  .tablet-xl-top-30 {
    margin-bottom: 30px !important;
  }
  .tablet-xl-top-45 {
    margin-bottom: 45px !important;
  }
  .tablet-xl-top-60 {
    margin-bottom: 60px !important;
  }
  .tablet-xl-hidden, .hide-tablet-xl {
    display: none;
  }
  .tablet-xl-no-border {
    border: none !important;
  }
  .tablet-xl-no-border-right {
    border-right: none !important;
  }
  .tablet-xl-no-border-left {
    border-left: none !important;
  }
  .tablet-xl-no-border-top {
    border-top: none !important;
  }
  .tablet-xl-no-border-bottom {
    border-bottom: none !important;
  }
  .tablet-xl-overlay-dark {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  .tablet-xl-overlay-darker {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.87);
  }
  .tablet-xl-overlay-light {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.87);
  }
  .tablet-xl-overlay-lighter {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
  }
  .tablet-xl-overlay-primary {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(214, 29, 9, 0.5);
  }
  .tablet-xl-overlay-primary-darker {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(214, 29, 9, 0.87);
  }
  [class*="col-xl"] .stacked-card {
    margin-left: 0;
    margin-right: 0;
  }
  .overlay-half-dark.tablet-xl-full, .overlay-half-darker.tablet-xl-full, .overlay-half-light.tablet-xl-full, .overlay-half-lighter.tablet-xl-full, .overlay-half-light-frosted.tablet-xl-full, .overlay-half-primary.tablet-xl-full, .overlay-half-primary-darker.tablet-xl-full {
    width: 100%;
  }
  .navbar-expand-xl .form-inline {
    display: block;
    margin: .5rem auto;
  }
  .navbar-expand-xl .navbar-collapse {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 1rem;
  }
  .navbar-expand-xl .navbar-collapse .nav-link {
    padding: 1rem 0;
  }
  .navbar-expand-xl .dropdown-toggle::after {
    top: 21px;
    font-size: 0.65rem;
  }
  .navbar-expand-xl .navbar-collapse {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    width: 270px;
    height: 100% !important;
    max-height: 100% !important;
    background: #fff;
    text-align: left;
    padding: 100px 30px 0;
    margin-top: 0;
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  .navbar-expand-xl button:not(.collapsed) {
    position: absolute;
    right: 30px;
  }
  .navbar-expand-xl.split-menu .navbar-brand {
    display: none;
  }
  .navbar-expand-xl.split-menu .navbar-brand.mobile-brand {
    display: contents !important;
  }
  .navbar-expand-xl.split-menu .navbar-collapse {
    margin-top: 20px;
  }
  .snav-hide-span-xl .nav-item span {
    display: none !important;
  }
  .vertical-swiper.swiper-container {
    height: 600px !important;
  }
  .vertical-swiper.swiper-container.header-slider {
    height: 740px !important;
  }
  .vertical-swiper.swiper-container.header-slider-full {
    height: 740px !important;
  }
  .header-content.slide-header {
    padding: 260px 0;
  }
  .hovereffect-title .product-title {
    font-size: 1rem;
  }
  .product-price {
    font-size: 1rem;
  }
}

/*-------       end of 1200px       -------*/
/*-------       1024px       -------*/
@media only screen and (max-width: 1024px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 94%;
  }
  .bg-fixed {
    background-attachment: scroll !important;
  }
  .hide-tablet-landscape, .hide-tablet-ls {
    display: none !important;
  }
  .vertical-swiper.swiper-container {
    height: 500px !important;
  }
  .vertical-swiper.swiper-container.header-slider {
    height: 650px !important;
  }
  .vertical-swiper.swiper-container.header-slider-full {
    height: 650px !important;
  }
}

/*-------       end of 1024px       -------*/
/*-------       991px       -------*/
@media only screen and (max-width: 991px) {
  .revert-order-tablet-lg {
    display: flex;
    flex-direction: column-reverse;
  }
  .tablet-lg-text-left {
    text-align: left !important;
  }
  .tablet-lg-text-center {
    text-align: center !important;
  }
  .tablet-lg-text-right {
    text-align: right !important;
  }
  .tablet-lg-fw {
    width: 100% !important;
    max-width: 100% !important;
  }
  .tablet-lg-top {
    margin-bottom: 15px !important;
  }
  .tablet-lg-top-30 {
    margin-bottom: 30px !important;
  }
  .tablet-lg-top-45 {
    margin-bottom: 45px !important;
  }
  .tablet-lg-top-60 {
    margin-bottom: 60px !important;
  }
  .hide-tablet-lg, .tablet-lg-hidden {
    display: none !important;
  }
  .tablet-lg-no-border {
    border: none !important;
  }
  .tablet-lg-no-border-right {
    border-right: none !important;
  }
  .tablet-lg-no-border-left {
    border-left: none !important;
  }
  .tablet-lg-no-border-top {
    border-top: none !important;
  }
  .tablet-lg-no-border-bottom {
    border-bottom: none !important;
  }
  .tablet-lg-overlay-dark {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  .tablet-lg-overlay-darker {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.87);
  }
  .tablet-lg-overlay-light {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.87);
  }
  .tablet-lg-overlay-lighter {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
  }
  .tablet-lg-overlay-primary {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(214, 29, 9, 0.5);
  }
  .tablet-lg-overlay-primary-darker {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(214, 29, 9, 0.87);
  }
  .overlay-half-dark.tablet-lg-full, .overlay-half-darker.tablet-lg-full, .overlay-half-light.tablet-lg-full, .overlay-half-lighter.tablet-lg-full, .overlay-half-light-frosted.tablet-lg-full, .overlay-half-primary.tablet-lg-full, .overlay-half-primary-darker.tablet-lg-full {
    width: 100%;
  }
  .w50, .w-50 {
    width: 100% !important;
    max-width: 100% !important;
  }
  .w40, .w-40 {
    width: 100% !important;
    max-width: 100% !important;
  }
  .w30, .w-30 {
    width: 100% !important;
    max-width: 100% !important;
  }
  .w20, .w-20 {
    width: 100% !important;
    max-width: 100% !important;
  }
  .w10, .w-10 {
    width: 100% !important;
    max-width: 100% !important;
  }
  .card img.w-50 {
    width: 50% !important;
    max-width: 50% !important;
  }
  .card img.w-60 {
    width: 60% !important;
    max-width: 60% !important;
  }
  .card img.w-70 {
    width: 70% !important;
    max-width: 70% !important;
  }
  .card img.w-80 {
    width: 80% !important;
    max-width: 80% !important;
  }
  .card img.w-90 {
    width: 90% !important;
    max-width: 90% !important;
  }
  .navbar-expand-lg.mobile-white-nav {
    background-color: #fff !important;
  }
  .navbar-expand-lg.mobile-light-nav {
    background-color: #f1f4f7 !important;
  }
  .navbar-expand-lg.mobile-primary-nav {
    background-color: #0978f5 !important;
  }
  .navbar-expand-lg.mobile-inverse-nav {
    background-color: #191b1d !important;
  }
  .navbar-expand-lg.mobile-dark-nav {
    background-color: #282c31 !important;
  }
  .navbar-expand-lg.mobile-black-nav {
    background-color: #000 !important;
  }
  .navbar.navbar-expand-lg .dropdown-menu.mega-menu {
    transform: none;
    right: 0;
    left: 0;
  }
  .navbar-expand-lg > .container {
    padding-right: 15px;
    padding-left: 30px;
  }
  .navbar-expand-lg.navbar {
    padding: 1.375rem 0;
  }
  .navbar-expand-lg.menu-line.nav-scroll li a span {
    padding-bottom: 8px;
  }
  .navbar-expand-lg .dropdown .dropdown-toggle.show::after {
    content: "\f286" !important;
  }
  .navbar-expand-lg .navbar-collapse {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 1rem;
  }
  .navbar-expand-lg .navbar-collapse .nav-link {
    padding: 1rem 0;
  }
  .navbar-expand-lg.bg-transparent.absolute .navbar-collapse .nav-link {
    padding: 1rem;
  }
  .navbar-expand-lg .navbar-brand {
    margin-left: 0;
  }
  .navbar-expand-lg.navbar-light.bg-transparent .navbar-collapse {
    background-color: #fff !important;
  }
  .navbar-expand-lg .dropdown-menu {
    background-color: transparent;
  }
  .navbar-expand-lg .dropdown-menu.show {
    display: block;
    left: 0;
    right: 0;
    max-width: 320px;
    transform: none;
  }
  .navbar-expand-lg .dropdown-menu:before, .navbar-expand-lg .dropdown-menu:after {
    display: none;
  }
  .navbar-expand-lg .dropdown-menu .dropdown-item {
    padding: 8px 0;
  }
  .navbar-expand-lg.navbar-inverse .navbar-collapse .dropdown-item, .navbar-expand-lg.navbar-inverse .navbar-collapse .dropdown-item:focus {
    color: #f1f4f7;
    opacity: 0.75;
  }
  .navbar-expand-lg.navbar-inverse .navbar-collapse .dropdown-item:hover, .navbar-expand-lg.navbar-inverse .navbar-collapse .dropdown-item:active, .navbar-expand-lg.navbar-inverse .navbar-collapse .dropdown-item.active {
    color: #0978f5;
  }
  .navbar-expand-lg.navbar-inverse.bg-primary .navbar-collapse .dropdown-item:hover, .navbar-expand-lg.navbar-inverse.bg-primary .navbar-collapse .dropdown-item:active, .navbar-expand-lg.navbar-inverse.bg-primary .navbar-collapse .dropdown-item.active, .navbar-expand-lg.navbar-inverse.bg-info .navbar-collapse .dropdown-item:hover, .navbar-expand-lg.navbar-inverse.bg-info .navbar-collapse .dropdown-item:active, .navbar-expand-lg.navbar-inverse.bg-info .navbar-collapse .dropdown-item.active {
    color: #fff !important;
    opacity: 1;
  }
  .navbar-expand-lg.navbar-inverse.bg-primary .navbar-collapse .dropdown-item i {
    color: #fff !important;
  }
  .navbar-expand-lg .dropdown-menu {
    box-shadow: none;
    max-width: 600px;
    margin: 0 auto;
  }
  .navbar-expand-lg .navbar-collapse .dropdown-menu {
    padding-left: 0.75rem;
    padding-right: 0;
    left: 0 !important;
    margin-left: 0;
    border-radius: 0;
    border-top: none;
    border-left: 1px solid #0978f5;
    max-height: 100%;
  }
  .navbar-expand-lg.split-menu .navbar-collapse .dropdown-menu, .navbar-expand-lg.stacked-menu .navbar-collapse .dropdown-menu {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-expand-lg.split-menu .navbar-brand {
    display: none;
  }
  .navbar-expand-lg.split-menu .mobile-brand {
    display: contents !important;
  }
  .navbar-expand-lg .navbar-collapse .dropdown-toggle::after {
    display: block !important;
    font-size: 16px !important;
    opacity: 1 !important;
  }
  .dropdown-menu.mega-menu {
    padding: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    max-height: 260px;
  }
  .navbar-expand-lg .dropdown-item {
    text-align: left;
  }
  .navbar-expand-lg .dropdown-toggle::after {
    top: 27px;
    right: 0;
    font-size: 10px;
  }
  .full-height-nav {
    min-height: calc(100vh - 73px) !important;
  }
  .nav-icon .md-icon {
    margin: 5px;
  }
  .navbar-expand-lg .form-inline {
    display: block;
    margin: .5rem auto;
  }
  .navbar-expand-lg .count {
    top: 12px;
    right: auto;
    left: auto;
    margin-top: 0;
    margin-left: -8px;
  }
  .after-dropdown {
    margin-left: 0;
  }
  .navbar-expand-lg .navbar-button {
    margin-left: 0 !important;
  }
  .navbar-expand-lg.secondary-nav .navbar-toggler {
    margin-left: auto;
    margin-right: auto;
  }
  .navbar-expand-lg.split-menu .dropdown-item, .navbar-expand-lg.stacked-menu .dropdown-item, .navbar-expand-lg.secondary-nav .dropdown-item {
    text-align: center;
  }
  .navbar-expand-lg.bg-secondary .navbar-collapse {
    background: #c2c7cd;
  }
  .navbar-expand-lg.navbar-inverse.nav-primary .navbar-collapse, .navbar-expand-lg.navbar-inverse.bg-primary .navbar-collapse {
    background: #0978f5;
  }
  .navbar-expand-lg.navbar-inverse.main-nav .navbar-collapse .nav-link, .navbar-expand-lg.navbar-inverse.main-nav .navbar-collapse .nav-link:focus, .navbar-expand-lg.navbar-inverse.main-nav .navbar-collapse .dropdown-item, .navbar-expand-lg.navbar-inverse.main-nav .navbar-collapse .dropdown-item:focus, .navbar-expand-lg.navbar-inverse.main-nav .dropdown-item i, .navbar-expand-lg.navbar-inverse.main-nav .navbar-toggler .icon-bar, .navbar-expand-lg.navbar-inverse.main-nav .navbar-toggler.collapsed .icon-bar {
    color: #fff;
  }
  .navbar-expand-lg.navbar-inverse.main-nav .navbar-collapse .nav-link:hover, .navbar-expand-lg.navbar-inverse.main-nav .navbar-collapse .nav-link:active, .navbar-expand-lg.navbar-inverse.main-nav .navbar-collapse .nav-link.active, .navbar-expand-lg.navbar-inverse.main-nav .navbar-collapse .dropdown-item:hover, .navbar-expand-lg.navbar-inverse.main-nav .navbar-collapse .dropdown-item:active, .navbar-expand-lg.navbar-inverse.main-nav .navbar-collapse .dropdown-item.active {
    color: #0978f5;
  }
  .navbar-expand-lg.navbar-inverse.nav-primary.main-nav .navbar-collapse .nav-link:hover, .navbar-expand-lg.navbar-inverse.nav-primary.main-nav .navbar-collapse .nav-link:active, .navbar-expand-lg.navbar-inverse.nav-primary.main-nav .navbar-collapse .nav-link.active, .navbar-expand-lg.navbar-inverse.nav-primary.nav-primary.main-nav .navbar-collapse .dropdown-item:hover, .navbar-expand-lg.navbar-inverse.nav-primary.main-nav .navbar-collapse .dropdown-item:active, .navbar-expand-lg.navbar-inverse.nav-primary.main-nav .navbar-collapse .dropdown-item.active {
    color: rgba(255, 255, 255, 0.87);
  }
  .navbar-expand-lg .navbar-button {
    margin-top: 15px !important;
    width: 100%;
  }
  .navbar-expand-lg .navbar-button .btn {
    width: 100%;
    max-width: 100% !important;
  }
  .navbar-expand-lg .navbar-icons {
    margin: 15px 0 !important;
  }
  .navbar-expand-lg .navbar-brand img {
    /* width: auto; */
    max-width: 3rem;
    max-height: 3rem;
    margin: 0;
  }
  .navbar-expand-lg.split-menu .navbar-brand img {
    max-height: 40px;
  }
  .navbar-expand-lg.right-toggler {
    position: relative;
  }
  .navbar-expand-lg.right-toggler .container {
    position: relative;
  }
  .navbar-expand-lg.right-toggler .navbar-toggler {
    position: absolute;
    right: 15px;
  }
  .navbar-expand-lg.right-toggler button:not(.collapsed) {
    position: fixed;
    top: 23px;
    right: 15px;
  }
  .navbar-expand-lg.navbar-inverse .dropdown-item i {
    color: #fff;
  }
  .navbar-expand-lg.menu-custom .nav-link {
    text-align: center;
    padding: 10px 25px;
    margin: 5px 0;
    border-radius: 5px;
  }
  .navbar-expand-lg.menu-custom .dropdown-toggle::after {
    top: 24px;
    right: auto;
  }
  .navbar-expand-lg.menu-custom .nav-link.active, .navbar-expand-lg.menu-custom .nav-link:hover, .navbar-expand-lg.menu-custom .nav-link.active:hover {
    color: #111724;
    background-color: rgba(55, 55, 55, 0.2);
  }
  .navbar-expand-lg.menu-custom.navbar-inverse .nav-link.active, .navbar-expand-lg.menu-custom.navbar-inverse .nav-link:hover, .navbar-expand-lg.menu-custom.navbar-inverse .nav-link.active:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.2);
  }
  .navbar-expand-lg.custom-menu.menu-pills .nav-link {
    text-align: center;
    margin: 5px 0;
  }
  .navbar-expand-lg.menu-line li a span {
    padding-bottom: 10px !important;
  }
  .navbar-expand-lg.snav-hide-span-xxl .nav-item span, .navbar-expand-lg.snav-hide-span-xl .nav-item span {
    display: inline-block !important;
  }
  .navbar-expand-lg .navbar-collapse .second-side-nav {
    display: flex;
    flex-direction: row;
    background-color: #f1f4f7;
    margin-top: 20px !important;
    padding: 0 1.5rem !important;
    border-radius: 0.125rem;
  }
  .navbar-expand-lg .navbar-collapse .second-side-nav .nav-item .nav-link {
    padding-right: 1.5rem !important;
  }
  .navbar-expand-lg .navbar-collapse .second-side-nav .nav-item:last-child .nav-link {
    padding-right: 0 !important;
  }
  .navbar-expand-lg.menu-line .navbar-collapse .nav-item {
    margin-bottom: 30px;
    text-align: center;
  }
  .has-top-menu {
    margin-top: 90px;
  }
  .has-top-menu-sm {
    margin-top: 70px;
  }
  /*--- side-navbar ---*/
  .side-navbar.navbar-expand-lg .navbar-collapse {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 1rem;
  }
  .side-navbar.navbar-expand-lg .navbar-collapse .nav-link {
    padding: 20px 30px;
  }
  .side-navbar.navbar-expand-lg .navbar-collapse .nav-link.sidenav-sub-item {
    padding: 15px 30px;
  }
  .side-navbar.navbar-expand-lg .navbar-brand {
    margin-left: 0;
  }
  .side-navbar.navbar-expand-lg.navbar-light.bg-transparent .navbar-collapse {
    background-color: #fff !important;
  }
  .side-navbar.navbar-expand-lg.navbar-inverse.bg-transparent .navbar-collapse {
    background-color: #000 !important;
  }
  .side-navbar .navbar-collapse {
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    max-height: 380px;
  }
  .side-navbar .navbar-collapse.collapsing {
    transition: none !important;
  }
  .side-navbar.navbar-expand-lg .dropdown-item {
    text-align: left;
  }
  .side-navbar.navbar-expand-lg .dropdown-toggle::after {
    top: 6px;
    right: 0;
    font-size: 11px;
  }
  .side-navbar .nav-button {
    margin-left: 0;
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
  .side-navbar.navbar-expand-lg .navbar-button {
    margin-top: 15px !important;
    width: 100%;
  }
  .side-navbar.navbar-expand-lg .navbar-button .btn {
    width: 100%;
    border-radius: 0 !important;
  }
  .side-navbar.navbar-expand-lg {
    width: 100%;
    height: 71px;
    position: relative;
    top: 0;
    padding: 0 30px;
  }
  .side-navbar.navbar-expand-lg .navbar-collapse {
    background-color: #fff;
    width: 340px;
    height: 100%;
    max-height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: auto;
    bottom: 0;
    z-index: 99999;
    padding: 71px 0 0;
    margin-top: 0;
    animation-name: slideInLeft;
  }
  .side-navbar.right-nav.navbar-expand-lg .navbar-collapse {
    left: auto;
    right: 0;
    animation-name: slideInRight;
  }
  .side-navbar.navbar-expand-lg button:not(.collapsed) {
    position: fixed;
    right: auto;
  }
  .side-navbar.navbar-expand-lg .sidenavbar-logo {
    position: static;
    height: 101px;
    line-height: 101px;
    padding: 0 30px;
    border-top: 1px solid #f1f4f7;
    border-bottom: 1px solid #f1f4f7;
  }
  .side-navbar.navbar-expand-lg .sidenavbar-logo img {
    height: 40px;
  }
  .side-navbar.right-toggler {
    height: 60px;
  }
  .side-navbar.navbar-expand-lg.right-toggler {
    position: relative;
  }
  .side-navbar.navbar-expand-lg.right-toggler .container {
    position: relative;
  }
  .side-navbar.navbar-expand-lg.right-toggler .navbar-toggler {
    position: absolute;
    top: 22px;
    right: 30px;
  }
  .side-navbar.navbar-expand-lg.right-toggler button:not(.collapsed) {
    position: fixed;
    top: 22px;
    right: 30px;
  }
  .sidenavbar-footer {
    text-align: center;
    height: 71px;
    line-height: 71px;
  }
  .page-container {
    padding: 0;
  }
  /* side-navbar nav-inverse */
  .side-navbar.nav-inverse .navbar-collapse {
    background-color: #191b1d;
  }
  .side-navbar.nav-inverse .sidenavbar-logo {
    position: static;
    height: 101px;
    line-height: 101px;
    padding: 0 30px;
    border-color: #282c31;
  }
  .side-navbar.nav-inverse .nav-link {
    color: #fff;
  }
  .side-navbar.nav-inverse .nav-link:hover {
    color: #0978f5;
    background-color: #dfeeff;
  }
  .side-navbar.nav-inverse .nav-link.active, .side-navbar.nav-inverse .nav-link.active:hover {
    color: #fff;
    background-color: #0978f5;
  }
  .side-navbar.nav-inverse .navbar-toggler .icon-bar {
    background-color: #fff;
  }
  /* end of side-navbar nav-inverse */
  /*--- end of side-navbar ---*/
  .table-overflow {
    width: 100%;
    overflow-x: auto;
  }
  header.lg {
    padding: 200px 0;
  }
  header.xl {
    padding: 150px 0;
  }
  .header-content.xl {
    padding-top: 180px;
  }
  header.xxl {
    padding: 180px 0;
  }
  header.xxl.has-fixed-menu {
    padding-top: 250px;
  }
  .header-content.xxl, .header-content.xxxl {
    padding-top: 220px;
  }
  .header-content.slide-header {
    padding: 250px 0;
  }
  header.xxl .fixed-title {
    top: 260px;
  }
  header .page-title {
    font-size: 48px;
  }
  header .absolute-title {
    top: -50%;
  }
  .section-title {
    font-size: 48px;
  }
  .section-title {
    font-size: 48px;
  }
  .page-header {
    padding: 180px 0;
  }
  .page-header.w-absolute-nav .page-title {
    padding-top: 200px;
  }
  header.dark .page-title, header.dark .title {
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.09);
  }
  header .page-title, header .title {
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  }
  .btn-inline {
    position: relative;
    display: block;
  }
  .vertical-swiper.swiper-container {
    height: 460px !important;
  }
  .vertical-swiper.swiper-container.header-slider {
    height: 560px !important;
  }
  .vertical-swiper.swiper-container.header-slider-full {
    height: 560px !important;
  }
  .promo-img-lg.promo-box-img-left img, .promo-img-lg.promo-box-img-left .box-bg-image {
    border-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .promo-img-lg.promo-box-img-left .promo-box {
    border-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .promo-img-lg.promo-box-img-right img, .promo-img-lg.promo-box-img-right .box-bg-image {
    border-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .promo-img-lg.promo-box-img-right .promo-box {
    border-radius: 0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  .cta .left-area {
    text-align: center;
  }
  .cta .right-area {
    text-align: center;
    margin-top: 20px;
  }
  .cta .left-area.left-aligned.tablet-center {
    text-align: center;
  }
  .cta .right-area.right-aligned.tablet-center {
    text-align: center;
  }
  .page-content {
    margin-bottom: 50px;
  }
  .error-title {
    font-size: 12rem;
  }
  .stacked-card {
    margin-left: auto;
    margin-right: auto;
  }
}

/*-------       end of 991px       -------*/
/*-------       767px       -------*/
@media only screen and (max-width: 767px) {
  .revert-order-tablet {
    display: flex;
    flex-direction: column-reverse;
  }
  body.bg-inverse {
    background: #303132;
  }
  .tablet-text-left {
    text-align: left !important;
  }
  .tablet-text-center {
    text-align: center !important;
  }
  .tablet-text-right {
    text-align: right !important;
  }
  .tablet-fw {
    width: 100% !important;
    max-width: 100% !important;
  }
  .tablet-top {
    margin-bottom: 15px !important;
  }
  .tablet-top-30 {
    margin-bottom: 30px !important;
  }
  .tablet-top-45 {
    margin-bottom: 45px !important;
  }
  .tablet-top-60 {
    margin-bottom: 60px !important;
  }
  .tablet-hidden, .hide-tablet {
    display: none;
  }
  .tablet-no-border {
    border: none !important;
  }
  .tablet-no-border-right {
    border-right: none !important;
  }
  .tablet-no-border-left {
    border-left: none !important;
  }
  .tablet-no-border-top {
    border-top: none !important;
  }
  .tablet-no-border-bottom {
    border-bottom: none !important;
  }
  .tablet-overlay-dark {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  .tablet-overlay-darker {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.87);
  }
  .tablet-overlay-light {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.87);
  }
  .tablet-overlay-lighter {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
  }
  .tablet-overlay-primary {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(214, 29, 9, 0.5);
  }
  .tablet-overlay-primary-darker {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(214, 29, 9, 0.87);
  }
  .overlay-half-dark.tablet-full, .overlay-half-darker.tablet-full, .overlay-half-light.tablet-full, .overlay-half-lighter.tablet-full, .overlay-half-light-frosted.tablet-full, .overlay-half-primary.tablet-full, .overlay-half-primary-darker.tablet-full {
    width: 100%;
  }
  .content {
    background: transparent;
    padding: 30px 0;
  }
  .w90, .w-90 {
    width: 100% !important;
    max-width: 100% !important;
  }
  .w80, .w-80 {
    width: 100% !important;
    max-width: 100% !important;
  }
  .w70, .w-70 {
    width: 100% !important;
    max-width: 100% !important;
  }
  .w60, .w-60 {
    width: 100% !important;
    max-width: 100% !important;
  }
  .hide-tablet {
    display: none !important;
  }
  header h1.page-title {
    font-size: 44px;
  }
  .section-title {
    font-size: 44px;
  }
  .section-title {
    font-size: 44px;
  }
  .card-columns {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
  .navbar-expand-md .form-inline {
    display: block;
    margin: .5rem auto;
  }
  .navbar-expand-md .count {
    top: 13px;
    right: auto;
    left: auto;
    margin-top: 0;
    margin-left: -8px;
  }
  .navbar-expand-md .dropdown .dropdown-toggle.show::after {
    content: "\f077";
  }
  .split-menu .navbar-brand {
    display: none;
  }
  .split-menu .navbar-brand.mobile-brand {
    display: contents !important;
  }
  header .breadcrumb.text-right {
    text-align: left !important;
    margin-top: 15px;
  }
  .top-nav {
    text-align: center;
  }
  .top-nav .left {
    float: none;
    display: block;
    text-align: center !important;
    margin-bottom: 10px;
  }
  .top-nav .right {
    float: none;
    display: block;
    text-align: center !important;
  }
  header.xl, header.lg {
    padding: 170px 0;
  }
  .swiper-container .header-content {
    max-width: 85%;
    margin: 0 auto;
  }
  .header-content.slide-header {
    padding: 200px 0;
  }
  .tablet-text-center .header-text-container {
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    padding: 50px;
    border-radius: 0.25rem;
  }
  .tablet-text-center .header-text-container.dark-bg, .dark-mode .tablet-text-center .header-text-container {
    background-color: rgba(27, 26, 26, 0.87);
  }
  .header-text {
    font-size: 18px;
  }
  .vertical-swiper.swiper-container {
    height: 400px !important;
  }
  .vertical-swiper.swiper-container.header-slider {
    height: 500px !important;
  }
  .vertical-swiper.swiper-container.header-slider-full {
    height: 500px !important;
  }
  .promo-box.has-process-icon:after {
    display: none;
  }
  .promo-box-img-left img, .promo-box-img-left .box-bg-image {
    border-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .promo-box-img-left .promo-box {
    border-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .promo-box-img-right img, .promo-box-img-right .box-bg-image {
    border-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .promo-box-img-right .promo-box {
    border-radius: 0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  .pricing-table.featured, .stacked .pricing-table.featured {
    margin-left: 0;
    margin-right: 0;
  }
  .timeline > li > .timeline-panel {
    width: 100%;
  }
  .timeline > li:not(.timeline-inverted), .timeline.w-icons > li:not(.timeline-inverted) {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .timeline > li.timeline-inverted, .timeline.w-icons > li.timeline-inverted {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .timeline > li > .timeline-panel:before, .timeline > li > .timeline-panel:after {
    display: none;
  }
  .timeline > li > .timeline-panel {
    margin-top: 75px;
  }
  .modal-right .modal-dialog, .modal-left .modal-dialog {
    width: calc(100% - 50px);
  }
  .footer-left-area {
    text-align: center;
    margin-bottom: 15px;
  }
  .footer-center-area {
    text-align: center;
    margin-bottom: 15px;
  }
  .footer-right-area {
    text-align: center;
  }
  .cta .left-area.left-aligned.tablet-md-center {
    text-align: center;
  }
  .cta .right-area.right-aligned.tablet-md-center {
    text-align: center;
  }
  .header-slider-content {
    padding: 120px 15px;
  }
  .header-text {
    font-size: 14px !important;
    max-width: 90%;
  }
  .btn-group.is-split-drop .dropdown-menu {
    left: 0 !important;
    right: 0 !important;
    transform: none !important;
  }
  .btn-group.is-split-drop.dropup .dropdown-menu {
    left: 0 !important;
    right: 0 !important;
    transform: none !important;
  }
  .error-title {
    font-size: 10rem;
  }
  footer .text-left, footer .text-right {
    display: block !important;
    text-align: center !important;
  }
  .filter-btn {
    font-size: 14px;
    min-width: auto;
    padding: 0.5rem 0.1rem;
  }
  .footer-widget-area {
    padding: 40px 0;
  }
  .footer-widget-area .widget {
    margin: 25px 0;
  }
}

/*-------       end of 767px       -------*/
/*-------       575px       -------*/
@media only screen and (max-width: 575px) {
  .revert-order-mobile {
    display: flex;
    flex-direction: column-reverse;
  }
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 96%;
  }
  .mobile-text-left {
    text-align: left !important;
  }
  .mobile-text-center {
    text-align: center !important;
  }
  .mobile-text-right {
    text-align: right !important;
  }
  .mobile-fw {
    width: 100% !important;
    max-width: 100% !important;
  }
  .mobile-top {
    margin-bottom: 15px !important;
  }
  .mobile-top-30 {
    margin-bottom: 30px !important;
  }
  .mobile-top-45 {
    margin-bottom: 45px !important;
  }
  .mobile-top-60 {
    margin-bottom: 60px !important;
  }
  .hide-mobile, .mobile-hidden {
    display: none !important;
  }
  .mobile-no-border {
    border: none !important !important;
  }
  .mobile-no-border-right {
    border-right: none !important;
  }
  .mobile-no-border-left {
    border-left: none !important;
  }
  .mobile-no-border-top {
    border-top: none !important;
  }
  .mobile-no-border-bottom {
    border-bottom: none !important;
  }
  .mobile-overlay-dark {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  .mobile-overlay-darker {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.87);
  }
  .mobile-overlay-light {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.87);
  }
  .mobile-overlay-lighter {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
  }
  .mobile-overlay-primary {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(214, 29, 9, 0.5);
  }
  .mobile-overlay-primary-darker {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(214, 29, 9, 0.87);
  }
  .overlay-half-dark.mobile-full, .overlay-half-darker.mobile-full, .overlay-half-light.mobile-full, .overlay-half-lighter.mobile-full, .overlay-half-light-frosted.mobile-full, .overlay-half-primary.mobile-full, .overlay-half-primary-darker.mobile-full {
    width: 100%;
  }
  .card-w-raised-img-top {
    margin-top: 100px;
  }
  .card.w-raised-icon {
    margin-top: 65px;
  }
  .card.w-raised-icon.sm-icon {
    margin-top: 55px;
  }
  .card.w-raised-icon.lg-icon {
    margin-top: 70px;
  }
  .card.w-raised-icon.xl-icon {
    margin-top: 80px;
  }
  .nav-tabs .nav-item {
    margin: 3px 0;
  }
  header.xl, header.lg {
    padding: 150px 0;
  }
  .header-content.xl, .header-content.lg {
    padding-top: 100px;
  }
  header.xxl {
    padding: 100px 0;
  }
  header.xxl.has-fixed-menu {
    padding-top: 170px;
  }
  .header-content.xl {
    padding-top: 150px;
  }
  .header-content.xxl, .header-content.xxxl {
    padding-top: 180px;
  }
  .header-content.slide-header {
    padding: 150px 0;
  }
  header.xxl h1.fixed-title {
    top: 190px;
  }
  .page-header {
    padding: 120px 0;
  }
  .page-header.w-absolute-nav {
    padding-bottom: 80px;
  }
  .page-header.w-absolute-nav .page-title {
    padding-top: 160px;
  }
  .header-text {
    font-size: 16px;
  }
  header.dark .page-title, header.dark .title {
    text-shadow: 0px 0px 12px rgba(0, 0, 0, 0.085);
  }
  header .page-title, header .title {
    text-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  }
  .cta .left-area.left-aligned.mobile-center {
    text-align: center !important;
  }
  .cta .right-area.right-aligned.mobile-center {
    text-align: center !important;
  }
  footer {
    text-align: center;
  }
  .modal-example .modal-content {
    max-width: 300px;
    margin: 0 auto;
  }
  .top-page-header {
    height: 100%;
  }
  .top-header-nav {
    position: relative;
    left: 0;
    right: 0;
    height: 100%;
    padding: 15px;
  }
  .top-header-nav .top-nav-item {
    line-height: 3rem;
  }
  .vertical-swiper.swiper-container {
    height: 300px !important;
  }
  .header-text {
    max-width: 100%;
  }
  .nav-tabs .nav-item {
    display: block;
    width: 100%;
  }
  .instagram-widget img {
    max-width: 54px;
  }
  .error-title {
    font-size: 8rem;
  }
}

/*-------       end of 575px       -------*/
/*-------       480px       -------*/
@media only screen and (max-width: 480px) {
  .mobile-sm-fw {
    width: 100% !important;
    max-width: 100% !important;
  }
  .hide-mobile-sm, .mobile-sm-hidden {
    display: none !important;
  }
  .mobile-sm-no-border {
    border: noen !important;
  }
  .mobile-sm-no-border-right {
    border-right: none !important;
  }
  .mobile-sm-no-border-left {
    border-left: none !important;
  }
  .mobile-sm-no-border-top {
    border-top: none !important;
  }
  .mobile-sm-no-border-bottom {
    border-bottom: none !important;
  }
  .mobile-sm-overlay-dark {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  .mobile-sm-overlay-darker {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.87);
  }
  .mobile-sm-overlay-light {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.87);
  }
  .mobile-sm-overlay-lighter {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
  }
  .mobile-sm-overlay-primary {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(214, 29, 9, 0.5);
  }
  .mobile-sm-overlay-primary-darker {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(214, 29, 9, 0.87);
  }
  .overlay-half-dark.mobile-sm-full, .overlay-half-darker.mobile-sm-full, .overlay-half-light.mobile-sm-full, .overlay-half-lighter.mobile-sm-full, .overlay-half-light-frosted.mobile-sm-full, .overlay-half-primary.mobile-sm-full, .overlay-half-primary-darker.mobile-sm-full {
    width: 100%;
  }
  .side-navbar.navbar-expand-lg .navbar-collapse {
    width: 100%;
  }
  header h1 {
    font-size: 28px;
  }
  header h2 {
    font-size: 24px;
  }
  header h3 {
    font-size: 20px;
  }
  header h1.page-title {
    font-size: 34px;
  }
  .section-title {
    font-size: 34px;
  }
  .section-title {
    font-size: 34px;
  }
  header.xxl {
    padding: 160px 0;
  }
  header.xxl h1.fixed-title {
    top: 170px;
  }
  .header-text {
    font-size: 14px;
  }
  .navbar-expand-lg.right-toggler button:not(.collapsed) {
    top: 60px;
  }
  .custom-form .left-area {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }
  .custom-form .right-area {
    display: block;
    width: 100%;
    text-align: left;
  }
  .custom-form .left-area.long {
    width: 100%;
  }
  .custom-form .right-area.long {
    width: 100%;
  }
  .custom-form .left-area.short {
    width: 100%;
  }
  .custom-form .right-area.short {
    width: 100%;
  }
  .modal-footer.custom-form {
    display: block;
  }
  .cta .left-area.left-aligned.mobile-sm-center {
    text-align: center;
  }
  .cta .right-area.right-aligned.mobile-sm-center {
    text-align: center;
  }
  .modal-example .modal-content {
    max-width: 260px;
    margin: 0 auto;
  }
  table {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .account-bar.horizontal-bar .nav-link {
    padding: 20px;
    font-size: 0.875rem;
  }
  .tab-pane h4 {
    font-size: 1.25rem;
  }
  .nav-tabs .nav-link {
    padding: 0.875rem 1rem;
  }
  .account-bar.horizontal-bar {
    padding: 20px;
  }
}

/*-------       end of 480px       -------*/
/*-------       400px       -------*/
@media only screen and (max-width: 400px) {
  .mobile-xs-fw {
    width: 100% !important;
    max-width: 100% !important;
  }
  .hide-mobile-xs, .mobile-xs-hidden {
    display: none !important;
  }
  .mobile-xs-no-border {
    border: none !important;
  }
  .mobile-xs-no-border-right {
    border-right: none !important;
  }
  .mobile-xs-no-border-left {
    border-left: none !important;
  }
  .mobile-xs-no-border-top {
    border-top: none !important;
  }
  .mobile-xs-no-border-bottom {
    border-bottom: none !important;
  }
  .modal-example .modal-content {
    max-width: 220px;
    margin: 0 auto;
  }
  .vertical-swiper.swiper-container {
    height: 220px !important;
  }
  .slide-nav .navbar-collapse {
    width: 100%;
  }
  .order-list .order-list-info, .wishlist-list .wishlist-info {
    padding-left: 0;
  }
  .order-info, .shipping-info {
    padding: 30px 15px;
  }
  .payment-methods {
    display: block;
  }
  .nav-tabs .nav-link {
    font-size: 0.875rem;
  }
}

/*-------       end of 400px       -------*/
/*------------- end of max-widths -------------*/
/*------------- min-max-widths -------------*/
@media only screen and (max-width: 991px) and (min-width: 768px) {
  .custom-form .left-area {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }
  .custom-form .right-area {
    display: block;
    width: 100%;
    text-align: left;
  }
  .custom-form .left-area.long {
    width: 100%;
  }
  .custom-form .right-area.long {
    width: 100%;
  }
  .custom-form .left-area.short {
    width: 100%;
  }
  .custom-form .right-area.short {
    width: 100%;
  }
  .glightbox-clean .gslide-media {
    max-width: 70% !important;
  }
  .glightbox-clean .gslide-description {
    max-width: 70% !important;
  }
}

/*------------- end of min-max-widths -------------*/
/*------------- min-widths -------------*/
/*-------       768px       -------*/
@media only screen and (min-width: 768px) {
  .card-columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px;
    orphans: 1;
    widows: 1;
  }
  .modal-sm {
    max-width: 420px;
  }
  #wrapper {
    padding-left: 300px;
  }
  #wrapper.toggled {
    padding-left: 0;
  }
  #sidebar-wrapper {
    width: 300px;
  }
  #wrapper.toggled #sidebar-wrapper {
    width: 0;
  }
  #page-content-wrapper {
    position: relative;
  }
  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}

/*-------       end of 768px       -------*/
/*-------       992px       -------*/
@media only screen and (min-width: 992px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    /* padding: 0 1rem; */
    /* height: 90px; */
    line-height: 90px;
  }
  .navbar-expand-lg.nav-fixed .navbar-nav .nav-link {
    padding: 0 1rem;
    height: 70px;
    line-height: 70px;
  }
  .navbar-expand-lg.nav-scroll .navbar-nav .nav-link {
    padding: 25px;
  }
  .navbar-expand-lg.menu-custom, .navbar-expand-lg.custom-menu.menu-pills {
    padding: 20px 0;
  }
  .navbar-expand-lg.custom-menu.menu-pills .nav-link {
    padding: 0 30px;
    height: 52px;
    line-height: 52px;
    margin: 0 5px;
    border: none !important;
    border-radius: 50px;
  }
  .navbar-expand-lg.menu-custom .nav-link {
    padding: 10px 25px;
    margin: 0 5px;
    border-radius: 5px;
  }
  .navbar-expand-lg.menu-custom .nav-link.active, .navbar-expand-lg.menu-custom .nav-link:hover, .navbar-expand-lg.menu-custom .nav-link.active:hover {
    color: #111724;
    background-color: rgba(55, 55, 55, 0.2);
  }
  .navbar-expand-lg.menu-custom.navbar-inverse .nav-link.active, .navbar-expand-lg.menu-custom.navbar-inverse .nav-link:hover, .navbar-expand-lg.menu-custom.navbar-inverse .nav-link.active:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.2);
  }
  .navbar-expand-lg .dropdown-toggle::after {
    display: inline-block !important;
    opacity: 0 !important;
  }
  .navbar-expand-lg .navbar-nav .nav-link:after {
    font-size: 1.125rem !important;
    line-height: 1.5rem;
    font-family: inherit !important;
    font-weight: 400 !important;
    vertical-align: baseline;
    top: 0 !important;
    position: relative !important;
    transform: none !important;
  }
  /* .navbar-expand-lg .navbar-nav .dropdown-toggle.nav-link:after {
    
    margin-left: 8px !important;
  }
  .navbar-expand-lg .navbar-nav .nav-link.active:before, .navbar-expand-lg .navbar-nav .nav-link.active:after {
    opacity: 1 !important;
  } */
  .nav-item.no-nav-link {
    padding: 0 1.5rem;
  }
  .nav-item.no-nav-link.last-menu-item {
    padding-right: 0;
  }
  .navbar-expand-lg .last-menu-item {
    margin-right: 0 !important;
    padding-right: 0 !important;
  }
  .dropdown-menu li:hover .sub-menu {
    visibility: visible;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    display: block;
    position: absolute;
    -webkit-transition: opacity 0.3s, ease-in-out 0.3s;
    -moz-transition: opacity 0.3s, ease-in-out 0.3s;
    -o-transition: opacity 0.3s, ease-in-out 0.3s;
    transition: opacity 0.3s, ease-in-out 0.3s;
    animation-delay: 0.5s;
    top: 100px;
    visibility: hidden;
    opacity: 0;
  }
  .navbar-expand-lg .dropdown:hover .dropdown-menu {
    top: 90px;
    visibility: visible;
    opacity: 1;
  }
  .navbar-expand-lg .mega-menu.one-column {
    min-width: 220px;
  }
  .navbar-expand-lg .mega-menu.two-columns {
    min-width: 460px;
  }
  .navbar-expand-lg .mega-menu.three-columns {
    min-width: 620px;
  }
  .navbar-expand-lg .mega-menu.four-columns {
    min-width: 820px;
  }
  .navbar-expand-lg .mega-menu.five-columns {
    min-width: 220px;
  }
  .navbar-expand-lg .mega-menu.six-columns {
    min-width: 1080px;
    max-width: 100%;
  }
  .navbar-expand-lg .icon-dropdowns .nav-link {
    padding: .75rem !important;
  }
  .navbar-expand-lg.stacked-menu > .container {
    display: block;
  }
  /*--- secondary-nav ---*/
  .navbar-expand-lg.secondary-nav .navbar-nav .nav-link {
    padding: 0 1.5rem;
    height: 54px;
    line-height: 54px;
  }
  .navbar-expand-lg.snav-left .navbar-nav .nav-item:first-child .nav-link {
    padding-left: 0;
  }
  .navbar-expand-lg.snav-right .navbar-nav .nav-item:last-child .nav-link {
    padding-right: 0;
  }
  .navbar-expand-lg.secondary-nav.snav-tab .navbar-nav .nav-link {
    background-color: transparent;
  }
  .navbar-expand-lg.secondary-nav.snav-tab .navbar-nav .nav-link.active, .navbar-expand-lg.secondary-nav.snav-tab .navbar-nav .nav-link.active:hover {
    background-color: #dfeeff;
    color: #0978f5;
  }
  .navbar-expand-lg.secondary-nav.snav-tab .navbar-nav .nav-link:hover {
    background-color: #dfeeff;
    color: #0978f5;
  }
  /*--- end of secondary-nav ---*/
  .navbar-expand-lg .dropdown-menu.mw-140 {
    min-width: 140px;
  }
  .navbar-expand-lg .dropdown-menu.mw-160 {
    min-width: 160px;
  }
  .navbar-expand-lg .dropdown-menu.mw-200 {
    min-width: 200px;
  }
  .navbar-expand-lg .dropdown-menu.mw-300 {
    min-width: 300px;
  }
}

/*-------       end of 992px       -------*/
/*-------       1200px       -------*/
@media only screen and (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav .nav-link {
    padding: 1.75rem 1.5rem;
  }
  .navbar-expand-xl .dropdown-toggle::after {
    display: none;
  }
  .navbar-expand-xl .dropdown-toggle.active::after {
    display: block;
  }
  .navbar-expand-xl .split-menu .navbar-brand {
    display: inline-block;
  }
  .navbar-expand-xl .split-menu .navbar-brand.mobile-brand {
    display: none !important;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    display: block;
    position: absolute;
    -webkit-transition: opacity 0.3s, ease-in-out 0.3s;
    -moz-transition: opacity 0.3s, ease-in-out 0.3s;
    -o-transition: opacity 0.3s, ease-in-out 0.3s;
    transition: opacity 0.3s, ease-in-out 0.3s;
    animation-delay: 0.5s;
    top: 75px;
    visibility: hidden;
    opacity: 0;
  }
  .navbar-expand-xl .dropdown:hover .dropdown-menu {
    top: 65px;
    visibility: visible;
    opacity: 1;
  }
  .navbar-expand-xl .dropdown-menu.show {
    top: 65px;
    visibility: visible;
    opacity: 1;
  }
  .navbar-expand-xl .mega-menu.one-column {
    min-width: 180px;
  }
  .navbar-expand-xl .mega-menu.two-columns {
    min-width: 360px;
  }
  .navbar-expand-xl .mega-menu.three-columns {
    min-width: 540px;
  }
  .navbar-expand-xl .mega-menu.four-columns {
    min-width: 720px;
  }
  .navbar-expand-xl .mega-menu.five-columns {
    min-width: 900px;
  }
  .navbar-expand-xl .mega-menu.six-columns {
    min-width: 1080px;
  }
  .navbar-expand-xl .icon-dropdowns .nav-link {
    padding: .75rem !important;
  }
}

/*-------       end of 1200px       -------*/
/*------------- end of min-widths -------------*/
/*------------- max-height -------------*/
@media only screen and (max-height: 700px) {
  .modal-fs .modal-body {
    padding-top: 80px;
  }
}

/*------------- end of max-height -------------*/
/*---------------- end of media screens ----------------*/
.plan-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adds spacing between buttons */
  align-items: center;
}
.book-demo {
  margin-top: 10px;
  position: relative;
}
.show-more {
  margin-top: 5px;
}
.reseller-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 60px auto;
  padding: 60px 20px;
}

.reseller-content {
  flex: 1;
}

.tag {
  color: #dc2626;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 16px;
  display: block;
}

h1 {
  font-size: 42px;
  color: #1f2937;
  line-height: 1.2;
  margin-bottom: 24px;
  font-weight: 700;
}

p {
  color: #6b7280;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 32px;
}

.register-btn {
  background-color: #dc2626;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-btn:hover {
  background-color: #b91c1c;
}

.reseller-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reseller-image img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .reseller-section {
    flex-direction: column;
    text-align: center;
    padding: 40px 20px;
  }
  
  .reseller-image {
    margin-top: 40px;
  }
  
  h1 {
    font-size: 32px;
  }
}

.partner-section {
  padding: 0 20px 40px;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

.partner-title {
  font-size: 36px;
  color: #1f2937;
  margin-bottom: 60px;
}

.partner-steps {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 50px;
}

.step {
  flex: 1;
  padding: 20px;
}

.step-number {
  width: 40px;
  height: 40px;
  background-color: #f3f4f6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
  font-size: 20px;
  color: #1f2937;
}

.step h3 {
  font-size: 24px;
  color: #1f2937;
  margin-bottom: 15px;
}

.step p {
  color: #6b7280;
  line-height: 1.5;
}

.register-reseller-btn {
  background-color: #1f2937;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.register-reseller-btn:hover {
  background-color: #374151;
}

@media (max-width: 768px) {
  .partner-steps {
    flex-direction: column;
  }
  
  .step {
    margin-bottom: 30px;
  }
}

.card-equal-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.search-input {
  border-radius: 30px !important; /* Force the border-radius */
  padding-left: 40px !important; /* Adjust left padding to make space for the icon */
}

@media (max-width: 576px) {
  /* For mobile view */
  .card {
    height: auto; /* Allow flexibility on smaller screens */
  }
}


