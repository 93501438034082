/* Footer Top Area */
.footer-top-area {
  padding: 40px 0;
  background: #fff;
}

.pt-50 {
  padding-top: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

/* Single Widget */
.single-widget {
  width: 100%;
}

/* Top Section with Logo, Contact, and Social Media */
.footer-info-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

/* Social Icons Container */
.social-icon {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-wrap: wrap;
  gap: 0.2rem;
}

.social-icon li {
  margin: 0;
}

.social-icon li a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: transform 0.3s ease;
}

.social-icon li a:hover {
  transform: translateY(-3px);
}

.social-icon li a i {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Links Section */
.footer-widget {
  margin-bottom: 30px;
}

.footer-widget h3 {
  color: #0d6efd;
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 20px;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  margin-bottom: 12px;
}

.footer-links li a {
  color: #333;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.footer-links li a:hover {
  color: #0d6efd;
  transform: translateX(5px);
}

.footer-links li a i {
  font-size: 12px;
  margin-right: 8px;
  color: #0d6efd;
}

/* Bottom App Links */
.list-inline {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.75rem;
  margin: 0;
  padding: 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .footer-top-area {
    padding: 30px 0;
  }

  .footer-widget {
    margin-bottom: 20px;
  }

  .footer-widget h3 {
    font-size: 19px;
    margin-bottom: 15px;
  }

  .footer-links li {
    margin-bottom: 8px;
  }

  .footer-links li a {
    font-size: 16px;
  }
  
  /* Make columns side by side on mobile */
  .col-12 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .footer-info-wrapper {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
  }

  .footer-info-wrapper > div {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .social-icon {
    justify-content: center;
    gap: 0.25rem;
  }

  .social-icon li a i {
    margin: 0 0.5rem;
  }

  .single-widget {
    margin-bottom: 0;
  }

  .single-widget h3 {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }

  .links a {
    font-size: 0.85rem;
    padding: 0.25rem 0;
  }

  /* Improve touch targets on mobile */
  .btn {
    padding: 0.75rem 1rem;
    font-size: 0.9rem;
    width: 100%;
    max-width: none;
    margin: 0.25rem 0;
  }

  .single-widget > div {
    flex-direction: column;
    gap: 1.5rem;
  }
}

/* Preserve Desktop Layout */
@media (min-width: 769px) {
  .footer-info-wrapper {
    flex-direction: row;
  }

  .social-icon {
    justify-content: flex-end;
  }

  .btn {
    width: auto;
  }

  .single-widget h3 {
    margin-bottom: 1.25rem;
  }

  .links a {
    padding: 0.4rem 0;
  }
} 